import { useQuery } from "@tanstack/react-query";
import { getUserGeolocationInfo } from "lib/geolocationApi";

const useGeolocationInfo = () => {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["geolocation_info"],
    queryFn: getUserGeolocationInfo,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: Infinity
  });
  return {
    geoLocationInfo: data,
    isLoading,
    isFetched
  };
};

export default useGeolocationInfo;
