"use client";

import { useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";

import Modal from "components/Modal";
import usePageDataById from "hooks/usePageDataById";
import useTableActionsState from "hooks/useTableActionsState";

import { RecordItem } from "types/common";
import { CrudActions, ViewOption } from "utils/constants";
import { hasAFileTable } from "utils/dataUtils";
import { TableColumnType } from "types/baTypes";
import useSchemaState from "hooks/useSchemaState";

const Form = dynamic(() => import("components/Form"), { ssr: false });
const FormFile = dynamic(() => import("components/Add/FormFile"), { ssr: false });

const InlineCreation = ({
  showInModal = true,
  className = "",
  onActionSuccess,
  ...rest
}: {
  showInModal?: boolean;
  className?: string;
  onActionSuccess?: (newRecord?: RecordItem[]) => void;
}) => {
  const { schemaInstance } = useSchemaState();
  const { inlineCreationState, updateInlineCreationState } = useTableActionsState();
  const { data: pageData, isLoading: pageDataLoading } = usePageDataById(inlineCreationState.createPageId, {
    refetchOnWindowFocus: false,
    enabled: !!inlineCreationState.createPageId
  });
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isFileTable = useMemo(() => {
    return hasAFileTable(pageData?.table_name, schemaInstance?.extendedSchema);
  }, [pageData?.table_name, schemaInstance?.extendedSchema]);

  const columnsWithFormView = useMemo(() => {
    if (!pageData?.columns) return [];
    if (!inlineCreationState?.createPageDefaultValues)
      return pageData?.columns?.filter((col: TableColumnType) => !!col.views?.[ViewOption.FORM]?.id);

    return pageData?.columns
      ?.filter((col: TableColumnType) => col.views?.[ViewOption.FORM]?.id)
      .map((col: TableColumnType) => {
        if (inlineCreationState?.createPageDefaultValues?.[col.id]) {
          return {
            ...col,
            defaultValues: inlineCreationState?.createPageDefaultValues?.[col.id]
          };
        }

        return col;
      });
  }, [pageData?.columns, inlineCreationState]);

  const handleClose = () => {
    updateInlineCreationState({ isOpen: false });
  };

  const handleActionSuccess = (newRecord?: RecordItem | RecordItem[]) => {
    onActionSuccess?.(Array.isArray(newRecord) ? newRecord : newRecord ? [newRecord] : newRecord);
    inlineCreationState.onSuccess?.(newRecord);
    handleClose();
  };

  const prefillValues = useMemo(() => {
    if (
      !inlineCreationState?.columnToPrefill?.lookupPath ||
      !pageData?.table_name ||
      !inlineCreationState.valueToPrefill
    )
      return undefined;

    let final = null;
    Object.keys(inlineCreationState.columnToPrefill.lookupPath).forEach((key) => {
      const item = inlineCreationState.columnToPrefill?.lookupPath?.[key];

      // TODO: Update with !item?.lookupForeignKey check is case found
      if (item?.lookupTableName === pageData?.table_name) {
        final = item?.lookupDisplayColumn || item?.lookupColumns?.[0];
      }
    });

    if (final) {
      return {
        [final]: inlineCreationState.valueToPrefill
      };
    }
  }, [inlineCreationState?.columnToPrefill, pageData?.table_name, inlineCreationState.valueToPrefill]);

  useEffect(() => {
    if (inlineCreationState.isOpen) {
      setTimeout(() => {
        buttonRef.current?.focus();
      }, 100);
    }
  }, [inlineCreationState.isOpen]);

  if (!showInModal) {
    return (
      <div className={clsx("h-full w-full flex-1 flex-col overflow-hidden pt-4", className)}>
        {pageDataLoading ? (
          <div>Loading...</div>
        ) : isFileTable ? (
          <FormFile
            onActionSuccess={handleActionSuccess}
            tableName={pageData?.table_name}
            filePageId={pageData?.id}
            columns={columnsWithFormView}
            {...rest}
          />
        ) : (
          <Form
            action={CrudActions.CREATE}
            columns={columnsWithFormView}
            tablePath={pageData?.path}
            onActionSuccess={handleActionSuccess}
            record={null}
            title={pageData?.title || ""}
            prefillValues={prefillValues}
            isInlineCreate
            {...rest}
          />
        )}
      </div>
    );
  }
  return (
    <Modal isOpen={inlineCreationState.isOpen} onClose={handleClose} showClose>
      {/* Ghost button to focus after modal is open */}
      <button ref={buttonRef} />
      <div className="pt-5">
        {pageDataLoading ? (
          <div>Loading...</div>
        ) : isFileTable ? (
          <FormFile
            onActionSuccess={handleActionSuccess}
            tableName={pageData?.table_name}
            filePageId={pageData?.id}
            {...rest}
          />
        ) : (
          <Form
            action={CrudActions.CREATE}
            columns={columnsWithFormView}
            tablePath={pageData?.path}
            onActionSuccess={handleActionSuccess}
            record={null}
            title={pageData?.title || ""}
            prefillValues={prefillValues}
            className="!overflow-hidden"
            isInlineCreate
            {...rest}
          />
        )}
      </div>
    </Modal>
  );
};

export default InlineCreation;
