import clsx from "clsx";

function SearchIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      aria-hidden="true"
      className="text-icons h-5 w-5 shrink-0 stroke-current stroke-2 transition-colors"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.01 12a4.25 4.25 0 1 0-6.02-6 4.25 4.25 0 0 0 6.02 6Zm0 0 3.24 3.25"
      />
    </svg>
  );
}

export const Search = ({
  isCollapsed,
  openSearchBox
}: {
  isCollapsed?: boolean;
  openSearchBox?: (value: boolean) => void;
}) => {
  return (
    <div className="lg:max-w-md lg:flex-auto" onClick={() => openSearchBox?.(true)}>
      <button
        type="button"
        className="text-secondary flex h-[36px] w-full items-center gap-2 rounded-md  bg-gray-100 pl-2 pr-3 text-[13px] ring-0 transition-colors hover:bg-gray-200 dark:bg-white/5  dark:ring-inset  dark:hover:bg-gray-600 focus:[&:not(:focus-visible)]:outline-none"
      >
        <SearchIcon />
        <div
          className={clsx("w-full items-center justify-center", isCollapsed ? "hidden group-hover/nav:flex" : "flex")}
        >
          Search...
          <kbd className="text-secondary ml-auto text-2xs">
            <kbd className="font-sans">⌘</kbd>
            <kbd className="font-sans">K</kbd>
          </kbd>
        </div>
      </button>
    </div>
  );
};
