"use client";

import clsx from "clsx";
import Link from "next/link";
import { Fragment, useCallback, useEffect, useMemo, useState, useContext } from "react";
import { useMediaQuery } from "usehooks-ts";
import { MenuSquareIcon } from "lucide-react";
import { Dialog, Transition } from "@headlessui/react";
import { Sheet, SheetTrigger } from "components/ui/sheet";
import { CartContext } from "context/CartContext";
import NavigationItems from "components/NavigationItems";
import Logo from "components/logo";
import useCurrentUser from "hooks/useCurrentUser";
import useOnlineUsers from "hooks/useOnlineUsers";
import useNavItemsRecordCounts from "hooks/useNavItemsRecordCounts";
import CartModal from "components/Tiles/components/ListGrid/Cart/Modal/modal";
import { NavigationItem } from "types/common";
import { getDefaultNavigationItem } from "utils/pathUtils";
import ShoppingBag from "./shoppingBag";

import NavigationNotifications from "./NavigationNotifications";
import NavigationUserMenu from "./NavigationUserMenu";
import NavigationItemsList from "./NavigationItemsList";

type NavigationTopProps = {
  navigationMenuItems: NavigationItem[];
  isCollapsed: boolean;
};

const NavigationTop = ({ navigationMenuItems, isCollapsed }: NavigationTopProps) => {
  const currentUser = useCurrentUser();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [showNotificationCenter, setShowNotificationCenter] = useState(false);
  const isDesktopView = useMediaQuery("(min-width: 1024px)");
  const cartContext = useContext(CartContext);
  const isClient = typeof window !== "undefined" && window.location.host.startsWith("client.");
  const onCloseMobileMenu = useCallback(() => setShowMobileMenu(false), []);

  const { countByNavItem } = useNavItemsRecordCounts({
    items: navigationMenuItems
  });

  useEffect(() => {
    setIsDesktop(isDesktopView);
  }, [isDesktopView]);
  useOnlineUsers();

  const defaultMenuItem = useMemo(
    () => (typeof window !== "undefined" ? getDefaultNavigationItem(navigationMenuItems || []) : null),
    [navigationMenuItems]
  );

  return (
    <section className="tpnav  consumer-nav border-separator border-b">
      <section
        className={clsx(
          "group/nav mx-auto max-w-[1600px] px-5 py-2 font-matter font-normal tracking-wide lg:px-12 lg:py-6",
          "flex items-center gap-x-5"
        )}
      >
        <Link href={defaultMenuItem?.href || "/"} className="self-center">
          <Logo className="w-auto self-center" />
        </Link>

        {isDesktop && (
          <div className="flex flex-1">
            <NavigationItems
              navigationMenuItems={navigationMenuItems}
              menuPosition="top"
              countByNavItem={countByNavItem}
            />
          </div>
        )}

        <div className="flex items-center justify-between gap-x-4">
          <NavigationNotifications
            isCollapsed={isCollapsed}
            menuPosition="top"
            isOpen={showNotificationCenter}
            onToggle={setShowNotificationCenter}
          />
          <div className={clsx(" mr-0 cursor-pointer", "hidden justify-center lg:flex")}>
            <NavigationUserMenu currentUser={currentUser} />
          </div>
          {isClient && (
            <div>
              <Sheet>
                <SheetTrigger asChild>
                  <div className="relative items-center self-center hover:cursor-pointer">
                    <ShoppingBag />
                    {cartContext && cartContext.cartItems.length > 0 && (
                      <div className="absolute -bottom-1 -right-1">
                        <div className="inline-flex h-[16px] w-[16px] items-center  justify-center rounded-full bg-black">
                          <span className=" text-[10px] text-white">{cartContext.cartItems.length}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </SheetTrigger>
                <CartModal />
              </Sheet>
            </div>
          )}
        </div>
        {!showMobileMenu && (
          <div className="-mt-[1px] mr-1 flex min-w-4 flex-1 cursor-pointer justify-end lg:hidden">
            <MenuSquareIcon className="block h-6 w-6" aria-hidden="true" onClick={() => setShowMobileMenu(true)} />
          </div>
        )}
      </section>
      {!isDesktop && (
        <Transition.Root show={showMobileMenu} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={onCloseMobileMenu}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-neutral-dark-0/40" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enterFrom={"translate-x-full"}
              enterTo={"translate-x-0"}
              leave="transform transition ease-in-out duration-300 sm:duration-300"
              leaveFrom={"translate-x-0"}
              leaveTo={"translate-x-full"}
            >
              <Dialog.Panel
                className={clsx(
                  "bg-background border-separator fixed inset-y-0 z-10 w-64 overflow-y-auto border-l",
                  "right-0"
                )}
                aria-label="Global"
              >
                <NavigationItemsList
                  firstNavItems={[]}
                  isDesktop={isDesktop}
                  showMobileMenu={showMobileMenu}
                  onCloseMobileMenu={onCloseMobileMenu}
                  navigationMenuItems={navigationMenuItems}
                  countByNavItem={countByNavItem}
                />
              </Dialog.Panel>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      )}
    </section>
  );
};

export default NavigationTop;
