import clsx from "clsx";
import { ClockIcon } from "lucide-react";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useRouter } from "next/navigation";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from "components/ui/dropdownMenu";
import useUserHistory from "hooks/useUserHistory";
import EllipisWithTooltip from "components/EllipisWithTooltip";

const NavigationUserRecentHistory = ({
  isCollapsed,
  onMenuOpen,
  open
}: {
  isCollapsed?: boolean;
  onMenuOpen: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
}) => {
  const { data: userHistoryItems, refetch } = useUserHistory();
  const router = useRouter();

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        refetch();
      }
      onMenuOpen(isOpen);
    },
    [onMenuOpen, refetch]
  );
  return (
    <div
      className={clsx(
        "flexitems-center relative justify-center p-1",
        isCollapsed ? "hidden group-hover/nav:flex" : "hidden lg:flex"
      )}
    >
      <DropdownMenu open={open} onOpenChange={handleOpenChange}>
        <DropdownMenuTrigger asChild>
          <ClockIcon className="h-5 w-5 shrink-0 cursor-pointer text-base-primary dark:text-base-dark-primary" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="border-separator w-72 rounded-xl border py-2 " align="start">
          <DropdownMenuLabel className="truncate font-matter font-medium">Recently Viewed</DropdownMenuLabel>
          {!userHistoryItems.length && (
            <DropdownMenuItem disabled className="justify-center text-sm">
              No Page Visits Yet
            </DropdownMenuItem>
          )}
          {userHistoryItems.map((item) => (
            <DropdownMenuItem className="cursor-pointer" key={item.id} onClick={() => router.push(item.pagePath)}>
              <EllipisWithTooltip tooltipProps={{ placement: "right" }} className="text-sm">
                {item.pageTitle}
              </EllipisWithTooltip>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default NavigationUserRecentHistory;
