"use client";
import { useState } from "react";
import clsx from "clsx";
import { XIcon } from "lucide-react";

import { useCallback, useMemo } from "react";
import IconButton from "components/IconButton";
import NavigationItemComponent from "components/NavigationItem";
import NavigationBackButton from "components/NavigationItem/NavigationBackButton";
import NavigationItems from "components/NavigationItems";
import OnlineUsers from "components/OnlineUsers";
import { Search } from "components/Search/Search";
import Modal from "components/Modal";
import ImpersonateUser from "components/Form/ImpersonateUser";
import useCurrentUser from "hooks/useCurrentUser";
import { NavigationItem, RecordItem } from "types/common";
import CreateActionMenu from "./CreateActionsMenu";

type NavigationItemsListProps = {
  isRecord?: boolean;
  showMobileMenu: boolean;
  onCloseMobileMenu: () => void;
  searchBarEnabled?: boolean;
  isCollapsed?: boolean;
  onSearchBoxClick?: (open: boolean) => void;
  showBackButton?: boolean;
  backButtonLabel?: string;
  backButtonLink?: string;
  firstNavItems: NavigationItem[];
  navigationMenuItems: NavigationItem[];
  isDesktop: boolean;
  unreadCount?: number;
  onEditMenuItem?: (input: RecordItem) => void;
  adminTablePageId?: string;
  showAdminMenu?: boolean;
  onCreateNewPage?: () => void;
  countByNavItem?: RecordItem;
};
const NavigationItemsList = ({
  isRecord,
  showMobileMenu,
  onCloseMobileMenu,
  searchBarEnabled,
  isCollapsed,
  onSearchBoxClick,
  showBackButton,
  backButtonLabel,
  backButtonLink = "",
  firstNavItems,
  isDesktop,
  unreadCount,
  onEditMenuItem,
  adminTablePageId,
  navigationMenuItems,
  countByNavItem
}: NavigationItemsListProps) => {
  const currentUser = useCurrentUser();
  const [showImpersonateModal, setShowImpersonateModal] = useState(false);

  const handleImpersonateDisplayClick = useCallback((open: boolean) => {
    setShowImpersonateModal(open);
  }, []);

  const [createActionMenuItems, restNavigationMenuItems] = useMemo(() => {
    const createActionMenuItems = navigationMenuItems.filter((item) => item.additionalConfig?.showAsCreateForm);
    const restNavigationMenuItems = navigationMenuItems.filter((item) => !item.additionalConfig?.showAsCreateForm);
    if (currentUser?.is_admin && `${currentUser?.org_id}` === "1") {
      return [
        [
          ...createActionMenuItems,
          {
            id: "impersonate",
            onClick: () => handleImpersonateDisplayClick(true),
            icon: "user-search",
            hasDivider: true,
            name: "Impersonate User",
            href: ""
          }
        ],
        restNavigationMenuItems
      ];
    }
    return [createActionMenuItems, restNavigationMenuItems];
  }, [navigationMenuItems, currentUser?.is_admin, handleImpersonateDisplayClick, currentUser?.org_id]);

  return (
    <>
      <Modal
        title="Impersonate User Selection"
        className="p-5"
        isOpen={showImpersonateModal}
        onClose={() => handleImpersonateDisplayClick(false)}
        showClose
      >
        <ImpersonateUser
          onSuccess={() => {
            handleImpersonateDisplayClick(false);
          }}
        />
      </Modal>
      <IconButton
        className={clsx("absolute top-2 lg:hidden", isRecord ? "left-3" : "right-3", !showMobileMenu && "hidden")}
        icon={XIcon}
        onClick={onCloseMobileMenu}
      />
      <div className="px-3 pb-2 pt-12 lg:pt-[20px]">
        {searchBarEnabled && <Search isCollapsed={isCollapsed} openSearchBox={onSearchBoxClick} />}
      </div>

      {showBackButton && backButtonLabel ? (
        <NavigationBackButton
          label={backButtonLabel}
          className="hidden lg:flex"
          isCollapsed={isCollapsed}
          backButtonLink={backButtonLink}
        />
      ) : null}

      <nav
        aria-label="Sidebar"
        className={clsx(
          "transition-borderWidth flex-1 py-2 transition-colors duration-75 lg:flex-shrink-0",
          !showMobileMenu && "hidden lg:block"
        )}
      >
        <div className="relative flex h-full shrink-0 flex-col overflow-y-auto">
          {firstNavItems.map((item, index) =>
            item.name === "Notifications" ? (
              isDesktop ? (
                <NavigationItemComponent
                  showCollapsedUnreadIndicator={(unreadCount || 0) > 0}
                  key={`${item.name}-${index}`}
                  item={item}
                  noHref
                  level={0}
                  isCollapsed={isCollapsed}
                />
              ) : null
            ) : (
              <NavigationItemComponent
                key={`${item.name}-${index}`}
                item={item}
                level={0}
                isCollapsed={isCollapsed}
                onEditMenuItem={onEditMenuItem}
              />
            )
          )}
          {!adminTablePageId ? (
            <NavigationItems
              navigationMenuItems={restNavigationMenuItems}
              isCollapsed={isCollapsed}
              onEditMenuItem={onEditMenuItem}
              menuPosition="left"
              countByNavItem={countByNavItem}
            />
          ) : null}
        </div>
      </nav>

      <div
        className={clsx(
          "border-separator w-full items-center gap-2 border-t pr-2 pt-2",
          !showMobileMenu ? "hidden lg:flex" : "flex"
        )}
      >
        <div className="flex-1">
          <OnlineUsers className={clsx("px-6", isCollapsed ? "hidden group-hover/nav:flex" : "")} />
        </div>
        <CreateActionMenu menuItems={createActionMenuItems} />
      </div>
    </>
  );
};

export default NavigationItemsList;
