"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import useUserNotifications from "hooks/useUserNotifications";
import useNotificationsState from "hooks/useNotificationsState";
import useTableActionsState from "hooks/useTableActionsState";
import useWriteRollupUpdate from "hooks/useWriteRollupUpdate";
import useCurrentUser from "hooks/useCurrentUser";
import { Notification } from "types/apiTypes";
import { RecordItem } from "types/common";
import toast, { ToastActionType, ToastId } from "utils/toast";
import { CrudActions, STATIC_SIDEBAR_IDS, SidebarContainer, TOAST_TYPE } from "utils/constants";
import {
  NotificationRecord,
  NOTIFICATION_TYPE,
  transformPayloadToNotificationRecord,
  NOTIFICATION_ACTION,
  NOTIFICATION_TYPE_SKIP_BY_GLOBAL_HANDLER
} from "./utils";
const isLocalDev = !process.env.NEXT_PUBLIC_VERCEL_URL;

/**
 * This Component handles Notifications for our App
 * We have two types for notifications currently:
 * 1.  Action type notifications --> These are triggered by actions webhook url when action is completed, whenever these notifications
 * are created in `user_notifications`, they are added to Notifications context and to the list of liveNotification list within
 * this component, i.e to be shown to user as toast if user is online at the time notification is received.
 *
 * 2. Mention type notification -> These are currently setup for when some user is mentioned in a note and are triggered from subapage webhook
 * whenever note is created/updated. whenever these notifications are created in `user_notifications`, they are added to Notifications context
 * and user can see them in notification center.
 * 3. System type notification -> These are used to show system level notifications to user, like when a table is reset, record page is reset or when app needs to be reloaded
 *
 * 4. Form Modal type notification -> These are used to show form modals to user, like when a user is redirected to a form modal from a notification
 *
 * 5. Info type notification -> These are used to show info/toast to user, like when a user is redirected to a url from a notification
 *
 * 6. Write Rollup type notification -> These are used to update the write rollup column data in the table
 *
 * 7. Confetti type notification -> These are used to show confetti to user, like when a user is redirected to a url from a notification
 *
 * 8. Static Custom form type notification -> These are used to trigger static custom forms.
 *
 * We also have `sendNotification` method available in `useNotificationCenter` hook  which can also be used to send notification
 * from within the App.
 */
const Notifications = () => {
  const { data: userNotifications, isLoading } = useUserNotifications({
    refetchOnWindowFocus: false,
    refetchInterval: false
  });

  const { addPendingNotification, isNotificationPending, removePendingNotification, notificationsList } =
    useNotificationsState();
  const { updateSidebarState, updateFormWebhookAddedRecordId } = useTableActionsState();
  const { getWriteRollupTableDataAndUpdate } = useWriteRollupUpdate();
  const subscribedRef = useRef(false);
  const currentUser = useCurrentUser();
  const router = useRouter();
  const appNewVersionToastId = useRef<ToastId | null>(null);

  const supabaseClient = useSupabaseBrowser();
  const queryClient = useQueryClient();
  // used to show the live notification where are useful if user is online
  // For now the notification type = action are the one
  const [liveNotifications, setLiveNotifications] = useState<NotificationRecord[]>([]);
  const [pendingToastNotifications, setPendingToastNotifications] = useState<
    Array<{ notificationId: string; toastId: ToastId }>
  >([]);
  // Handle notification of type "system"
  const handleSystemNotification = useCallback(
    (notification: NotificationRecord) => {
      if (!notification || notification?.type !== NOTIFICATION_TYPE.SYSTEM) return;
      if (notification.action === NOTIFICATION_ACTION.RESET) {
        toast.success(
          "Due to recent changes the site needs to be reloaded. Please save your work and perform a page refresh",
          {
            autoClose: 30000
          }
        );
        queryClient.invalidateQueries();
      }
      if (notification.action === NOTIFICATION_ACTION.TABLE_RESET) {
        queryClient.invalidateQueries({ queryKey: ["table", notification.tableName] });
      }

      if (notification.action === NOTIFICATION_ACTION.RECORD_RESET) {
        queryClient.invalidateQueries({
          queryKey: ["record", (notification.action_props?.recordSlug || "")?.replace("/", ""), notification.recordId]
        });
        // for cell sidebar
        queryClient.invalidateQueries({ queryKey: ["record", "", notification.recordId] });
      }

      if (notification.action === NOTIFICATION_ACTION.APP_NEW_VERSION) {
        if (appNewVersionToastId.current) {
          toast.dismiss(appNewVersionToastId.current);
        }
        appNewVersionToastId.current = toast.success("A new version is available.", {
          autoClose: false,
          actions: [{ type: ToastActionType.BUTTON, onClick: () => window.location.reload(), label: "Reload" }]
        });
      }
    },
    [queryClient]
  );

  // Display a toast notification for the action when "new" and
  // updates when success/failure is received from the notifications table
  const handleToastNotification = useCallback(
    (
      status: "new" | "success" | "error",
      notificationId: string,
      redirectUrlOrErrMsg?: string,
      additionalProps?: RecordItem
    ) => {
      const pendingNotication = pendingToastNotifications.find(
        (notification) => notification.notificationId === notificationId
      );
      const notification = notificationsList.find((notification) => notification.id === notificationId);

      if (status === "new" && notificationId && !pendingNotication) {
        const newToastId = toast.success("Processing...", {
          autoClose: false
        });
        setPendingToastNotifications((prev) => [...prev, { notificationId, toastId: newToastId }]);
      } else if (status === "success" && pendingNotication) {
        removePendingNotification(notificationId);
        if (additionalProps?.recordId) {
          updateFormWebhookAddedRecordId(additionalProps?.recordId);
        }
        toast.update(pendingNotication.toastId, "Successfully completed action!", {
          autoClose: 3000
        });
        setPendingToastNotifications((prev) =>
          prev.filter((notification) => notification.notificationId !== notificationId)
        );
        if (redirectUrlOrErrMsg) {
          router.push(redirectUrlOrErrMsg);
        }
      } else if (status === "error" && pendingNotication) {
        removePendingNotification(notificationId);
        toast.update(pendingNotication.toastId, redirectUrlOrErrMsg || "Action failed!", {
          autoClose: 3000,
          type: TOAST_TYPE.ERROR
        });
        setPendingToastNotifications((prev) =>
          prev.filter((notification) => notification.notificationId !== notificationId)
        );
      }
      if (additionalProps?.tableName && !notification?.action_props?.noRefetch) {
        // Refetch table data
        queryClient.invalidateQueries({ queryKey: ["table", additionalProps.tableName] });
      }
    },
    [
      pendingToastNotifications,
      setPendingToastNotifications,
      removePendingNotification,
      router,
      updateFormWebhookAddedRecordId,
      queryClient,
      notificationsList
    ]
  );

  // fetch the user notifications and add to notification context
  useEffect(() => {
    if (isLoading) return;
    userNotifications.forEach((notification) => {
      const notificationData = transformPayloadToNotificationRecord(notification);
      if (notificationData.userId === currentUser?.user_id) {
        addPendingNotification(notificationData, false);
      }
    });
  }, [userNotifications, isLoading, addPendingNotification, currentUser?.user_id]);

  // this is used to update context with new notifications being created
  useEffect(() => {
    if (!currentUser?.id || isLocalDev) return;
    const channel = supabaseClient
      .channel("table-db-changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "user_notifications"
        },
        (payload) => {
          if (payload?.new) {
            const notificationRecord = transformPayloadToNotificationRecord(payload.new as Notification);

            // skip specific notification types that are handled by their own handlers
            if (NOTIFICATION_TYPE_SKIP_BY_GLOBAL_HANDLER.includes(notificationRecord.type)) {
              return;
            }

            // Only add if notification is for current user
            if (notificationRecord.userId && notificationRecord.userId === currentUser?.user_id) {
              if (
                notificationRecord.type === NOTIFICATION_TYPE.ACTION &&
                notificationRecord.action === NOTIFICATION_ACTION.CONFETTI
              ) {
                // @ts-ignore
                window.confetti?.({
                  particleCount: 500,
                  spread: 700,
                  origin: { y: 0.5 }
                });
              }

              if (
                notificationRecord.type === NOTIFICATION_TYPE.ACTION &&
                notificationRecord.action === NOTIFICATION_ACTION.WRITE_ROLLUP
              ) {
                if (
                  notificationRecord.action_props?.writeRollupColumnId &&
                  notificationRecord.action_props?.pageId &&
                  notificationRecord.action_props?.sourceId
                ) {
                  getWriteRollupTableDataAndUpdate(
                    notificationRecord.action_props?.writeRollupColumnId,
                    notificationRecord.action_props?.pageId,
                    notificationRecord.action_props?.sourceId
                  );
                }
                return;
              }
              // Used to update an existing toast notification for notification id
              if (
                notificationRecord.type === NOTIFICATION_TYPE.INFO &&
                notificationRecord.action === NOTIFICATION_ACTION.ACTION_SUCCESS
              ) {
                handleToastNotification(
                  "success",
                  notificationRecord.action_props?.id,
                  notificationRecord.redirectUrl,
                  {
                    ...notificationRecord.action_props,
                    tableName: notificationRecord.tableName
                  }
                );
                return;
              }
              if (
                notificationRecord.type === NOTIFICATION_TYPE.INFO &&
                notificationRecord.action === NOTIFICATION_ACTION.ACTION_ERROR
              ) {
                handleToastNotification(
                  "error",
                  notificationRecord.action_props?.id,
                  notificationRecord.action_props?.message
                );
                // Refetch table data
                queryClient.invalidateQueries({ queryKey: ["table", notificationRecord.tableName] });
                return;
              }
              addPendingNotification(notificationRecord);

              // add action type notifications to the live notifications list
              if (notificationRecord.type === NOTIFICATION_TYPE.ACTION) {
                setLiveNotifications((prev) => [
                  ...prev,
                  {
                    ...notificationRecord,
                    read: false
                  }
                ]);
              }

              // display the static custom form view
              if (notificationRecord.type === NOTIFICATION_TYPE.STATIC_CUSTOM_FORM) {
                updateSidebarState(
                  {
                    isOpen: true,
                    action: CrudActions.CREATE_CUSTOM,
                    formViewId: notificationRecord.action_props?.viewId,
                    showDetailView: false,
                    skiploadSidebaronSubmission: true,
                    actionSource: "Notification Context"
                  },
                  STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
                );
                return;
              }

              // Redirect to the url if provided
              if (notificationRecord.redirectUrl) {
                router.push(notificationRecord.redirectUrl);
              }

              if (notificationRecord.type === NOTIFICATION_TYPE.FORM_MODAL) {
                if (notificationRecord.action_props?.tablePath) {
                  updateSidebarState(
                    {
                      isOpen: true,
                      action: CrudActions.CREATE,
                      record: { id: notificationRecord.recordId },
                      tablePath: notificationRecord.action_props?.tablePath,
                      title: notificationRecord.action_props?.title,
                      showDetailView: false,
                      skiploadSidebaronSubmission: true,
                      container: SidebarContainer.Modal
                    },
                    STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
                  );
                }
              }
            }

            // if user id is not present and notification is of type action and action is confetti show to all user
            if (
              !notificationRecord.userId &&
              notificationRecord.type === NOTIFICATION_TYPE.ACTION &&
              notificationRecord.action === NOTIFICATION_ACTION.CONFETTI
            ) {
              // @ts-ignore
              window.confetti?.({
                particleCount: 500,
                spread: 700,
                origin: { y: 0.5 }
              });
            }
            if (notificationRecord.type === NOTIFICATION_TYPE.SYSTEM) {
              handleSystemNotification(notificationRecord);
            }
          }
        }
      )
      .subscribe();
    subscribedRef.current = true;
    return () => {
      channel.unsubscribe();
    };
  }, [
    supabaseClient,
    currentUser,
    addPendingNotification,
    handleSystemNotification,
    handleToastNotification,
    router,
    updateSidebarState,
    queryClient,
    getWriteRollupTableDataAndUpdate
  ]);

  // this is used to show current notification when user is online
  useEffect(() => {
    if (!liveNotifications.length) return;
    const { message, id: notificationId, tableName, type, recordId } = liveNotifications[liveNotifications.length - 1];
    // Trigger refetch for the table and record if the notification is of type action
    if (type === NOTIFICATION_TYPE.ACTION && tableName) {
      queryClient.invalidateQueries({ queryKey: ["table", tableName], exact: false });
      if (recordId) {
        queryClient.refetchQueries({ queryKey: ["record", recordId], exact: false });
      }
    }
    // show the toast for the notification and remove from the context
    if (message && isNotificationPending(notificationId)) {
      toast.success(message);
      removePendingNotification(notificationId);
    }
  }, [liveNotifications, isNotificationPending, removePendingNotification, queryClient]);

  // This effect tracks added notifications for toast notifications
  useEffect(() => {
    if (!notificationsList?.length) return;
    const lastNotification = notificationsList[notificationsList.length - 1];
    const isExistingToastNotification = pendingToastNotifications.find(
      (notification) => notification.notificationId === lastNotification.id
    );
    // This prop is only set from the app, the notification from Supabase does not contain this value
    if (lastNotification?.action_props?.hasWaitForSuccess && !isExistingToastNotification) {
      handleToastNotification("new", lastNotification.id);
    }
  }, [notificationsList, handleToastNotification, pendingToastNotifications]);

  return <div />;
};

export default Notifications;
