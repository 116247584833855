"use client";

import React, { createContext, useState, useCallback } from "react";
import type { AlgoliaSearchTable } from "types/baTypes";

export interface AdminContextState {
  searchTableState: AlgoliaSearchTable | undefined;
  resetState: () => void;
  updateSearchTableStateProp: (newPropValues: Partial<AlgoliaSearchTable>) => void;
  pageDataSyncLoading?: boolean; // Tracks data sync loading state
  updatePageDataSyncLoading: (loading: boolean) => void;
  refetchPageData?: boolean; // This is used to trigger a refetch from the Admin layout page
  updateRefetchPageData: (refetch: boolean) => void; // This method ensures the page data in the Admin is refetched after an operation
}

export const AdminContext = createContext<AdminContextState | null>(null);

const { Provider } = AdminContext;

export const AdminContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchTableState, setSearchTableState] = useState<AlgoliaSearchTable | undefined>();
  const [pageDataSyncLoading, setPageDataSyncLoading] = useState<boolean>(false);
  const [refetchPageData, setRefetchPageData] = useState<boolean>(false);

  const updateSearchTableStateProp = useCallback((newPropValues: Partial<AlgoliaSearchTable>) => {
    setSearchTableState((prev: any) => {
      return {
        ...prev,
        ...newPropValues,
      };
    });
  }, []);

  const updatePageDataSyncLoading = useCallback((loading: boolean) => {
    setPageDataSyncLoading(loading);
  }, []);

  const updateRefetchPageData = useCallback((refetch: boolean) => {
    setRefetchPageData(refetch);
  }, []);

  const resetState = useCallback(() => {
    setSearchTableState(undefined);
  }, []);

  return (
    <Provider
      value={{
        searchTableState,
        updateSearchTableStateProp,
        resetState,
        pageDataSyncLoading,
        updatePageDataSyncLoading,
        refetchPageData,
        updateRefetchPageData,
      }}
    >
      {children}
    </Provider>
  );
};
