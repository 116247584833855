"use client";
import { VercelToolbar as Toolbar } from "@vercel/toolbar/next";
import useCurrentUser from "hooks/useCurrentUser";
import useUIState from "hooks/useUIState";

/** Show vercel toolbar for admin users */
const VercelToolbar = () => {
  const currentUser = useCurrentUser();
  const { uiState } = useUIState();
  return currentUser?.is_admin && uiState?.vercelToolbarEnabled ? <Toolbar /> : null;
};

export default VercelToolbar;
