import { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { PostgrestError } from "@supabase/supabase-js";
import { UserHistory } from "types/baTypes";
import { getUserHistoryApiFieldsFromUserHistory } from "utils/apiUtils";
import { getUserHistory } from "lib/supabaseApi";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { QueryHookOptions } from "types/common";
import { ERROR_TYPES } from "utils/constants";
import { mapApiUserHistoryFieldsToUserHistory } from "utils/pageUtils";
import useCurrentUser from "./useCurrentUser";
import useAddRecord from "./useAddRecord";
import useErrorLogger from "./useErrorLogger";

const IS_LOCAL_DEV = !process.env.NEXT_PUBLIC_VERCEL_URL;

const useUserHistory = (options?: QueryHookOptions) => {
  const { logError } = useErrorLogger();
  const { addRecordAsync } = useAddRecord();
  const currentUser = useCurrentUser();
  const supabaseClient = useSupabaseBrowser();

  const { data, refetch } = useQuery({
    queryKey: ["user_history", currentUser?.user_id, currentUser?.org_id],
    queryFn: () => getUserHistory(supabaseClient, currentUser?.user_id, currentUser?.org_id),
    enabled: !!currentUser?.user_id,
    refetchOnMount: true,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    ...options
  });

  useEffect(() => {
    if (!data?.error) {
      return;
    }
    logError({
      error: data.error as PostgrestError,
      source: "useUserHistory",
      type: ERROR_TYPES.HOOKS,
      message: (data.error as PostgrestError).message || `Error fetching user ${currentUser?.user_id} history`,
      url: window.location.href,
      additionalInfo: {
        userId: currentUser?.user_id,
        organizationId: currentUser?.org_id
      }
    });
  }, [data?.error, currentUser?.user_id, currentUser?.org_id, logError]);

  const historyItems = useMemo(() => {
    return (data?.data || []).map((entry) => mapApiUserHistoryFieldsToUserHistory(entry));
  }, [data?.data]);

  const addToHistory = useCallback(
    (input: Partial<UserHistory>) => {
      if (!currentUser?.user_id || IS_LOCAL_DEV) return;

      return addRecordAsync({
        tableName: "user_history",
        input: getUserHistoryApiFieldsFromUserHistory({ ...input, peopleId: currentUser.user_id })
      });
    },
    [addRecordAsync, currentUser?.user_id]
  );
  return {
    data: historyItems,
    addToHistory,
    refetch
  };
};

export default useUserHistory;
