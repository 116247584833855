import { ExpandIcon, X as CancelIcon } from "lucide-react";
import clsx from "clsx";
import IconButton from "components/IconButton";
type CollapsibleItemProps = {
  title?: string;
  className?: string;
  onCancel: () => void;
  onExpand: () => void;
};

const CollapsibleItem = ({ title = "", onCancel, onExpand, className }: CollapsibleItemProps) => {
  return (
    <div
      className={clsx(
        "flex w-[280px] items-center gap-2.5 rounded-tl-xl rounded-tr-xl border-b border-neutral-200 bg-white px-3 py-2 drop-shadow-xl dark:border-neutral-dark-200 dark:bg-black",
        className
      )}
    >
      <span className="ml-3 flex-1 text-ellipsis whitespace-nowrap text-sm font-medium text-base-primary dark:text-base-dark-primary">
        {title}
      </span>
      <IconButton icon={ExpandIcon} onClick={onExpand} />
      <IconButton icon={CancelIcon} onClick={onCancel} />
    </div>
  );
};

export default CollapsibleItem;
