import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useRouter } from "next/navigation";
import groupBy from "lodash/groupBy";
import type { Dictionary } from "lodash";
import has from "lodash/has";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import { v4 } from "uuid";

import { useGeneralActionsUpdater } from "context/GeneralActionsContext";
import CommandMenu from "components/CommandMenu";
import useCurrentUser from "hooks/useCurrentUser";
import useNavigationMenu from "hooks/useNavigationMenu";
import useOpenRecordInNestedView from "hooks/useOpenRecordInNestedView";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import useSearchTables from "hooks/useSearchTables";
import useInLayout from "hooks/useInLayout";
import useAllPagesLite from "hooks/useAllPagesLite";
import useSchemaState from "hooks/useSchemaState";
import useTableActionsState from "hooks/useTableActionsState";
import { AlgoliaSearchTable, TableColumnType, MasterIndexLinkedConfigWithJoinInfo } from "types/baTypes";
import { NavigationItem, RecordItem } from "types/common";
import { algoliaSearchClient } from "utils/algolia";
import {
  ALGOLIA_ACTIONS_TYPE,
  ALGOLIA_MASTER_INDEX,
  ALGOLIA_MENU_ITEMS_INDEX,
  CellType,
  CrudActions,
  FORM_TRIGGER_TYPES,
  RELATION_TYPES,
  SidebarContainer,
  USER_TYPE
} from "utils/constants";
import { flatMapArrayWithChildrens, getGenericCellValuesFromRecord } from "utils/dataUtils";
import {
  AlgoliaMultiSearchResults,
  AlgoliaSearchResult,
  generateQueries,
  runSearch,
  transformNavigationMenuItemsToAlgoliaResults,
  getSearchHitResult
} from "./utils";

const algoliaIndices = [ALGOLIA_MASTER_INDEX];
const ALGOLIA_CREATE_ACTION = "CREATE_ITEM";

export const SearchContainer = ({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<AlgoliaMultiSearchResults[]>();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = React.useState<string[]>(["home"]);
  const [nestedPageItem, setNestedPageItem] = useState<AlgoliaSearchResult | null>(null);
  const [nestedPageId, setNestedPageId] = useState<string | undefined>();
  const [navItemsByPageId, setNavItemsByPageId] = useState<Dictionary<RecordItem[]>>({});
  const [createNavItemsByPageId, setCreateNavItemsByPageId] = useState<Dictionary<RecordItem[]>>({});
  const [emptySearchFacets, setEmptySearchFacets] = useState<RecordItem>();
  const [currentFacetInView, setCurrentFacetInView] = useState<string | undefined>("All");
  const [emptySearchResults, setEmptySearchResults] = useState<{ [key: string]: AlgoliaMultiSearchResults[] }>();
  const emptySearchRanForContextIds = useRef<string[]>([]);

  const { currentRecordId, recordViewParentPage, currentProjectId } = useTableActionsState();
  const { triggerFileUploadAction } = useGeneralActionsUpdater();
  const { isTable, isRecord } = useInLayout();
  const {
    setParams,
    activeSearchContexts,
    updateHiddenSearchContextIds,
    hiddenSearchContextIds,
    pushActiveSearchContext,
    removeActiveSearchContext,
    resetActiveSearchContexts,
    updateCurrentSearchContextProjectId,
    updateCurrentSearchContextRecordId
  } = useSearchQueryParams();
  const router = useRouter();
  const { openRecordInNestedView } = useOpenRecordInNestedView();
  const currentUser = useCurrentUser();
  const { schemaInstance, schema } = useSchemaState();
  const { data: allPagesData } = useAllPagesLite();

  const isStaffUser = currentUser?.type === USER_TYPE.STAFF;
  const activePage = pages[pages.length - 1];
  const isHome = activePage === "home";

  const { data: searchTableData } = useSearchTables({
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { data: nestedPageMenuItems, isLoading: menuItemsLoading } = useNavigationMenu({
    isMain: !isRecord && !nestedPageItem?.id && !recordViewParentPage?.id,
    pageId: nestedPageId ? nestedPageId : isRecord ? recordViewParentPage?.id || "" : "",
    recordSlug: nestedPageItem?.resultPath
      ? nestedPageItem?.resultPath.replace("/", "")
      : isRecord && recordViewParentPage?.path
        ? recordViewParentPage.path.replace("/", "")
        : undefined,
    recordId: nestedPageItem?.id ? nestedPageItem?.id : isRecord && currentRecordId ? currentRecordId : undefined,
    allPages: allPagesData || undefined,
    hookOptions: {
      enabled: (!isRecord || (isRecord && !!recordViewParentPage?.id)) && open // do not fetch if the search is not open
    }
  });
  const finalNavItemKey = nestedPageItem?.pageId
    ? `${nestedPageItem?.pageId}`
    : isRecord && recordViewParentPage?.id
      ? `${recordViewParentPage?.id}`
      : "global";

  const finalActiveSearchContexts = useMemo(() => {
    return activeSearchContexts?.filter(
      (cntxt) => !hiddenSearchContextIds?.length || !hiddenSearchContextIds?.includes(cntxt.id)
    );
  }, [activeSearchContexts, hiddenSearchContextIds]);

  // check if any reserved index is enabled for global search
  const useMasterIndexForGlobalSearch = useMemo(() => {
    return !!searchTableData?.find(
      (searchTable) => searchTable.isReservedIndex && searchTable.indexName === ALGOLIA_MASTER_INDEX
    )?.additionalConfig?.use_for_global_search;
  }, [searchTableData]);

  const allMasterConfigLinkedConfig = useMemo(() => {
    if (!searchTableData || !schema) return [];
    const finalMasterLinkedConfig: MasterIndexLinkedConfigWithJoinInfo[] = [];

    const colNamesInList: string[] = [];
    searchTableData.forEach((searchTable) => {
      if (searchTable.masterIndexConfig?.linkedConfig?.length) {
        const tableProps = schemaInstance?.extendedSchema?.[searchTable.tableName];
        const joinTables = tableProps?.joinTables?.map((jt) => jt.tableName) || [];
        const finalList: MasterIndexLinkedConfigWithJoinInfo[] = searchTable.masterIndexConfig.linkedConfig
          .map((conf) => {
            const comparisonVal = conf.type === RELATION_TYPES.JOIN ? conf.syncLabel || "" : conf.columnName;
            if (!colNamesInList.includes(comparisonVal)) {
              colNamesInList.push(comparisonVal);
              let joinKeyToTable = undefined;
              if (conf.type === RELATION_TYPES.JOIN && joinTables?.includes(conf.tableName)) {
                const joinTableData = tableProps?.joinTables?.find((jt) => jt.tableName === conf.tableName);
                if (joinTableData) {
                  joinKeyToTable = joinTableData.joinAttributes?.find(
                    (ja) => ja.attributeId === conf.columnName
                  )?.table;
                }
              }
              return {
                ...conf,
                joinKeyToTable
              };
            }
            return undefined;
          })
          .filter(Boolean) as MasterIndexLinkedConfigWithJoinInfo[];
        finalMasterLinkedConfig.push(...finalList);
      }
    });
    return finalMasterLinkedConfig;
  }, [searchTableData, schemaInstance, schema]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        setSearchResults(undefined);
        setSearchValue("");
        setPages(["home"]);
        setCurrentFacetInView("All");
        if (!isTable) {
          updateHiddenSearchContextIds();
        } else {
          resetActiveSearchContexts();
        }
      }
      setOpen(open);
    },
    [setOpen, updateHiddenSearchContextIds, isTable, resetActiveSearchContexts]
  );

  const createActionItems: AlgoliaSearchResult[] = useMemo(() => {
    if (!searchTableData || !isStaffUser) return [];
    return searchTableData
      .filter((searchTable) => searchTable.hasCreateAction)
      .map((searchTable) => ({
        id: ALGOLIA_ACTIONS_TYPE.CREATE,
        objectID: `${ALGOLIA_ACTIONS_TYPE.CREATE}_action`,
        title: `Create ${searchTable.title}`,
        resultIcon: "plus",
        index: searchTable.indexName,
        searchTableTitle: searchTable.title,
        searchTableSortOrder: searchTable.sortOrder
      }));
  }, [searchTableData, isStaffUser]);

  const masterIndexSearchResults = useMemo(() => {
    if (!searchResults || !useMasterIndexForGlobalSearch) return {};
    const masterIndexSearchResult = searchResults.find((result) => result.index === ALGOLIA_MASTER_INDEX);
    if (!masterIndexSearchResult) return {};

    const finalGroups: RecordItem = {};
    const allAvailableFacets = masterIndexSearchResult.facets?.entityType;
    if (!isEmpty(allAvailableFacets)) {
      // We create groups based on facets

      Object.keys(allAvailableFacets || {}).forEach((facet) => {
        finalGroups[facet] = {
          hits:
            currentFacetInView === facet || finalActiveSearchContexts?.length
              ? getSearchHitResult(
                  masterIndexSearchResult.hits.filter((hit) => hit.entityType === facet),
                  searchTableData
                )
              : [],
          total: allAvailableFacets?.[facet]
        };
      });
      if (currentFacetInView === "All") {
        finalGroups["All"] = { hits: getSearchHitResult(masterIndexSearchResult.hits, searchTableData) };
      }
    } else {
      const finalHits = getSearchHitResult(masterIndexSearchResult.hits, searchTableData);
      finalGroups["All"] = { hits: finalHits };
    }
    return finalGroups;
  }, [
    searchResults,
    useMasterIndexForGlobalSearch,
    searchTableData,
    currentFacetInView,
    finalActiveSearchContexts?.length
  ]);

  // Only handles non master index search results
  const searchResultsList = useMemo(() => {
    if (!searchResults) return {};
    let finalSearchResults: AlgoliaSearchResult[] = [];
    searchResults
      .filter((result) => result.index !== ALGOLIA_MASTER_INDEX)
      .forEach((searchResult) => {
        const { index, hits } = searchResult;
        const finalHits: AlgoliaSearchResult[] = [];
        hits.forEach((hit) => {
          const searchTableInfo = searchTableData?.find(
            (tableData: AlgoliaSearchTable) => hit.index === tableData.indexName
          );
          const searchResultValue: RecordItem | undefined = hit;
          const finalSearchTableTitle = searchTableInfo?.title;

          if (index === ALGOLIA_MENU_ITEMS_INDEX || hit.entityIndex === ALGOLIA_MENU_ITEMS_INDEX) {
            return {
              ...hit,
              searchTableTitle: finalSearchTableTitle,
              resultIcon: hit.icon || hit.uniqueAttributes?.icon,
              title: `${hit.name}`,
              searchTableSortOrder: searchTableInfo?.sortOrder
            };
          }

          const finalSearchResult: AlgoliaSearchResult = {
            ...hit,
            searchTableTitle: finalSearchTableTitle,
            resultPath: searchTableInfo?.linkedPage?.path,
            showInSidebar: searchTableInfo?.resultConfig?.showInSidebar,
            searchTableSortOrder: searchTableInfo?.sortOrder
          };

          if (searchResultValue) {
            if (searchTableInfo?.resultConfig?.resultColumn) {
              finalSearchResult.resultColumn = searchTableInfo.resultConfig.resultColumn;
              const value = getGenericCellValuesFromRecord({
                column: searchTableInfo.resultConfig.resultColumn as TableColumnType,
                recordData: searchResultValue,
                inGlobalSearch: true,
                columns: (searchTableInfo?.columns || []) as TableColumnType[]
              });

              finalSearchResult.resultColumnValue = Array.isArray(value) ? value[0] : value;
            } else {
              const resultTitleCol = searchTableInfo?.columns?.find((col) => col.type === CellType.TEXT);
              finalSearchResult.title = resultTitleCol
                ? searchResultValue[resultTitleCol.header || ""]
                : searchResultValue.id;
            }
          }

          finalHits.push(finalSearchResult);
        });

        finalSearchResults.push(...finalHits);
      });
    if (!searchValue || !(searchValue.length > 1)) {
      finalSearchResults = [...finalSearchResults, ...createActionItems];
    } else {
      const filteredActionItems = createActionItems.filter(
        (item) =>
          item.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          finalSearchResults.some((result) => result.searchTableTitle === item.searchTableTitle)
      );
      finalSearchResults = [...finalSearchResults, ...filteredActionItems];
    }

    const groupedResults = groupBy(finalSearchResults, "searchTableTitle");

    // return groupedResult sortBy having create action as firstElement
    return Object.keys(groupedResults).reduce((acc: Dictionary<AlgoliaSearchResult[]>, key) => {
      acc[key] = sortBy(groupedResults[key], ({ id }) => (id === ALGOLIA_ACTIONS_TYPE.CREATE ? 1 : 0));
      return acc;
    }, {});
  }, [searchResults, searchTableData, createActionItems, searchValue]);

  const runSearchRequest = useCallback(
    (value: string, currentFacetInViewOverride?: string) => {
      const queries = generateQueries({
        search: value,
        indices: algoliaIndices,
        userType: currentUser?.type,
        isAdmin: currentUser?.is_admin,
        searchParamValues: {
          hitsPerPage: 20
        },
        searchContexts: finalActiveSearchContexts,
        allMasterConfigLinkedConfig,
        searchTableData,
        currentFacetInView:
          (((finalActiveSearchContexts?.length && value.length > 2) ||
            (!currentFacetInViewOverride && currentFacetInView === "All")) &&
            !currentFacetInViewOverride) ||
          currentFacetInViewOverride === "All"
            ? undefined
            : currentFacetInViewOverride || currentFacetInView,
        orgId: currentUser?.org_id
      });
      setLoading(true);
      runSearch(algoliaSearchClient, queries, {
        onSuccess: (searchResults) => {
          setSearchResults(searchResults);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        }
      });
    },
    [currentUser, finalActiveSearchContexts, allMasterConfigLinkedConfig, searchTableData, currentFacetInView]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (currentFacetInView !== "Menus") {
        // We search within the menu results directly within CommandMenu
        if (value?.length > 2) {
          runSearchRequest(value);
        } else {
          setSearchResults(undefined);
        }
      }
    },
    [runSearchRequest, currentFacetInView]
  );

  const handleAddFileAction = useCallback(
    ({
      pageId,
      parentRecordId,
      parentRecordTable,
      parentRecordSlug,
      tableName
    }: {
      parentRecordId?: string;
      parentRecordTable?: string;
      parentRecordSlug?: string;
      tableName?: string;
      pageId: string;
    }) => {
      const parentRecordInfo = parentRecordId
        ? {
            path: parentRecordSlug,
            recordId: parentRecordId,
            tableName: parentRecordTable
          }
        : undefined;

      triggerFileUploadAction({
        isOpen: true,
        currentAction: "uploadFiles",
        actionProps: {
          tableName,
          pageId,
          parentRecordInfo,
          createdIn: "Search",
          createdInPath: window.location.pathname,
          trigger: FORM_TRIGGER_TYPES.SEARCH
        }
      });
    },
    [triggerFileUploadAction]
  );

  const handleCreateClicked = useCallback(
    (menuItem: RecordItem) => {
      if (!menuItem?.pageSlug && !menuItem?.tabForPage?.id) {
        return;
      }
      const lastSearchContext = finalActiveSearchContexts?.[finalActiveSearchContexts?.length - 1];
      let finalRecordId = currentProjectId || currentRecordId;
      let finalSlug = menuItem.pageSlug;
      if (lastSearchContext?.recordId) {
        finalRecordId = lastSearchContext.recordId;
        finalSlug =
          lastSearchContext.tableName === "projects"
            ? "/projects"
            : allPagesData?.find(
                (page) => page.table_name === lastSearchContext.tableName && page.user_type === currentUser?.type
              )?.path;
        updateCurrentSearchContextRecordId(lastSearchContext.recordId);
        if (lastSearchContext.tableName === "projects") {
          updateCurrentSearchContextProjectId(lastSearchContext.recordId);
        }
      }
      const isFilesCreate = menuItem.ui_page_id?.table_name === "files";
      if (isFilesCreate) {
        handleAddFileAction({
          pageId: menuItem.ui_page_id?.id || menuItem.tabForPage?.id || "",
          parentRecordId: finalRecordId,
          parentRecordTable: lastSearchContext?.tableName,
          parentRecordSlug: finalSlug,
          tableName: menuItem.ui_page_id?.table_name
        });
      } else if (!!menuItem.tabAddManyPageId) {
        setParams({
          form: {
            tablePath: menuItem.pageSlug || menuItem.tabForPage?.path,
            sidebar: SidebarContainer.Sidebar,
            action: undefined,
            parentRecordId: finalRecordId,
            parentRecordSlug: !finalActiveSearchContexts?.length ? recordViewParentPage?.path || "" : finalSlug,
            trigger: FORM_TRIGGER_TYPES.SEARCH,
            add: {
              addPageId: menuItem.tabAddManyPageId || "",
              expanded: !!menuItem?.defaultAddExpanded,
              tabId: menuItem?.pageTabId || ""
            }
          }
        });
      } else {
        setParams({
          form: {
            tablePath: menuItem.pageSlug || menuItem.tabForPage?.path,
            sidebar: SidebarContainer.Sidebar,
            action: CrudActions.CREATE,
            parentRecordId: finalRecordId,
            parentRecordSlug: !finalActiveSearchContexts?.length ? recordViewParentPage?.path || "" : finalSlug,
            trigger: FORM_TRIGGER_TYPES.SEARCH
          }
        });
      }

      setTimeout(() => {
        handleOpenChange(false);
      }, 300);
    },
    [
      currentProjectId,
      currentRecordId,
      finalActiveSearchContexts,
      handleOpenChange,
      recordViewParentPage?.path,
      setParams,
      allPagesData,
      currentUser?.type,
      updateCurrentSearchContextRecordId,
      updateCurrentSearchContextProjectId,
      handleAddFileAction
    ]
  );

  const handleResultClick = (resultItem: AlgoliaSearchResult, addToSearch?: boolean) => {
    const resultItemValue =
      resultItem?.index === ALGOLIA_MASTER_INDEX
        ? has(resultItem, "uniqueAttributes")
          ? resultItem.uniqueAttributes
          : resultItem
        : resultItem;
    if (!resultItemValue) return;

    if (addToSearch && resultItemValue?.id) {
      if (resultItem?.index === ALGOLIA_MASTER_INDEX) {
        const entityTable = searchTableData?.find(
          (tableData: AlgoliaSearchTable) => resultItem.entityIndex === tableData.indexName
        );
        if (entityTable) {
          const cntxtId = v4();
          // We need to add this to context
          pushActiveSearchContext({
            id: cntxtId,
            title: resultItem?.name || resultItem?.coreAttributes,
            tableName: entityTable.tableName,
            recordId: resultItemValue.id
          });
          setSearchValue("");
          if (entityTable?.linkedPage?.id) {
            setNestedPageId(entityTable.linkedPage.id);
            setNestedPageItem({
              id: resultItemValue.id,
              objectID: resultItem?.entityIndex,
              contextId: cntxtId,
              resultPath: entityTable.linkedPage.path,
              pageId: entityTable.linkedPage.id
            });
          }
        }
      }
      return;
    }
    // handle action results
    if (resultItem.index === ALGOLIA_CREATE_ACTION) {
      handleCreateClicked(resultItem);
      return;
    } else if (
      resultItem.index === ALGOLIA_MENU_ITEMS_INDEX ||
      resultItem.entityIndex === ALGOLIA_MENU_ITEMS_INDEX ||
      resultItem.item_link
    ) {
      handleOpenChange(false);
      if (resultItemValue.url) {
        window.open(resultItemValue.url, resultItemValue.open_in_new_tab ? "_blank" : "_self");
      } else {
        router.push(resultItemValue.item_link);
      }
    } else {
      // handle other result items
      if (resultItem.resultPath) {
        if (resultItemValue) {
          if (resultItem.showInSidebar) {
            handleOpenChange(false);
            openRecordInNestedView(resultItemValue, resultItem.resultPath, { redirectOnExpand: true });
          } else {
            handleOpenChange(false);
            // TODO: Make this dynamic
            if (
              finalActiveSearchContexts?.[0]?.tableName === "projects" &&
              finalActiveSearchContexts?.length > 1 &&
              finalActiveSearchContexts?.[1]?.isEntityType
            ) {
              // Look for nested menu items that may match the facet
              const projectSearchTable = searchTableData?.find((st) => st.tableName === "projects");
              const entityFacetContext = finalActiveSearchContexts?.[1];
              if (projectSearchTable?.linkedPage?.id && navItemsByPageId?.[projectSearchTable?.linkedPage?.id]) {
                const menuItems = navItemsByPageId[projectSearchTable.linkedPage.id];
                const matchedMenuItem = menuItems.find((menuItem) => menuItem.title === entityFacetContext.title);
                if (matchedMenuItem && matchedMenuItem?.href) {
                  router.push(`${matchedMenuItem.href}/${resultItemValue.id}`);
                  return;
                }
              }
            } else if (
              finalActiveSearchContexts?.[0]?.tableName === "projects" &&
              finalActiveSearchContexts?.[0]?.recordId
            ) {
              const projectSearchTable = searchTableData?.find((st) => st.tableName === "projects");
              if (projectSearchTable?.linkedPage?.id && navItemsByPageId?.[finalNavItemKey]?.length) {
                const menuItems = navItemsByPageId[finalNavItemKey];
                const matchedMenuItem = menuItems.find((menuItem) => menuItem.title === resultItem.entityType);
                if (matchedMenuItem && matchedMenuItem?.href) {
                  router.push(`${matchedMenuItem.href}/${resultItemValue.id}`);
                  return;
                }
              }
            }
            router.push(`/r${resultItem.resultPath}/${resultItemValue.id}`);
          }
        }
      }
    }
  };

  const setIndexFromFetchedFacetResult = useCallback(
    (facetHitResult: AlgoliaMultiSearchResults[]) => {
      if (facetHitResult?.length) {
        const matchingResultForFacet = facetHitResult[0];
        if (matchingResultForFacet.hits?.length) {
          const cntxtId = v4();
          pushActiveSearchContext({
            id: cntxtId,
            title: matchingResultForFacet.hits?.[0]?.entityType,
            tableName: matchingResultForFacet.hits?.[0]?.entityIndex, // We use this to unset the current active index
            isEntityType: true
          });
          setSearchValue("");
          setSearchResults(undefined);
          const entityTable = searchTableData?.find(
            (st) => st.indexName === matchingResultForFacet.hits?.[0]?.entityIndex
          );
          if (entityTable?.linkedPage?.id) {
            setNestedPageId(entityTable.linkedPage.id);
            setNestedPageItem({
              objectID: matchingResultForFacet.hits?.[0]?.entityIndex,
              contextId: cntxtId,
              resultPath: entityTable.linkedPage.path,
              pageId: entityTable.linkedPage.id
            });
          }
        }
      }
    },
    [pushActiveSearchContext, searchTableData]
  );

  const updateCurrentFacetInView = useCallback(
    (facet: string, withSearchValue?: string) => {
      setCurrentFacetInView(facet);

      runSearchRequest(withSearchValue || "", facet);
    },
    [runSearchRequest]
  );

  // This method is called when the user wants to search within a facet
  const handleNestedFacetClick = useCallback(
    async (facet: string) => {
      if (!facet) return;
      // We need to search within this facet entityIndex next
      if (searchResults?.length) {
        const masterIndexResults = searchResults.find((result) => result.index === ALGOLIA_MASTER_INDEX);
        if (masterIndexResults) {
          const matchingResultForFacet = masterIndexResults.hits.find((hit) => hit.entityType === facet);
          if (matchingResultForFacet) {
            // Push to active context
            pushActiveSearchContext({
              id: v4(),
              title: facet,
              tableName: matchingResultForFacet.entityIndex, // We use this to unset the current active index
              isEntityType: true
            });
            setSearchValue("");
            setSearchResults(undefined);
            return;
          }
        }
      }
      // We need to query the master index for this facet to retrieve entityIndex
      runSearch(
        algoliaSearchClient,
        [
          {
            query: "",
            indexName: ALGOLIA_MASTER_INDEX,
            params: {
              facetFilters: [`entityType:${facet}`],
              hitsPerPage: 1
            }
          }
        ],
        {
          onSuccess: setIndexFromFetchedFacetResult
        }
      );
    },
    [searchResults, pushActiveSearchContext, setIndexFromFetchedFacetResult]
  );

  const getEmtySearchFacets = useCallback(() => {
    const queries = generateQueries({
      search: "",
      indices: algoliaIndices,
      userType: currentUser?.type,
      isAdmin: currentUser?.is_admin,
      searchParamValues: {
        hitsPerPage: 5
      },
      searchContexts: finalActiveSearchContexts,
      allMasterConfigLinkedConfig,
      searchTableData,
      orgId: currentUser?.org_id
    });
    runSearch(algoliaSearchClient, queries, {
      onSuccess: (searchResults) => {
        const emptyFacetsCount: RecordItem = {};
        searchResults.forEach((result) => {
          Object.keys(result.facets?.entityType || {}).forEach((facet) => {
            emptyFacetsCount[facet] = {
              total: result.facets?.entityType[facet]
            };
          });
          return {
            ...result
          };
        });
        emptyFacetsCount["All"] = { total: searchResults?.[0]?.nbHits };
        setSearchResults(searchResults);

        if (finalActiveSearchContexts?.length) {
          setEmptySearchResults((prev) => ({
            ...prev,
            [finalActiveSearchContexts?.[0]?.id]: searchResults
          }));

          setEmptySearchFacets((prev) => ({
            ...prev,
            [finalActiveSearchContexts?.[0]?.id]: emptyFacetsCount
          }));
        } else {
          setEmptySearchResults((prev) => ({
            ...prev,
            All: searchResults
          }));
          setEmptySearchFacets((prev) => ({
            ...prev,
            All: emptyFacetsCount
          }));
        }

        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      }
    });
  }, [finalActiveSearchContexts, currentUser, searchTableData, allMasterConfigLinkedConfig]);

  const onRemoveActiveSearchContextClick = useCallback(
    (contextId: string) => {
      if (!contextId) return;
      updateHiddenSearchContextIds(contextId);
      setSearchResults(undefined);
      setSearchValue("");
      const contextDeletedIndx = finalActiveSearchContexts?.findIndex((cntxt) => cntxt.id === contextId);
      if (isTable || (contextDeletedIndx && contextDeletedIndx > 0)) {
        removeActiveSearchContext(contextId);
        setNestedPageId(undefined);
        if (isTable && contextDeletedIndx === 0) {
          setCurrentFacetInView("All");
        }
      }
      if (nestedPageItem?.contextId === contextId) {
        setNestedPageItem(null);
      }
    },
    [updateHiddenSearchContextIds, finalActiveSearchContexts, removeActiveSearchContext, isTable, nestedPageItem]
  );

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && e.metaKey) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  // We fetch all the facets and counts for master index on load
  useEffect(() => {
    if (!open || currentFacetInView !== "All" || emptySearchRanForContextIds.current.includes("All")) {
      if (currentFacetInView === "All" && !searchResults?.length && !isEmpty(emptySearchResults)) {
        if (finalActiveSearchContexts?.length) {
          setSearchResults(emptySearchResults?.[finalActiveSearchContexts?.[0]?.id]);
        } else {
          setSearchResults(emptySearchResults?.All);
        }
      }
      return;
    }
    getEmtySearchFacets();
    emptySearchRanForContextIds.current.push("All");
  }, [
    open,
    getEmtySearchFacets,
    currentFacetInView,
    searchResults,
    emptySearchResults,
    isTable,
    finalActiveSearchContexts
  ]);

  useEffect(() => {
    if (
      !finalActiveSearchContexts?.length ||
      emptySearchRanForContextIds.current.includes(finalActiveSearchContexts?.[0]?.id) ||
      currentFacetInView !== "All"
    ) {
      return;
    }
    emptySearchRanForContextIds.current.push(finalActiveSearchContexts?.[0]?.id);
    getEmtySearchFacets();
  }, [finalActiveSearchContexts, getEmtySearchFacets, currentFacetInView]);

  useEffect(() => {
    if (!nestedPageMenuItems?.length || navItemsByPageId?.[finalNavItemKey]?.length || menuItemsLoading || !open) {
      return;
    }
    const menuItems = flatMapArrayWithChildrens(nestedPageMenuItems);
    const matchedContext = finalActiveSearchContexts?.find((cntxt) => cntxt.id === nestedPageItem?.contextId);
    if (matchedContext) {
      const searchTableForContext = searchTableData?.find((st) => st.tableName === matchedContext.tableName);
      const finalItems = [
        ...transformNavigationMenuItemsToAlgoliaResults(
          menuItems as NavigationItem[],
          searchTableForContext?.tableName || ""
        )
      ];
      if (matchedContext?.recordId) {
        finalItems.unshift({
          ...nestedPageItem,
          title: `Go to Details`,
          index: ALGOLIA_MENU_ITEMS_INDEX,
          objectID: `${nestedPageId}}_${nestedPageMenuItems?.length}`,
          item_link: matchedContext?.recordId
            ? `/r${searchTableForContext?.linkedPage?.path}/${matchedContext?.recordId}`
            : `/table${nestedPageItem?.resultPath}/grid`,
          resultIcon: "arrow-right",
          isNestedPageItem: true
        });
      }
      setNavItemsByPageId((prev) => ({ ...prev, [finalNavItemKey]: finalItems }));
      return;
    }
    if (!nestedPageItem?.contextId && isRecord && recordViewParentPage?.id) {
      const matchedContext = finalActiveSearchContexts?.find(
        (cntxt) => cntxt.tableName === recordViewParentPage?.table_name
      );
      const finalItems = [
        ...transformNavigationMenuItemsToAlgoliaResults(
          menuItems as NavigationItem[],
          recordViewParentPage.table_name || ""
        )
      ];
      if (matchedContext?.recordId) {
        finalItems.unshift({
          ...nestedPageItem,
          title: `Go to Details`,
          index: ALGOLIA_MENU_ITEMS_INDEX,
          objectID: `${nestedPageId}}_${nestedPageMenuItems?.length}`,
          item_link: matchedContext?.recordId
            ? `/r${recordViewParentPage?.path}/${matchedContext?.recordId}`
            : `/table${nestedPageItem?.resultPath}/grid`,
          resultIcon: "arrow-right",
          isNestedPageItem: true
        });
      }
      setNavItemsByPageId((prev) => ({ ...prev, [finalNavItemKey]: finalItems }));
      return;
    }
    if (finalNavItemKey === "global") {
      const finalItems = [...transformNavigationMenuItemsToAlgoliaResults(menuItems as NavigationItem[], "global")];
      setNavItemsByPageId((prev) => ({ ...prev, [finalNavItemKey]: finalItems }));
    }
  }, [
    nestedPageMenuItems,
    navItemsByPageId,
    nestedPageId,
    nestedPageItem,
    finalActiveSearchContexts,
    searchTableData,
    menuItemsLoading,
    recordViewParentPage?.id,
    recordViewParentPage?.table_name,
    recordViewParentPage?.path,
    open,
    finalNavItemKey,
    isRecord
  ]);

  useEffect(() => {
    if (
      !nestedPageMenuItems?.length ||
      createNavItemsByPageId?.[finalNavItemKey]?.length ||
      menuItemsLoading ||
      !open
    ) {
      return;
    }
    const menuItems = flatMapArrayWithChildrens(nestedPageMenuItems);
    if (menuItems?.length) {
      const createMenuItems = menuItems
        .filter((menuItem) => menuItem.isFormEnabled)
        .map((menuItem) => ({
          ...menuItem,
          pageId: menuItem.ui_page_id?.id || menuItem.tabForPage?.id,
          title: `Create ${menuItem.pageTitle || menuItem.tabForPage?.title}`,
          objectID: `create_${menuItem.id}`,
          index: ALGOLIA_CREATE_ACTION
        }));
      if (createMenuItems?.length) {
        setCreateNavItemsByPageId((prev) => ({ ...prev, [finalNavItemKey]: createMenuItems }));
      }
    }
  }, [
    nestedPageMenuItems,
    createNavItemsByPageId,
    finalNavItemKey,
    nestedPageItem,
    finalActiveSearchContexts,
    menuItemsLoading,
    open
  ]);

  return (
    <div id="cmdk-container">
      <CommandMenu
        loading={loading}
        value={searchValue}
        onValueChange={handleSearchChange}
        results={searchResultsList}
        open={open}
        onOpenChange={handleOpenChange}
        onResultClick={handleResultClick}
        isHome={isHome}
        activePage={activePage}
        nestedPageResults={
          (isRecord && recordViewParentPage?.id) || !isRecord
            ? navItemsByPageId?.[finalNavItemKey] || undefined
            : undefined
        }
        currentActiveContexts={finalActiveSearchContexts}
        onRemoveActiveSearchContextClick={onRemoveActiveSearchContextClick}
        masterIndexSearchResults={masterIndexSearchResults}
        handleNestedFacetClick={handleNestedFacetClick}
        currentFacetInView={currentFacetInView}
        emptySearchFacets={
          finalActiveSearchContexts?.length
            ? emptySearchFacets?.[finalActiveSearchContexts?.[0]?.id]
            : emptySearchFacets?.All
        }
        updateCurrentFacetInView={updateCurrentFacetInView}
        createActionResults={createNavItemsByPageId?.[finalNavItemKey] || []}
      />
    </div>
  );
};
