"use client";

import clsx from "clsx";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import useUIState from "hooks/useUIState";
import useInLayout from "hooks/useInLayout";
import { MENU_POSITION } from "context/UIContext";

type AllLayoutContainerProps = {
  children: React.ReactNode;
  menuIsTop?: boolean;
  menuPosition?: string;
};
const AllLayoutContainer = ({ children, menuIsTop, menuPosition }: AllLayoutContainerProps) => {
  const { uiState, updateUIState } = useUIState();
  const { isRecord } = useInLayout();
  const pathname = usePathname();

  useEffect(() => {
    updateUIState({ menuPosition: menuPosition as MENU_POSITION });
  }, [menuPosition, updateUIState]);
  const isCompsPage = pathname === "/comps" || pathname?.includes("/comps/");

  return (
    <div
      className={clsx(
        "h-full",
        (menuIsTop || isCompsPage) && "lg:ml-0",
        !isCompsPage && isRecord && !menuIsTop && "lg:ml-16",
        !isCompsPage && !isRecord && !menuIsTop && uiState?.isCollapsed && "lg:ml-16",
        !isCompsPage && !isRecord && !menuIsTop && !uiState?.isCollapsed && "lg:ml-80"
      )}
    >
      {children}
    </div>
  );
};

export default AllLayoutContainer;
