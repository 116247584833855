import clsx from "clsx";
import truncate from "lodash/truncate";
import {
  CheckCircle as CheckIcon,
  X as CloseIcon,
  ChevronDown as DownIcon,
  XCircle as FailIcon,
  Loader as SpinnerIcon,
  Image as ThumbnailIcon,
  ChevronUp as UpIcon
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import Button from "components/Button";
import ConfirmationModal from "components/ConfirmationModal";
import Tooltip from "components/Tooltip";
import { ButtonAppearance } from "utils/constants";

type FileUploadWindowProps = {
  fileUploadStatus: Record<string, string>;
  totalFilesCount: number;
  onDone: () => void;
  onCloseClick: () => void;
  triggeredBy: string;
  onAllUploaded?: (uploaded: boolean) => void;
  onSelectUploadedFiles?: () => void;
};

const FileUploadWindow = ({
  fileUploadStatus,
  totalFilesCount,
  onDone,
  onCloseClick,
  triggeredBy,
  onSelectUploadedFiles
}: FileUploadWindowProps) => {
  const fileNames = Object.keys(fileUploadStatus || {});

  const uploadCount = Object.values(fileUploadStatus || {})?.filter((status) => status !== "Failed")?.length;

  const [fullUploadStatus, setFullUploadStatus] = useState(fileUploadStatus);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isViewUp, setIsViewUp] = useState(true);
  const [uploadState, setUploadState] = useState({
    isUploading: true,
    filesUploading: totalFilesCount
  });

  const showBulkSelectButton = useMemo(() => {
    return !!(!uploadState.isUploading && !uploadState.filesUploading && fileNames?.length);
  }, [uploadState.filesUploading, uploadState.isUploading, fileNames?.length]);

  const getFileStatus = (fileName: string) => {
    if (!fullUploadStatus?.[fileName]) {
      return null;
    }
    const fileStatus = fullUploadStatus[fileName];
    if (fileStatus === "Failed") {
      const extensionPattern = /\.[0-9a-z]+$/i;
      const fileType = fileName.match(extensionPattern)?.[0];

      return (
        <Tooltip title={`File type ${fileType} not accepted`}>
          <FailIcon className="h-6 w-6 text-red-700 dark:text-red-dark-700" aria-hidden="true" />
        </Tooltip>
      );
    }
    if (fileStatus === "Completed") {
      return <CheckIcon className="h-6 w-6 text-green-700 dark:text-green-dark-700" aria-hidden="true" />;
    }
    return (
      <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-base-primary dark:fill-base-dark-primary" />
    );
  };

  useEffect(() => {
    const updatedFilesUploading = [];
    const updatedFileUploadStatus: Record<string, string> = {};
    const fileNamesList = Object.keys(fileUploadStatus || {});
    fileNamesList.forEach((fileName) => {
      if (fileUploadStatus[fileName] === "Started") {
        updatedFilesUploading.push(fileName);
      }
      updatedFileUploadStatus[fileName] = fileUploadStatus[fileName];
    });

    setFullUploadStatus((prev) => ({ ...prev, ...updatedFileUploadStatus }));
    setUploadState({
      isUploading: updatedFilesUploading.length > 0,
      filesUploading: updatedFilesUploading.length
    });
  }, [fileUploadStatus]);

  return (
    <>
      <ConfirmationModal
        isOpen={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
        title="Cancel operation"
        message="Closing this window  will remove all files from the upload queue and you will no longer be able to track progress or add further details. Upload of files will not be cancelled."
        onConfirm={onCloseClick}
        confirmButtonAppearance={ButtonAppearance.RED}
      />

      <div
        className={clsx(
          "text-x flex-flex-col fixed left-2 right-2 z-[49] min-h-[320px]  max-w-[416px] drop-shadow-2xl sm:left-10",
          {
            "bottom-0": isViewUp,
            "bottom-[-264px]": !isViewUp
          }
        )}
      >
        <div className="flex h-[64px] flex-row items-center justify-between rounded-t-lg bg-base-primary  px-5 text-neutral-0">
          <div className="text-xs">
            {!uploadState.isUploading && !uploadState.filesUploading && fileNames?.length ? (
              <span className="flex items-center">
                {" "}
                <CheckIcon className="text-neutral-50 mr-2 h-5 w-5" aria-hidden="true" />
                {`Uploaded ${uploadCount} files.`}
              </span>
            ) : (
              <span className="flex items-center">
                <SpinnerIcon role="status" className="fill-neutral-50 mr-2 h-5 w-5 animate-spin" />{" "}
                {`Uploading ${uploadState?.filesUploading} items.`}
              </span>
            )}
          </div>
          <div className="flex flex-row items-center">
            {showBulkSelectButton && (
              <Button
                className="text-white"
                appearance={ButtonAppearance.TERTIARY}
                label="Select uploaded files"
                onClick={onSelectUploadedFiles}
              />
            )}
            {isViewUp ? (
              <DownIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(false)} />
            ) : (
              <UpIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(true)} />
            )}
            <CloseIcon
              className="h-4 w-4"
              onClick={() => {
                if (!uploadState.isUploading && !uploadState.filesUploading && fileNames?.length) {
                  onCloseClick();
                  return;
                }
                setIsCancelOpen(true);
              }}
            />
          </div>
        </div>
        {isViewUp ? (
          <div className="text-primary divide-y-1 bg-background flex h-[308px] flex-col overflow-y-auto px-5">
            {!uploadState?.isUploading && triggeredBy === "project" && uploadCount > 0 ? (
              <div
                onClick={() => onDone()}
                className="flex min-h-[56px] cursor-pointer flex-row items-center justify-between text-sm"
              >
                Add details to files
              </div>
            ) : null}
            {Object.keys(fullUploadStatus || {}).map((fileName) => {
              return (
                <div key={fileName} className="flex min-h-[56px] flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <span className="mr-3">
                      <ThumbnailIcon width={20} className="text-base-primary dark:text-base-dark-primary" />
                    </span>
                    <span className="text-sm">{truncate(fileName, { length: 34 })}</span>
                  </div>
                  {getFileStatus(fileName)}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default FileUploadWindow;
