import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getTableDataByColumnFilter } from "lib/supabaseApi";
import { TableColumnType } from "types/baTypes";
import { QueryHookOptions } from "types/common";
import useCurrentUser from "./useCurrentUser";

type useTableDataByColumnProps = {
  slug: string;
  value?: string | null;
  tableName: string;
  columnName: string;
  additionalColumns?: TableColumnType[];
  hookOptions?: QueryHookOptions & { onlyFetchAdditionalColumns?: boolean };
};

const useTableDataByColumnFilter = ({
  slug,
  value,
  tableName,
  columnName,
  additionalColumns = [],
  hookOptions
}: useTableDataByColumnProps) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["record", slug, columnName, currentUser?.org_id],
    queryFn: () =>
      getTableDataByColumnFilter({
        tableName,
        columnName,
        value,
        additionalColumns,
        supabaseClient,
        options: {
          onlyFetchAdditionalColumns: hookOptions?.onlyFetchAdditionalColumns
        },
        organizationId: currentUser?.org_id
      }),
    refetchInterval: false,
    enabled: !!tableName && !!columnName && value !== undefined,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...hookOptions
  });

  return {
    data: data?.data,
    isLoading,
    refetch
  };
};

export default useTableDataByColumnFilter;
