import dayjs from "dayjs";
import { BellDotIcon } from "lucide-react";
import ActionsHandler from "components/ActionsHandler";
import Avatar from "components/Avatar";
import IconButton from "components/IconButton";
import { IconButtonColor, IconButtonSize } from "components/IconButton/utils";
import { NotificationRecord } from "components/Notifications/utils";
import Tooltip from "components/Tooltip";
import { ViewAction } from "types/baTypes";
import { AvatarSize, CrudActions, STATIC_SIDEBAR_IDS } from "utils/constants";
import { formatDateTime } from "utils/format";
import generateInitials from "utils/generateInitials";
import Icon from "components/Icon";
import useTableActionsState from "hooks/useTableActionsState";

type FormNotificationItemProps = {
  item: NotificationRecord;
  onMarkRead: (notificationId: string, read?: boolean) => void;
  onClose: () => void;
  actions?: ViewAction[];
};

type FormInfo =
  | {
      from_user: {
        id: string;
        name: string;
        avatar?: string;
      };
      title: string;
      action: CrudActions.CREATE | CrudActions.CREATE_CUSTOM;
      viewId?: string;
      tablePath?: string;
    }
  | null
  | undefined;

const FormNotificationItem = ({ item, onMarkRead, onClose, actions }: FormNotificationItemProps) => {
  const { updateSidebarState } = useTableActionsState();
  const formInfo = item.action_props as FormInfo;

  const handleNotificationClick = () => {
    if (formInfo) {
      updateSidebarState(
        {
          isOpen: true,
          showDetailView: false,
          skiploadSidebaronSubmission: true,
          actionSource: `FormNotificationItem -${item.id}`,
          action: formInfo?.action,
          record: formInfo?.action === CrudActions.CREATE && item.recordId ? { id: item.recordId } : undefined,
          tablePath: formInfo?.tablePath,
          formViewId: formInfo?.action === CrudActions.CREATE_CUSTOM ? formInfo?.viewId : undefined
        },
        STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
      );
    }
    onMarkRead(item.id, true);
    onClose();
  };

  if (!formInfo) return null;
  return (
    <div className="relative flex w-full">
      <div className="flex flex-col items-center gap-3">
        <Avatar
          size={AvatarSize.XS}
          src={formInfo.from_user.avatar}
          initials={generateInitials(formInfo.from_user.name)}
        />
        <div className="h-full w-0 border border-neutral-300 dark:border-neutral-dark-300" />
      </div>
      <div className="flex w-full flex-col gap-2 pb-4">
        <div className="flex flex-1 items-start justify-end">
          <div className="flex items-center gap-2 py-1">
            {!item.read ? (
              <Tooltip title="Mark as read">
                <div
                  className="h-3 w-3 cursor-pointer rounded-full bg-red-700 dark:bg-red-dark-700"
                  onClick={() => {
                    onMarkRead(item.id, true);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Mark as unread">
                <IconButton
                  icon={BellDotIcon}
                  size={IconButtonSize.XS}
                  color={IconButtonColor.TRANSPARENT}
                  onClick={() => onMarkRead(item.id, false)}
                />
              </Tooltip>
            )}
            {!!actions?.length && (
              <ActionsHandler
                tablePath={item.action_props?.tablePath || ""}
                actions={actions}
                record={formInfo}
                tableName={item.tableName as string}
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 pl-3">
          <p className="flex-1 text-sm font-semibold text-base-secondary dark:text-base-dark-secondary">
            {formInfo.title}
          </p>
          <span className="text-xs text-base-disabled dark:text-base-dark-disabled">
            {formatDateTime(dayjs(item.createdAt).toString())}
          </span>
        </div>
        <div
          className="group relative ml-3 flex cursor-pointer gap-3 rounded-lg border border-neutral-300 p-4 text-base-disabled hover:bg-neutral-100 active:bg-neutral-200 dark:border-neutral-dark-300 dark:text-base-dark-disabled dark:hover:bg-neutral-dark-100 dark:active:bg-neutral-dark-200"
          onClick={handleNotificationClick}
        >
          <Icon name="clipboard-type" className="m-1 h-4 w-4" />
          <div className="flex w-full flex-col gap-2 overflow-hidden">
            {item?.message && <p className="text-sm text-base-primary dark:text-base-dark-primary">{item.message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormNotificationItem;
