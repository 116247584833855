import clsx from "clsx";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

type DragAndDropProps = {
  onSuccess?: (acceptedFiles: File[]) => void;
  classNameContainerDragging?: string;
  classNameDragging?: string;
  multiple?: boolean;
};

const DragAndDrop = ({
  onSuccess,
  classNameContainerDragging,
  classNameDragging,
  multiple = true,
}: DragAndDropProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onSuccess?.(acceptedFiles);
    },
    [onSuccess]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: false,
    multiple,
  });

  return (
    <div
      {...getRootProps()}
      className={clsx(
        isDragActive && classNameContainerDragging,
        isDragActive
          ? "absolute inset-0 z-50 h-full w-full rounded-lg bg-neutral-0/95 dark:bg-neutral-dark-0/95"
          : "absolute inset-0 h-full w-full"
      )}
    >
      <input {...getInputProps()} />
      <div
        className={clsx(
          isDragActive
            ? "flex h-full w-full items-center justify-center rounded-lg border border-dashed border-primary-700 bg-neutral-0/95 dark:bg-neutral-dark-0/95"
            : "h-full w-full",
          isDragActive && classNameDragging
        )}
      >
        {isDragActive ? <div>Drop file{multiple ? "s" : ""} here to upload</div> : <div />}
      </div>
    </div>
  );
};

export default DragAndDrop;
