import clsx from "clsx";
import { Link2Icon, LucideIcon, PlusIcon, UploadIcon } from "lucide-react";
import React, { useState } from "react";
import Dropzone from "react-dropzone";

import type { Accept, DropzoneState, FileRejection } from "react-dropzone";
import Button from "components/Button";
import IconButton from "components/IconButton";
import { IconButtonSize } from "components/IconButton/utils";
import Tooltip from "components/Tooltip";
import { FileUploadResults } from "types/common";
import { ButtonAppearance, ButtonSize } from "utils/constants";
import "./FileUpload.css";
import FileUrlImportModal from "./FileUrlImportModal";

type FileUploadProps = {
  handleAcceptedFiles: (files: File[]) => void;
  handleRejectedFiles: (files: File[]) => void;
  handleSelectedFiles?: () => void;
  acceptedFileTypes?: Accept;
  multiple?: boolean;
  isDisabled?: boolean;
  maxFiles?: number;
  uploadResults?: FileUploadResults;
  className?: string;
  maxFileSize?: number;
  dropClassName?: string;
  buttonClassName?: string;
  buttonIcon?: LucideIcon;
  buttonLabel?: string;
  buttonSize?: ButtonSize;
  isResponsive?: boolean;
  renderPreviews?: () => void;
  renderUpload?: () => void;
  renderCustomComponent?: (input: DropzoneState["getInputProps"]) => void;
  mRef?: React.RefObject<HTMLDivElement> | null;
  onClickPreview?: () => void;
};

const FileUpload = ({
  maxFiles = 0,
  maxFileSize,
  multiple = true,
  acceptedFileTypes = {
    "image/*": [".png", ".gif", ".jpeg", ".jpg", ".heic"]
  },
  handleAcceptedFiles,
  handleRejectedFiles,
  isDisabled = false,
  className = "",
  dropClassName = "",
  buttonClassName = "",
  buttonIcon = PlusIcon,
  buttonLabel,
  buttonSize = ButtonSize.SM,
  isResponsive = false,
  renderCustomComponent,
  mRef = null
}: FileUploadProps) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [showImportUrl, setShowImportUrl] = useState(false);

  const handleRejected = async (rejectedFiles: FileRejection[] = []) => {
    setIsDraggingOver(false);
    handleRejectedFiles?.(rejectedFiles.map((fileInfo) => fileInfo.file));
  };

  const handleAccepted = async (acceptedFiles: File[] = []) => {
    setIsDraggingOver(false);
    handleAcceptedFiles?.(acceptedFiles);
  };

  const renderUpload = (getInputProps: DropzoneState["getInputProps"]) => {
    if (renderCustomComponent) {
      return (
        <>
          <input {...getInputProps()} type="file" className={clsx("hidden", className)} disabled={isDisabled} />
          {renderCustomComponent(getInputProps)}
        </>
      );
    }

    return (
      <>
        <div className={clsx(isResponsive ? "flex justify-center lg:hidden" : "hidden", "dark:bg-dropdown")}>
          <input {...getInputProps()} type="file" className={clsx("hidden", className)} disabled={isDisabled} />
          <Button
            appearance={ButtonAppearance.SECONDARY}
            size={buttonSize}
            className={`${buttonClassName} border-separator text-primary dark:bg-transparent`}
            label={buttonLabel || `Add ${multiple ? "files" : "file"}`}
            icon={buttonIcon}
          />
        </div>

        <div
          className={clsx(
            "bg-dropdown",
            isResponsive ? "hidden lg:flex" : "flex",
            "flex flex-col items-center justify-center rounded-xl py-7",
            isDraggingOver
              ? "accent-dashed-border-desktop accent-dashed-border-mobile"
              : "neutral-dashed-border-desktop neutral-dashed-border-mobile"
          )}
        >
          {/* <UploadCloudIcon className="text-primary mb-[1.125rem] hidden h-12 w-12 shrink-0 sm:block" />
          <CameraIcon className="mb-3 block h-6 w-6 shrink-0 text-base-primary sm:hidden" /> */}
          <input {...getInputProps()} type="file" className={clsx("hidden", className)} disabled={isDisabled} />
          <div className="mb-3 flex gap-3">
            <Tooltip title={`Select File${multiple ? "s" : ""}`}>
              <IconButton size={IconButtonSize.MD} icon={UploadIcon} className={`!rounded-full`} />
            </Tooltip>
            <Tooltip title="Import from URL">
              <IconButton
                size={IconButtonSize.MD}
                icon={Link2Icon}
                className={` !rounded-full`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowImportUrl(true);
                }}
              />
            </Tooltip>
          </div>
          <span className="text-primary hidden text-md sm:block">{`Drop ${
            multiple ? "files" : "file"
          } here to upload`}</span>
          <span className="text-primary block max-w-[57%] text-center text-sm sm:hidden">
            Take photo or upload from your phone library
          </span>
        </div>
      </>
    );
  };

  const renderDropzone = () => (
    <Dropzone
      accept={acceptedFileTypes}
      maxSize={maxFileSize}
      maxFiles={maxFiles}
      multiple={multiple}
      onDropAccepted={handleAccepted}
      onDropRejected={handleRejected}
      onDragEnter={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={dropClassName} {...getRootProps()}>
          {renderUpload(getInputProps)}
        </div>
      )}
    </Dropzone>
  );

  return (
    <div data-testid="FileUpload" className={clsx("h-full w-full", className)} ref={mRef}>
      {renderDropzone()}
      <FileUrlImportModal open={showImportUrl} onOpenChange={setShowImportUrl} onFileImport={handleAccepted} />
    </div>
  );
};

export default FileUpload;
