import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";

const AddContainer = ({ children }: { children: React.ReactNode }) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return <DndContext sensors={sensors}>{children}</DndContext>;
};

export default AddContainer;
