"use client";

import clsx from "clsx";
import { useTheme } from "next-themes";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";

const ToggleTheme = ({ className }: { className?: string }) => {
  const { setTheme, theme } = useTheme();

  return (
    <Select value={theme} onValueChange={(value) => setTheme(value)}>
      <SelectTrigger className={clsx(className, "w-[110px] gap-1 px-2")}>
        <div className="flex flex-1 items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            className="h-4 w-4"
          >
            {theme === "light" ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            ) : theme === "dark" ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            ) : (
              <>
                <rect width="20" height="14" x="2" y="3" rx="2" strokeWidth={1.5} />
                <line x1="8" x2="16" y1="21" y2="21" strokeWidth={1.5} />
                <line x1="12" x2="12" y1="17" y2="21" strokeWidth={1.5} />
              </>
            )}
          </svg>
          <SelectValue placeholder="Theme" />
        </div>
      </SelectTrigger>
      <SelectContent align="end">
        <SelectItem value="light">Light</SelectItem>
        <SelectItem value="dark">Dark</SelectItem>
        <SelectItem value="system">System</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default ToggleTheme;
