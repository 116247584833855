"use client";
import { XIcon } from "lucide-react";

import clsx from "clsx";
import { useEffect, useState } from "react";

import { ReactCompareSlider } from "react-compare-slider";
import Image from "components/Image";
import Modal from "components/Modal";
import { getBlurUrl } from "lib/utils";

type BeforeAfterFileModalProps = {
  beforeImage: string;
  afterImage: string;
  isOpen: boolean;
  onClose: () => void;
};

const BeforeAfterFileModal = ({ isOpen, onClose, beforeImage, afterImage }: BeforeAfterFileModalProps) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    // Detect if the device is a touch device
    setIsTouchDevice(window.matchMedia("(pointer: coarse)").matches);
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      showClose={false}
      classNameBg={clsx("!border-none !bg-transparent")}
      fullScreen
      hideOverlay
    >
      <div
        className={clsx(
          "h-full overflow-hidden bg-base-secondary/90 transition-all dark:bg-black/75 lg:fixed lg:inset-0"
        )}
      >
        <div className={clsx("h-full w-full block")}>
          <div className={clsx("fixed top-5 z-10 w-full items-center justify-between px-5 flex")}>
            <XIcon className="text-icons h-5 w-5 cursor-pointer" onClick={handleClose} />
          </div>
          <div className={clsx("block h-full")}>
            <div
              className={clsx(
                "relative flex h-[calc(100%-64px)] items-center justify-center overflow-hidden transition-all duration-300 lg:h-full"
              )}
            >
              <div
                className={clsx(
                  "group/image relative flex h-full w-full z-[1] lg:max-w-[80%] mx-auto items-center lg:max-h-[80%] lg:min-h-[20%]",
                  "cursor-pointer"
                )}
              >
                <ReactCompareSlider
                  className="w-full h-full"
                  boundsPadding={80}
                  itemOne={
                    <div className="relative h-full w-full">
                      <Image
                        src={beforeImage}
                        alt="Before Image"
                        width={1200}
                        placeholder="blur"
                        blurDataURL={getBlurUrl()}
                        quality={75}
                        height={751}
                        sizes="100vw"
                        style={{
                          width: "100%",
                          height: "auto"
                        }}
                      />
                    </div>
                  } // Use next/image's Image component
                  itemTwo={
                    <div className="relative h-full w-full">
                      <Image
                        src={afterImage}
                        alt="After Image"
                        width={1200}
                        height={751}
                        quality={75}
                        placeholder="blur"
                        blurDataURL={getBlurUrl()}
                        sizes="100vw"
                        style={{
                          width: "100%",
                          height: "auto"
                        }}
                      />
                    </div>
                  }
                  position={50}
                  onlyHandleDraggable={isTouchDevice}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BeforeAfterFileModal;
