import React from "react";
import clsx from "clsx";
import { LucideIcon } from "lucide-react";
import slice from "lodash/slice";
import Link from "next/link";

import Skeleton from "components/Skeleton";
import Avatar from "components/Avatar";
import { AvatarSize, PeopleType } from "utils/constants";

export type AvatarMultipleProps = {
  size?: AvatarSize;
  images: SingleAvatarImage[];
  className?: string;
  maxImages?: number;
  hasTooltip?: boolean;
  isLoading?: boolean;
};

export type SingleAvatarImage = {
  iconClassName?: string;
  iconWrapperClassName?: string;
  src?: string;
  current_page?: string;
  initials: string;
  title?: string;
  role?: "staff" | "client";
  alt?: string;
  icon?: LucideIcon;
  id: string;
  type?: PeopleType;
  disabled?: boolean;
};

const AvatarMultiple = ({
  size = AvatarSize.MD,
  images,
  className,
  maxImages = 4,
  hasTooltip = true,
  isLoading = false
}: AvatarMultipleProps) => {
  if (isLoading) {
    return <Skeleton circle={true} className="h-7 w-7" />;
  }

  return (
    <div data-testid="AvatarMultiple" className="flex">
      <div className={clsx("flex -space-x-2", className)}>
        {images?.length > 0 &&
          slice(images, 0, maxImages < 1 ? 1 : maxImages)?.map((image, index) => {
            const avatar = (
              <Avatar
                hasTooltip={hasTooltip}
                src={image.src}
                size={size}
                initials={image?.initials}
                title={`${image?.title || ""} ${image?.disabled ? "(inactive)" : ""}`}
                role={image?.role}
                alt={image?.alt}
                type={image?.type}
                icon={image?.icon}
                classNameIcon={image?.iconClassName}
                classNameIconWrapper={image?.iconWrapperClassName}
                className="ring-1 ring-neutral-300 dark:ring-neutral-dark-300"
                disabled={image.disabled}
              />
            );
            return (
              <React.Fragment key={index}>
                {!!image?.current_page ? (
                  <Link href={image.current_page as string} target="_blank">
                    {avatar}
                  </Link>
                ) : (
                  <div>{avatar}</div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default AvatarMultiple;
