import React, { useMemo, useState } from "react";
import { Command } from "cmdk";
import { type Dictionary } from "lodash";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { Search } from "lucide-react";

import { AlgoliaSearchResult } from "components/Search/utils";
import Icon from "components/Icon";
import CellGeneric from "components/CellGroup/Cells/CellGeneric";
import CellBadge from "components/CellGroup/Cells/CellBadge";
import "./styles.css";
import { ActiveSearchContextState, RecordItem } from "types/common";
import { ALGOLIA_MASTER_INDEX } from "utils/constants";
type CommandMenuProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  value: string;
  onValueChange: (val: string) => void;
  results: Dictionary<AlgoliaSearchResult[]>;
  masterIndexSearchResults?: { [key: string]: { hits: AlgoliaSearchResult[]; total?: number } };
  loading: boolean;
  onResultClick: (resultItem: AlgoliaSearchResult, addToSearch?: boolean) => void;
  isHome: boolean;
  activePage: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  nestedPageResults?: RecordItem[];
  currentActiveContexts?: ActiveSearchContextState[];
  onRemoveActiveSearchContextClick?: (cntxtId: string) => void;
  handleNestedFacetClick?: (facet: string) => void;
  handleRedirectFromFacet?: (facet: string) => void;
  currentFacetInView?: string;
  updateCurrentFacetInView?: (facet: string, value?: string) => void;
  emptySearchFacets?: RecordItem;
  createActionResults?: RecordItem[];
};

const CommandMenu = ({
  value,
  open,
  onOpenChange,
  onValueChange,
  results,
  loading,
  onResultClick,
  isHome = true,
  nestedPageResults,
  activePage = "home",
  currentActiveContexts,
  onRemoveActiveSearchContextClick,
  masterIndexSearchResults,
  handleNestedFacetClick,
  currentFacetInView,
  updateCurrentFacetInView,
  emptySearchFacets,
  createActionResults
}: CommandMenuProps) => {
  const [nestedPageSearch, setNestedPageSearch] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const finalFacetCount =
    !isEmpty(emptySearchFacets) && (value?.length || 0) <= 2 ? emptySearchFacets : masterIndexSearchResults;

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      const currentSelectedDocument = document.querySelector("[data-selected=true]");
      // read attribute data-id from the element
      const itemId = currentSelectedDocument?.getAttribute("data-id");
      const idParts = itemId?.split(":");
      if (idParts?.length) {
        if (idParts?.[0] === "redirect") {
          const pageItem = nestedPageResults?.find((item) => item.objectID === idParts[1]);
          if (pageItem) {
            onResultClick(pageItem as AlgoliaSearchResult);
          }
        } else {
          if (idParts[0] === ALGOLIA_MASTER_INDEX) {
            const selectedHit = masterIndexSearchResults?.[idParts[1]]?.hits?.find(
              (hit) => hit.objectID === idParts[2]
            );
            if (selectedHit) {
              onResultClick(selectedHit);
            }
          } else {
            const selectedHit = results?.[idParts[1]]?.find((hit) => hit.objectID === idParts[2]);
            if (selectedHit) {
              onResultClick(selectedHit);
            }
          }
        }
      }
    }

    if (e.key === "Tab") {
      // pick the element with data-selected=true attribute
      const currentSelectedDocument = document.querySelector("[data-selected=true]");
      // read attribute data-id from the element
      const itemId = currentSelectedDocument?.getAttribute("data-id");
      const idParts = itemId?.split(":");
      if (idParts?.length) {
        if (idParts[0] === "facet") {
          handleNestedFacetClick?.(idParts[1]);
        } else {
          if (idParts[0] === ALGOLIA_MASTER_INDEX) {
            const selectedHit = masterIndexSearchResults?.[idParts[1]]?.hits?.find(
              (hit) => hit.objectID === idParts[2]
            );
            if (selectedHit) {
              onResultClick(selectedHit, true);
            }
          } else {
            const selectedHit = results?.[idParts[1]]?.find((hit) => hit.objectID === idParts[2]);
            if (selectedHit) {
              onResultClick(selectedHit, true);
            }
          }
        }
      }
    }

    if (e.key === "Backspace" && !finalValue?.length) {
      if (currentActiveContexts?.[currentActiveContexts?.length - 1]?.id) {
        onRemoveActiveSearchContextClick?.(currentActiveContexts?.[currentActiveContexts?.length - 1]?.id);
      }
    }
  };

  const handleValueChange = (val: string) => {
    setSelectedValue("");
    if (isHome) {
      onValueChange(val);
    } else {
      setNestedPageSearch(val);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setNestedPageSearch("");
    onOpenChange(open);
  };

  const finalValue = isHome ? value : nestedPageSearch;

  const sortedResultKeys = useMemo(() => {
    if (isEmpty(results)) return [];

    return sortBy(Object.keys(results), (key) => results[key]?.[0].searchTableSortOrder);
  }, [results]);

  const finalMenuItems = useMemo(() => {
    return nestedPageResults?.filter(
      (item) => !value || currentFacetInView === "Menus" || item.title.toLowerCase().includes(value.toLowerCase())
    );
  }, [nestedPageResults, value, currentFacetInView]);

  return (
    <>
      <Command.Dialog
        open={open}
        onOpenChange={handleOpenChange}
        label="Global_Command_Menu"
        shouldFilter={!isHome}
        onKeyDown={handleKeyDown}
        value={selectedValue}
        onValueChange={(v) => {
          setSelectedValue(v);
        }}
      >
        {!!currentActiveContexts?.length && (
          <div className="cmdk-badge flex h-fit items-center gap-1 !bg-white text-sm font-medium">
            {currentActiveContexts.map((cntxt, index) => {
              return (
                <CellBadge
                  className="mr-2 text-[13px]"
                  key={cntxt.title}
                  value={cntxt.title}
                  onRemoveClick={() => onRemoveActiveSearchContextClick?.(cntxt.id)}
                  color={index === currentActiveContexts?.length - 1 ? "#33ae91bd" : ""}
                  isEditable
                />
              );
            })}
          </div>
        )}
        <div className="flex items-center border-b px-3">
          <Search size={24} strokeWidth={1.5} className="mr-2 h-5 w-5 shrink-0 opacity-50" />
          <Command.Input
            placeholder="Type a command or search..."
            value={finalValue}
            onValueChange={handleValueChange}
          />
        </div>
        <div className="flex flex-row flex-wrap items-center gap-2 px-2 py-4">
          {Object.keys(emptySearchFacets || {})
            ?.sort()
            ?.map((facet: string) => {
              return (
                <CellBadge
                  className="cursor-pointer text-[13px]"
                  color={facet === currentFacetInView ? "#33ae91bd" : ""}
                  key={facet}
                  onClick={() => {
                    if (value?.length) {
                      updateCurrentFacetInView?.(facet, value);
                    } else {
                      updateCurrentFacetInView?.(facet);
                    }
                  }}
                  value={
                    facet !== "All" && facet !== "Menus"
                      ? `${facet} ${value?.length ? (masterIndexSearchResults?.[facet]?.total ? "(" + masterIndexSearchResults?.[facet]?.total + ")" : "") : "(" + finalFacetCount?.[facet]?.total + ")"}`
                      : facet
                  }
                />
              );
            })}
          {nestedPageResults?.length ? (
            <CellBadge
              className="cursor-pointer"
              color={"Menus" === currentFacetInView ? "#33ae91bd" : ""}
              key="Menus"
              onClick={() => {
                if (value?.length) {
                  updateCurrentFacetInView?.("Menus", value);
                } else {
                  updateCurrentFacetInView?.("Menus");
                }
              }}
              value={`Menus (${nestedPageResults?.length})`}
            />
          ) : null}
        </div>
        <Command.List>
          <>
            {loading && isEmpty(results) ? (
              <Command.Loading>Loading...</Command.Loading>
            ) : isEmpty(results) && isEmpty(masterIndexSearchResults) && value.length > 1 ? (
              <div className="cmdk-empty" role="presentation">
                No results found.
              </div>
            ) : !isEmpty(results) ||
              !currentFacetInView ||
              !isEmpty(masterIndexSearchResults?.[currentFacetInView]) ||
              (currentFacetInView === "Menus" && nestedPageResults?.length) ? (
              <>
                <Command.Group key={currentFacetInView}>
                  {currentFacetInView !== "Menus"
                    ? masterIndexSearchResults?.[currentFacetInView || ""]?.hits?.slice(0, 7).map((resultItem) => (
                        <Command.Item
                          key={`${resultItem.index}_${resultItem.objectID}`}
                          onSelect={() => onResultClick(resultItem)}
                          className="group flex flex-row justify-between gap-2"
                          data-id={`${resultItem.index}:${currentFacetInView}:${resultItem.objectID}`}
                          value={`${resultItem.index}_${resultItem.objectID}`}
                        >
                          {resultItem.resultColumn &&
                          (resultItem.resultColumnValue?.title || resultItem.resultColumnValue?.subTitle) ? (
                            <CellGeneric
                              value={resultItem.resultColumnValue}
                              column={resultItem.resultColumn}
                              className="!dark:text-gray-500 !text-gray-700"
                            />
                          ) : (
                            <div>
                              <div className="h-5 w-5">
                                {resultItem.resultIcon && <Icon name={resultItem.resultIcon} className="shrink-0" />}
                              </div>
                              <div className="flex flex-col items-center">
                                <span className="inline-block">{resultItem.name || resultItem.title}</span>
                              </div>
                            </div>
                          )}
                          <div className={`hidden text-[14px] text-gray-600 group-hover:block`}>
                            <kbd
                              className="mr-[2px] rounded-lg border border-[#ddd] bg-white px-1 py-1 text-[11px] tracking-tighter"
                              key={currentFacetInView + "enter"}
                            >
                              Enter
                            </kbd>
                            to jump
                            <kbd
                              className="mx-[2px] rounded-lg border border-[#ddd] bg-white px-1 py-1 text-[11px] tracking-tighter text-[]"
                              key={currentFacetInView + "tab"}
                            >
                              Tab
                            </kbd>
                            to search
                          </div>
                        </Command.Item>
                      ))
                    : null}
                </Command.Group>
                {createActionResults?.length && !finalValue?.length && currentFacetInView !== "Menus" ? (
                  <Command.Group heading="Create">
                    {createActionResults.map((menuItem) => {
                      return (
                        <Command.Item
                          key={`action_${menuItem.objectID}`}
                          onSelect={() => onResultClick(menuItem as AlgoliaSearchResult)}
                          className="group flex flex-row justify-between gap-2"
                        >
                          <div className="flex flex-row items-center">
                            <div className="h-5 w-5">
                              <Icon name="plus" className="shrink-0" />
                            </div>
                            <div className="ml-3 flex flex-col">
                              <span className="inline-block">{menuItem.title}</span>
                            </div>
                          </div>
                          <div className={`hidden group-hover:block`}>
                            <kbd key={menuItem?.objectID + "enter"}>Enter</kbd> to open
                          </div>
                        </Command.Item>
                      );
                    })}
                  </Command.Group>
                ) : null}
                {!!finalMenuItems?.length ? (
                  <Command.Group heading={"Menu Items > " + activePage}>
                    {finalMenuItems.map((pageResult) => (
                      <Command.Item
                        key={`page_result_${pageResult.objectID}`}
                        onSelect={() => onResultClick(pageResult as AlgoliaSearchResult)}
                        className="group flex flex-row justify-between gap-2"
                        data-id={`redirect:${pageResult.objectID}`}
                      >
                        <div className="flex flex-row items-center">
                          <div className="h-5 w-5">
                            {pageResult.resultIcon && <Icon name={pageResult.resultIcon} className="shrink-0" />}
                          </div>
                          <div className="ml-3 flex flex-col">
                            <span className="inline-block">{pageResult.title}</span>
                          </div>
                        </div>
                        <div className={`hidden text-[14px] text-gray-600 group-hover:block`}>
                          <kbd
                            className="mr-[2px] rounded-lg border border-[#ddd] bg-white px-1 py-1 text-[11px] tracking-tighter"
                            key={pageResult?.objectID + "enter"}
                          >
                            Enter
                          </kbd>
                          to jump
                        </div>
                      </Command.Item>
                    ))}
                  </Command.Group>
                ) : null}
                {sortedResultKeys.map((key) => (
                  <Command.Group key={key} heading={key}>
                    {results[key].map((resultItem) => (
                      <Command.Item
                        key={`${resultItem.index}_${resultItem.objectID}`}
                        onSelect={() => onResultClick(resultItem)}
                        className="flex items-center gap-2"
                        data-id={`${resultItem.index}:${key}:${resultItem.objectID}`}
                        value={`${resultItem.index}_${resultItem.objectID}`}
                      >
                        {resultItem.resultColumn ? (
                          <CellGeneric
                            value={resultItem.resultColumnValue}
                            column={resultItem.resultColumn}
                            className="!dark:text-gray-500 !text-gray-700"
                          />
                        ) : (
                          <>
                            <div className="h-5 w-5">
                              {resultItem.resultIcon && <Icon name={resultItem.resultIcon} className="shrink-0" />}
                            </div>
                            <div className="nk flex flex-col">
                              <span className="inline-block text-sm">{resultItem.title}</span>
                            </div>
                          </>
                        )}
                      </Command.Item>
                    ))}
                  </Command.Group>
                ))}
              </>
            ) : (
              <div className="cmdk-empty" role="presentation">
                Find something...
              </div>
            )}
          </>
        </Command.List>
      </Command.Dialog>
    </>
  );
};

export default CommandMenu;
