"use client";
import noop from "lodash/noop";
import { useEffect, useMemo, useState } from "react";

import DataGridView from "components/DataGridView";
import TableHeader from "components/TableHeader/TableHeader";
import useTableActionsState from "hooks/useTableActionsState";
import { TableFilterType, TableViewType } from "types/baTypes";

import Loader from "components/Loader";
import { QueryHookOptions } from "types/common";
import { ALTERNATE_SCHEMAS, INITIAL_PAGINATION_STATE, ViewOption } from "utils/constants";
import { auditColumns } from "./utils";

type AuditSidebarProps = {
  tableName: string;
  recordId?: string;
};

const AUDIT_TABLE_SLUG = "audit_sidebar";

const dataHookOptions: QueryHookOptions = {
  refetchInterval: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  staleTime: Infinity
};

const AuditSidebar = ({ tableName, recordId }: AuditSidebarProps) => {
  const [auditFilters, setAuditFilters] = useState<TableViewType>();
  const { updatePaginationByTableSlug, tableRowsStateBySlug } = useTableActionsState();

  useEffect(() => {
    updatePaginationByTableSlug(INITIAL_PAGINATION_STATE, AUDIT_TABLE_SLUG);
  }, [updatePaginationByTableSlug]);

  useEffect(() => {
    if (!tableName) return;
    const updatedFilter: TableFilterType[] = [
      {
        id: "table_name",
        filterField: "table_name",
        filterValue: tableName,
        filterOperator: "equals",
        isFilterTextSearch: false
      }
    ];
    // TODO: Resolve once the proper index has been set up
    // if (recordId) {
    //   updatedFilter.push({
    //     id: "record_filter",
    //     filterField: "record",
    //     filterValue: { id: recordId },
    //     filterOperator: "equals",
    //     isFilterTextSearch: false,
    //     isFilterJSONColumn: true,
    //   });
    // }
    setAuditFilters({ filters: updatedFilter });
  }, [tableName]);

  const auditSort = useMemo(
    () => [
      {
        id: "audit_sort",
        col: auditColumns.find((col) => col.header === "Timestamp"),
        desc: true,
        name: "ts"
      }
    ],
    []
  );

  if (!auditFilters?.filters?.length) {
    return <Loader />;
  }

  return (
    <div className="w-full flex-1 overflow-y-auto p-4">
      <TableHeader
        title={"Audit logs"}
        totalCount={tableRowsStateBySlug?.[AUDIT_TABLE_SLUG]?.totalCount}
        tableName={"record_version"}
        columns={auditColumns}
        tableFiltersOption={auditFilters}
        // @ts-ignore
        views={[]}
        params={{ slug: "" }}
        tableSlug={""}
        inSidebar
        hideBulkActions
        showAddLabel={false}
        isFileTable={false}
        barControlsEnabled
      />
      <DataGridView
        tableTitle={tableName + " Audit logs"}
        onRowClick={noop}
        columnOptions={auditColumns}
        tableName="record_version"
        currentSlug={AUDIT_TABLE_SLUG}
        multiSelectEnabled={false}
        tableFiltersOption={auditFilters}
        globalSort={auditSort}
        dataHookOptions={dataHookOptions}
        altSchema={ALTERNATE_SCHEMAS.AUDIT}
      />
    </div>
  );
};

export default AuditSidebar;
