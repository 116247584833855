import * as React from "react";

function ShoppingBag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.565 4.771H15.91a5.494 5.494 0 00-1.813-3.38A5.581 5.581 0 0010.5 0a5.581 5.581 0 00-3.596 1.392 5.495 5.495 0 00-1.813 3.38H3.436c-.911 0-1.784.359-2.429.997A3.395 3.395 0 000 8.177v12.417c0 .903.363 1.768 1.007 2.407A3.453 3.453 0 003.434 24h14.13c.912 0 1.785-.36 2.429-.999A3.395 3.395 0 0021 20.594V8.177c0-.903-.363-1.769-1.007-2.407a3.453 3.453 0 00-2.428-.999zM10.5 1.77c.856.018 1.68.326 2.336.871a3.723 3.723 0 011.27 2.13H6.894a3.723 3.723 0 011.27-2.13A3.779 3.779 0 0110.5 1.77zm8.715 18.824c0 .433-.175.85-.484 1.156a1.66 1.66 0 01-1.166.48H3.435a1.66 1.66 0 01-1.166-.48 1.632 1.632 0 01-.484-1.156V8.177c0-.434.175-.85.484-1.156.31-.307.729-.48 1.166-.48h14.13c.438 0 .857.173 1.166.48.31.306.483.722.484 1.156v12.417z"
        fill="#000"
      />
    </svg>
  );
}

export default ShoppingBag;
