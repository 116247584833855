"use client";

import useUserIpCheck from "hooks/useUserIpCheck";

const UserApiCheck = () => {
  useUserIpCheck();

  return null;
};

export default UserApiCheck;
