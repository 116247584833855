"use client";

import React, { useCallback } from "react";

import { GeneralAction, useGeneralActionsState, useGeneralActionsUpdater } from "context/GeneralActionsContext";
import GenActionUploadFiles from "./GenActionUploadFiles";

// NOTE: This will be refactored to more generic implementation once we start to handle diffrent kind of requests
const GeneralActions = () => {
  const actionsState = useGeneralActionsState();
  const { setActionsState } = useGeneralActionsUpdater();

  const handleClose = useCallback(
    (updatedState: GeneralAction) => {
      setActionsState({
        ...actionsState,
        ...updatedState
      });
    },
    [actionsState, setActionsState]
  );

  if (!actionsState) {
    return null;
  }

  return (
    <div>
      {actionsState.currentAction === "uploadFiles" && (
        <GenActionUploadFiles
          isOpen={actionsState.isOpen}
          onClose={handleClose}
          returnFiles={actionsState.returnFiles}
          newFiles={actionsState.newFiles}
          uploadStatusFromDragAndDrop={actionsState.uploadStatus}
          uploadedFilesFromDragAndDrop={actionsState.uploadedFiles}
          {...(actionsState.actionProps || {})}
        />
      )}
    </div>
  );
};

export default GeneralActions;
