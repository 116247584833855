import { RefreshCcw, RefreshCwOff, AlertTriangle, Vibrate, Sun, Repeat } from "lucide-react";
import { Page } from "types/baTypes";
import { NavigationItem, PAGE_TYPES } from "types/common";
import { ActionType, ViewOption } from "utils/constants";

//  Contains items used in the Admin sidebar menu, needs to be updated when adding/removing items from the Admin Menu
export const ADMIN_SIDEBAR_MENU_ITEMS = [
  {
    isDivider: true
  },
  {
    name: "Basics",
    icon: "braces"
  },
  {
    name: "Actions",
    icon: "cpu"
  },
  {
    isDivider: true
  },
  {
    name: "Grid View",
    icon: "list"
  },
  {
    name: "Card View",
    icon: "app-window"
  },
  {
    name: "Form View",
    icon: "pencil"
  },
  {
    name: "Detail View",
    icon: "badge-info"
  },
  {
    name: "MultiPages View",
    icon: "layers-3"
  },
  {
    isDivider: true
  },
  {
    name: "Board View",
    icon: "columns-2"
  },
  {
    name: "Map View",
    icon: "map-pin"
  },
  {
    name: "Calendar View",
    icon: "calendar-days"
  },
  {
    name: "Matrix View",
    icon: "table"
  },
  {
    name: "List View",
    icon: "list"
  },
  {
    name: "Activity View",
    icon: "scroll-text"
  }
];

// @FAKEDATA
export const getAdminTablePageNavigation = (pageProps: {
  adminId?: string;
  adminSection?: string;
  pageType: string;
  isAdminPage: boolean;
  isActivityTable: boolean;
  additionalActionsClickHandlers?: {
    [key: string]: { [action: string]: (viewId?: string, isDefaultForm?: boolean) => void };
  };
  pageViews?: Page;
}) => {
  const { adminId, adminSection, pageType, isAdminPage, additionalActionsClickHandlers, pageViews, isActivityTable } =
    pageProps;
  if (adminSection === "tables" && adminId) {
    const allFormViews = pageViews?.views?.filter((view) => view.viewType === ViewOption.FORM && view.isActive);
    const items = [
      ...(pageType === PAGE_TYPES.DATABASE
        ? isAdminPage
          ? ADMIN_SIDEBAR_MENU_ITEMS.slice(0, 5)
          : isActivityTable
            ? ADMIN_SIDEBAR_MENU_ITEMS
            : ADMIN_SIDEBAR_MENU_ITEMS.filter((item) => item.name !== "Activity View")
        : [ADMIN_SIDEBAR_MENU_ITEMS[0]]
      ).map((control) => {
        const isFormControl = control.name === "Form View";
        return {
          id: isFormControl ? "formView" : undefined,
          name: control.name || "",
          href:
            control.name && control.name !== "Detail View" && control.name !== "MultiPages View"
              ? `/admin/tables/${adminId}/${control.name.replace(" View", "").replaceAll(" ", "").toLowerCase()}`
              : "",
          is_folder:
            (control.name && ["Detail View", "MultiPages View"].includes(control.name)) ||
            (isFormControl && !!allFormViews?.length),
          icon: control.icon,
          is_divider: control.isDivider,
          isViewOnlyMenu: isFormControl,
          showExposedActions: isFormControl ? true : false,
          actions: isFormControl
            ? [
                {
                  id: "add",
                  name: "Add Form",
                  type: ActionType.CUSTOM,
                  icon: "plus",
                  properties: {},
                  onClick: additionalActionsClickHandlers?.[ViewOption.FORM]?.["add"]
                }
              ]
            : undefined,
          children:
            control.name === "Detail View"
              ? [
                  {
                    name: "Settings",
                    href: `/admin/tables/${adminId}/detail/settings`,
                    icon: "settings"
                  },
                  {
                    name: "Tabs",
                    href: `/admin/tables/${adminId}/detail/tabs`,
                    icon: "compass"
                  }
                ]
              : control.name === "MultiPages View"
                ? [
                    {
                      name: "Settings",
                      href: `/admin/tables/${adminId}/multipages/settings`,
                      icon: "cog"
                    },
                    {
                      name: "TableLite View",
                      href: `/admin/tables/${adminId}/multipages/tablelite`,
                      icon: "table-2"
                    }
                  ]
                : isFormControl
                  ? allFormViews?.map((formView) => ({
                      id: formView.id,
                      isViewOnlyMenu: true,
                      name: formView.title ? formView.title : formView.isDefault ? "Default Form" : formView.subType,
                      href: `/admin/tables/${adminId}/form/${formView.id}`,
                      icon: "file-cog",
                      actions: formView.isPageViewCustom
                        ? [
                            {
                              id: "delete",
                              name: "Delete",
                              icon: "trash",
                              type: ActionType.CUSTOM,
                              properties: {},
                              onClick: () =>
                                additionalActionsClickHandlers?.[ViewOption.FORM]?.["delete"]?.(formView.viewId)
                            },
                            {
                              id: "rename",
                              name: "Rename",
                              icon: "folder-pen",
                              type: ActionType.CUSTOM,
                              properties: {},
                              onClick: () =>
                                additionalActionsClickHandlers?.[ViewOption.FORM]?.["rename"]?.(formView.id)
                            },
                            {
                              id: "duplicate",
                              name: "Duplicate",
                              icon: "copy",
                              type: ActionType.CUSTOM,
                              properties: {},
                              onClick: () =>
                                additionalActionsClickHandlers?.[ViewOption.FORM]?.["duplicate"]?.(formView.id)
                            }
                          ]
                        : [
                            {
                              id: "rename",
                              name: "Rename",
                              icon: "folder-pen",
                              type: ActionType.CUSTOM,
                              properties: {},
                              onClick: () =>
                                additionalActionsClickHandlers?.[ViewOption.FORM]?.["rename"]?.(formView.id, true)
                            },
                            {
                              id: "duplicate",
                              name: "Duplicate",
                              icon: "copy",
                              type: ActionType.CUSTOM,
                              properties: {},
                              onClick: () =>
                                additionalActionsClickHandlers?.[ViewOption.FORM]?.["duplicate"]?.(formView.id)
                            }
                          ]
                    }))
                  : []
        };
      })
    ];
    return items as NavigationItem[];
  }
  return [];
};

export const dashboardFeatures = [
  {
    name: "No Page Refresh",
    description:
      "A user should never have to refresh a page to see fresh data. Live updates should stream in at all times.",
    icon: RefreshCwOff
  },
  {
    name: "Mobile Friendly",
    description:
      "When testing your work, always check mobile as well to ensure your updates work well across viewports.",
    icon: Vibrate
  },
  {
    name: "Light + Dark Mode",
    description: "Always ensure any new component work includes the required dark mode tailwind classes.",
    icon: Sun
  },
  {
    name: "Generic, Always",
    description:
      "Build features in generic, re-usable way so this app could be used for other businesses. Think Airtable.",
    icon: Repeat
  },
  {
    name: "Defensive Components",
    description:
      "Think of what can go wrong - add checks for null or unefined values, include error handling, consider empty states, test various use cases.",
    icon: AlertTriangle
  },
  {
    name: "Performance is everything",
    description:
      "Minimizing every millisecond a user has to wait for feedback, see updates, or wait for a page to load has immense cost. Always consider performance tradeoffs.",
    icon: RefreshCcw
  }
];
