import { useEffect, useMemo } from "react";

import useAllPagesLite from "hooks/useAllPagesLite";
import useMultiTableData, { MultiPageColumns } from "hooks/useMultiTableData";
import useRelationPages from "hooks/useRelationPages";
import useTableActionsState from "hooks/useTableActionsState";

import { Page, TablePageViewTab } from "types/baTypes";
import { DETAIL_TAB_VISIBILITY, NavigationItem, RecordItem } from "types/common";
import { getPageFiltersFromTab } from "utils/pageUtils";
import { ViewOption } from "utils/constants";

const useNavItemsRecordCounts = ({
  items,
  recordId,
  recordPage,
  source
}: {
  items: NavigationItem[];
  recordPageId?: string;
  recordId?: string;
  recordPage?: Page;
  source?: string;
}) => {
  const { data: pages } = useAllPagesLite();
  const { getRelationPages, data: relationPagesData } = useRelationPages();
  const { setMultiTablesData, results } = useMultiTableData(source);
  const { currentProjectId, currentRecordId } = useTableActionsState();

  const navItemPageSlugs = useMemo(() => {
    if (!items || !pages?.length) return undefined;
    const finalNavItemSlugs: RecordItem = {};
    items?.forEach((item) => {
      const slug = item.ui_page_id?.id
        ? pages?.find((page) => `${page.id}` === `${item.ui_page_id?.id}`)?.path.replace("/", "")
        : undefined;
      if (item.id) {
        finalNavItemSlugs[item.id] = slug;
      }
    });
    return finalNavItemSlugs;
  }, [pages, items]);

  // This sets all page Ids we need to fetch columns for
  useEffect(() => {
    if (!items?.length || !pages?.length || !navItemPageSlugs) return;
    const finalPageIdsToFetch: string[] = [];
    items.forEach((item) => {
      if (!!(item.show_record_count && item.ui_page_id?.id)) {
        finalPageIdsToFetch.push(item.ui_page_id?.id);
      }
    });
    if (finalPageIdsToFetch?.length) {
      getRelationPages(finalPageIdsToFetch);
    }
  }, [navItemPageSlugs, pages, items, getRelationPages, source]);

  useEffect(() => {
    if (!relationPagesData || !items?.length) return;
    const finalQueries: MultiPageColumns[] = [];
    items.forEach((item) => {
      if (!!(item.show_record_count && item.ui_page_id?.id)) {
        const pageData = relationPagesData?.find((page) => page.id === item.ui_page_id?.id);
        const pageForFilters = pages?.find((page) => `${page.id}` === `${item.ui_page_id?.id}`);
        let finalFilters = [...(pageForFilters?.pageFilters?.filters || [])];
        if (recordId && recordPage?.id) {
          const detailTabs = recordPage?.views
            ?.find((view) => view.viewType === ViewOption.DETAIL_MAIN)
            ?.tabs?.filter(
              (tab) =>
                (tab.visibility === DETAIL_TAB_VISIBILITY.DETAIL_TAB || tab.visibility === DETAIL_TAB_VISIBILITY.ALL) &&
                tab.page?.id
            );
          const currentTabVal = detailTabs?.find(
            (tab: TablePageViewTab) => tab.page?.path?.replace("/", "") === navItemPageSlugs?.[item.id || ""]
          );
          if (currentTabVal) {
            const { finalFiltersList } = getPageFiltersFromTab({
              recordPage,
              currentTabVal,
              byPassFileListIdCreation: true,
              recordId,
              currentProjectId,
              currentRecordId,
              tabPageData: pageForFilters
            });
            if (finalFiltersList?.length) {
              finalFilters = finalFilters.concat(finalFiltersList);
            }
          }
        }
        if (pageData) {
          finalQueries.push({
            tableName: pageData.table_name,
            columns: pageData?.columns || [],
            filters: finalFilters,
            resultId: item.id || "",
            returnCountOnly: true
          });
        }
      }
    });
    if (finalQueries.length) {
      setMultiTablesData(finalQueries);
    }
  }, [
    relationPagesData,
    items,
    setMultiTablesData,
    pages,
    navItemPageSlugs,
    recordId,
    recordPage,
    currentProjectId,
    currentRecordId,
    source
  ]);

  return {
    countByNavItem: results
  };
};

export default useNavItemsRecordCounts;
