"use client";
import { RudderAnalytics } from "@rudderstack/analytics-js";
import React, { createContext, useContext, useEffect, useState } from "react";

type RudderStackAnalyticsProps = {
  analytics?: RudderAnalytics;
};
const RudderStackContext = createContext<RudderStackAnalyticsProps>({
  analytics: undefined
});

const { Provider } = RudderStackContext;

export const RudderAnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const [analytics, setAnalytics] = useState<RudderAnalytics>();
  const isClient = typeof window !== "undefined" && window.location.host.startsWith("client.");

  useEffect(() => {
    if (!analytics && isClient) {
      const initialize = async () => {
        const { RudderAnalytics } = await import("@rudderstack/analytics-js");
        const analyticsInstance = new RudderAnalytics();

        // Pulling from environment variables
        const writeKey = process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY;
        const dataPlaneUrl = process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL;

        if (!writeKey || !dataPlaneUrl) {
          console.error("RudderStack write key or data plane URL is missing");
          return;
        }

        analyticsInstance.load(writeKey, dataPlaneUrl);

        analyticsInstance.ready(() => {
          console.log("RudderStack is all set!!!");
        });

        setAnalytics(analyticsInstance);
      };

      initialize().catch((e) => console.log(e));
    }
  }, [analytics, isClient]);

  return (
    <Provider
      value={{
        analytics
      }}
    >
      {children}
    </Provider>
  );
};

// Analytics hook to be used with other components.
export const useRudderStack = () => {
  const { analytics } = useContext(RudderStackContext);

  return { analytics };
};
