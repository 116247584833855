import { useContext } from "react";
import { CollapsibleViewerContext, CollapsibleViewerContextState } from "context/CollapsibleViewerContext";

const useCollapsibleViewerState = () => {
  const collapsibleViewerState = useContext<CollapsibleViewerContextState | null>(CollapsibleViewerContext);

  if (typeof collapsibleViewerState === "undefined" || typeof collapsibleViewerState !== "object") {
    return {
      expandedItem: undefined,
      collapsedViewerItems: [],
      viewerItems: [],
      addViewerItem: () => {},
      removeViewerItem: () => {},
      toggleExpandViewerItem: () => {},
      updateViewerItem: () => {},
    };
  }

  return collapsibleViewerState as CollapsibleViewerContextState;
};

export type CollapsibleViewerContextType = ReturnType<typeof useCollapsibleViewerState>;

export default useCollapsibleViewerState;
