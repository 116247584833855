import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import uniqBy from "lodash/uniqBy";
import { usePathname } from "next/navigation";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { USER_TYPE } from "utils/constants";
import useCurrentUser from "./useCurrentUser";
import { usePageVisibility } from "./usePageVisibility";

const isDevEnv = !process.env.NEXT_PUBLIC_VERCEL_URL;

const ROUTE_CHANGE_EVENT = "route_change";
const LAST_ACTIVE_EVENT = "last_active";

const getCurrentUrl = () => (typeof window !== "undefined" ? window.location.origin + window.location.pathname : "/");

const useOnlineUsers = () => {
  const supabaseClient = useSupabaseBrowser();
  const user = useCurrentUser();
  const pathname = usePathname();
  const isPageVisible = usePageVisibility();

  const [onlineUsers, setOnlineUsers] = useState<any[]>([]);
  const [tick, setTick] = useState(true);

  useEffect(() => {
    if (isDevEnv) return;
    // update ticker every 20 second to force rerender
    const intervalId = setInterval(() => {
      setTick((prev) => !prev);
    }, 20000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const channel = useMemo(() => {
    if (isDevEnv || !user) return null;
    // Only users in the same organization will be in the same channel
    return supabaseClient.channel(`online-users-${user.org_id}`, {
      config: {
        presence: {
          key: user?.id
        }
      }
    });
  }, [user, supabaseClient]);

  useEffect(() => {
    if (isDevEnv) {
      console.log("disabling online users for dev env");
      return;
    }

    if (!channel || !user || channel.joinedOnce) return;

    if (user.type === USER_TYPE.STAFF) {
      channel.on("presence", { event: "sync" }, () => {
        const presenceState = Object.values(channel.presenceState())
          .map((presence) => presence[0])
          .filter((presenceUser) => user.id !== presenceUser.presence_ref);
        setOnlineUsers(uniqBy(presenceState, "id"));
      });
    }

    channel.on("broadcast", { event: ROUTE_CHANGE_EVENT }, ({ payload }) => {
      const { user, url, online_at } = payload || {};
      if (user && url) {
        setOnlineUsers((prev) => {
          const onlineUser = prev.find((u) => u.id === user.id);
          if (onlineUser && onlineUser.current_page !== url) {
            onlineUser.current_page = url;
            onlineUser.online_at = online_at; // to track user last activity
            return [onlineUser, ...prev.filter((u) => u.id !== user.id)];
          }
          return prev;
        });
      }
    });

    channel.on("broadcast", { event: LAST_ACTIVE_EVENT }, ({ payload }) => {
      const { user, online_at } = payload || {};
      if (user && online_at) {
        setOnlineUsers((prev) => {
          const onlineUser = prev.find((u) => u.id === user.id);
          if (onlineUser) {
            onlineUser.online_at = online_at;

            return [onlineUser, ...prev.filter((u) => u.id !== user.id)];
          }
          return prev;
        });
      }
    });

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        const status = await channel.track({
          online_at: new Date().toISOString(),
          current_page: getCurrentUrl(),
          ...user
        });
        if (status === "ok") {
          console.log("user joined!");
        }
      }
    });
  }, [user, channel, pathname]);

  const sendLastActive = useCallback(() => {
    if (channel) {
      channel.send({
        type: "broadcast",
        event: LAST_ACTIVE_EVENT,
        payload: {
          online_at: new Date().toISOString(),
          user
        }
      });
    }
  }, [user, channel]);

  useEffect(() => {
    if (isDevEnv) return;
    sendRouteChange(getCurrentUrl());
  }, [pathname]);

  useEffect(() => {
    if (isDevEnv) return;

    let intervalId: NodeJS.Timer;
    if (isPageVisible) {
      sendLastActive();
      intervalId = setInterval(() => {
        sendLastActive();
      }, 20000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sendLastActive, isPageVisible]);

  const sendRouteChange = (url: string | null) => {
    if (channel && url) {
      channel
        .send({
          type: "broadcast",
          event: ROUTE_CHANGE_EVENT,
          payload: { user, url, online_at: new Date().toISOString() }
        })
        .catch(console.error);
    }
  };

  const finalOnlineUsersWithStatus = useMemo(
    () =>
      onlineUsers.map((user) => ({
        ...user,
        disabled: dayjs(user.online_at).isBefore(dayjs().subtract(10, "minutes"))
      })),
    [onlineUsers, tick]
  );

  return finalOnlineUsersWithStatus;
};

export default useOnlineUsers;
