"use client";

import { LogOutIcon } from "lucide-react";
import { useRouter } from "next/navigation";

import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import ToggleTheme from "components/ToggleTheme";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from "components/ui/dropdownMenu";
import { RecordItem } from "types/common";
import generateInitials from "utils/generateInitials";
import { buildSupabasePublicUrl } from "components/Image/utils";
import toast from "utils/toast";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import Toggle from "components/Toggle";
import { ToggleSize } from "components/Toggle/utils";
import useUIState from "hooks/useUIState";

type NavigationUserMenuProps = {
  currentUser?: RecordItem;
  onMenuOpen?: (open: boolean) => void;
};

const NavigationUserMenu = ({ currentUser, onMenuOpen }: NavigationUserMenuProps) => {
  const supabaseClient = useSupabaseBrowser();
  const router = useRouter();
  const { uiState, setVercelToolbarEnabled } = useUIState();

  const handleLogoutUser = async () => {
    const { error } = await supabaseClient.auth.signOut();

    if (error) {
      toast.error("Unable to logout, try again!");
    }
    router.refresh();
  };

  return (
    <DropdownMenu onOpenChange={onMenuOpen}>
      <DropdownMenuTrigger asChild>
        <Button className="h-8 w-8 p-0">
          <Avatar className="border-separator border">
            <AvatarImage
              src={currentUser?.file?.path ? buildSupabasePublicUrl(currentUser?.file?.path) : currentUser?.file?.path}
              className="object-cover"
            />
            <AvatarFallback className="p-[3px] font-matter">{generateInitials(currentUser?.full_name)}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="border-separator w-72 rounded-xl border py-2 " align="end">
        <DropdownMenuLabel className="truncate font-matter font-medium">{currentUser?.email}</DropdownMenuLabel>

        <DropdownMenuItem className="justify-between font-matter focus:bg-transparent dark:focus:bg-transparent">
          <span className="font-matter">Theme</span>
          <ToggleTheme />
        </DropdownMenuItem>
        {!!currentUser?.is_admin && (
          <DropdownMenuItem className="justify-between font-matter focus:bg-transparent dark:focus:bg-transparent">
            <span className="font-matter">Show Vercel Toolbar</span>
            <Toggle size={ToggleSize.SM} checked={uiState?.vercelToolbarEnabled} onChange={setVercelToolbarEnabled} />
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem className="cursor-pointer" onClick={handleLogoutUser}>
          <LogOutIcon className="mr-2 h-4 w-4" />
          <span className="font-matter">Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NavigationUserMenu;
