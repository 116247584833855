// @ts-ignore
import ReactStreetview from "react-streetview";

type MapStreetView = {
  latitude: number;
  longitude: number;
};

const GOOGLE_STREET_VIEW_KEY = process.env.NEXT_PUBLIC_GOOGLE_STREET_VIEW_KEY;

const MapStreetView = ({ latitude, longitude }: MapStreetView) => {
  return (
    <div className="h-full w-full">
      <ReactStreetview
        apiKey={GOOGLE_STREET_VIEW_KEY}
        streetViewPanoramaOptions={{
          position: { lat: latitude, lng: longitude },
          pov: { heading: 90, pitch: 0 },
          zoom: 1,
        }}
      />
    </div>
  );
};

export default MapStreetView;
