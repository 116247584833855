import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getCustomViewById } from "lib/adminApi";
import useAllPagesLite from "./useAllPagesLite";
import useCurrentUser from "./useCurrentUser";

const useCustomViewById = (viewId?: string) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data: allPages } = useAllPagesLite();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["customView", viewId, currentUser?.org_id],
    queryFn: () => getCustomViewById(supabaseClient, viewId, currentUser?.org_id),
    enabled: !!viewId,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity
  });

  const hasDifferentTables = useMemo(() => {
    if (!data?.data?.viewColumns?.length) return false;
    const colPageIds = data?.data.viewColumns.map((col) => col.pageId);
    return new Set(colPageIds).size > 1;
  }, [data]);

  const isStaticCustomForm = !!data?.data?.additionalConfig?.isStaticCustomForm;

  const pagesInColumns = useMemo(() => {
    if (!data?.data?.viewColumns?.length || !allPages?.length) return undefined;
    const colPagesIds = data?.data.viewColumns.map((col) => col.pageId);
    const uniqPageIds = Array.from(new Set(colPagesIds));
    return uniqPageIds
      .map((pageId) => {
        return allPages?.find((page) => page.id === pageId)?.path;
      })
      .filter(Boolean);
  }, [allPages, data?.data?.viewColumns]);

  return { data: data?.data, isLoading, error, hasDifferentTables, refetch, pagesInColumns, isStaticCustomForm };
};

export default useCustomViewById;
