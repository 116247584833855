import { CellType } from "utils/constants";

export const auditColumns = [
  {
    id: "79cd9f75-21c1-4945-a2d5-e0bf70c6a1fb",
    name: "table_name",
    hasFilter: false,
    hasBulkEdit: false,
    header: "Table Name",
    type: CellType.BADGE,
    isActive: true,
    sortOrder: {
      grid: "0|aa",
      map: "0|aa",
      card: "0|aa",
      board: "0|aa",
      detail_main: "0|aa",
      form: "0|aa",
      note: "0|aa",
      list: "0|aa",
      calendar: "0|aa",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, type: "string", format: "name" },
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "3096c75b-4291-4392-9a66-0b523d843c29",
    name: "op",
    hasFilter: false,
    hasBulkEdit: false,
    header: "Operation",
    type: CellType.BADGE,
    isActive: true,

    sortOrder: {
      grid: "0|ab",
      map: "0|ab",
      card: "0|ab",
      board: "0|ab",
      detail_main: "0|ab",
      form: "0|ab",
      note: "0|ab",
      list: "0|ab",
      calendar: "0|ab",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, type: "string", format: "audit.operation" },
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "f2ee38f6-37fa-4d1f-84b8-5db62d9d4a03",
    name: "record_id",
    hasFilter: true,
    hasBulkEdit: false,
    header: "Record ID",
    type: CellType.TEXT,
    isActive: true,

    sortOrder: {
      grid: "0|ac",
      map: "0|ac",
      card: "0|ac",
      board: "0|ac",
      detail_main: "0|ac",
      form: "0|ac",
      note: "0|ac",
      list: "0|ac",
      calendar: "0|ac",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, type: "string", format: "uuid" },
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "9ed05f3e-abc0-4cdc-8175-652d2201fe82",
    name: "old_record",
    hasFilter: false,
    hasBulkEdit: false,
    header: "Old Record",
    type: CellType.JSON,
    isActive: true,

    sortOrder: {
      grid: "0|ad",
      map: "0|ad",
      card: "0|ad",
      board: "0|ad",
      detail_main: "0|ad",
      form: "0|ad",
      note: "0|ad",
      list: "0|ad",
      calendar: "0|ad",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, format: "jsonb", type: "json" },
    isAdminColumn: true,
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "f8b8a0e0-5ef9-487a-a287-d36f9424dd38",
    name: "record",
    hasFilter: false,
    hasBulkEdit: false,
    header: "New Record",
    type: CellType.JSON,
    isActive: true,

    sortOrder: {
      grid: "0|ac1",
      map: "0|ae",
      card: "0|ae",
      board: "0|ae",
      detail_main: "0|ae",
      form: "0|ae",
      note: "0|ae",
      list: "0|ae",
      calendar: "0|ae",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, format: "jsonb", type: "json" },
    isAdminColumn: true,
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "dfb0935a-4eaf-4e67-b396-7fac576fe01f",
    name: "auth_role",
    hasFilter: false,
    hasBulkEdit: false,
    header: "Auth Role",
    type: CellType.TEXT,
    isActive: true,

    sortOrder: {
      grid: "0|ac02",
      map: "0|af",
      card: "0|af",
      board: "0|af",
      detail_main: "0|af",
      form: "0|af",
      note: "0|af",
      list: "0|af",
      calendar: "0|af",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, type: "string", format: "text" },
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "cb393be8-51a9-4da9-9038-488427943181",
    name: "changelog",
    hasFilter: false,
    hasBulkEdit: false,
    header: "Changelog",
    type: CellType.JSON,
    isActive: true,

    sortOrder: {
      grid: "0|ae",
      map: "0|ag",
      card: "0|ag",
      board: "0|ag",
      detail_main: "0|ag",
      form: "0|ag",
      note: "0|ag",
      list: "0|ag",
      calendar: "0|ag",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, format: "jsonb", type: "json" },
    isAdminColumn: true,
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "45928e76-9823-4170-8d92-15e61bcf5e29",
    name: "table_oid",
    hasFilter: false,
    hasBulkEdit: false,
    header: "Table OID",
    type: CellType.TEXT,
    isActive: true,

    sortOrder: {
      grid: "0|aa1",
      map: "0|ah",
      card: "0|ah",
      board: "0|ah",
      detail_main: "0|ah",
      form: "0|ah",
      note: "0|ah",
      list: "0|ah",
      calendar: "0|ah",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, type: "string", format: "oid" },
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "b42d0689-198c-4748-a8d4-1dd396c89a01",
    name: "ts",
    hasFilter: false,
    hasBulkEdit: false,
    header: "Timestamp",
    type: CellType.DATETIME,
    isActive: true,

    sortOrder: {
      grid: "0|a9",
      map: "0|ai",
      card: "0|ai",
      board: "0|ai",
      detail_main: "0|ai",
      form: "0|ai",
      note: "0|ai",
      list: "0|ai",
      calendar: "0|ai",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, type: "string", format: "datetime" },
    showRelativeTime: true,
    views: {
      grid: {
        id: "grid",
      },
    },
  },
  {
    id: "8651ec88-4672-4fee-a4f7-e501dfcc1aea",
    name: "auth_uid",
    hasFilter: false,
    hasBulkEdit: false,
    header: "User ID",
    type: CellType.TEXT,
    isActive: true,
    sortOrder: {
      grid: "0|ac01",
      map: "0|aj",
      card: "0|aj",
      board: "0|aj",
      detail_main: "0|aj",
      form: "0|aj",
      note: "0|aj",
      list: "0|aj",
      calendar: "0|aj",
      matrix: "0|aa",
    },
    isLookup: false,
    isFormula: false,
    lookupPath: {},
    formula: "",
    hasConfigError: false,
    configErrors: [],
    dbType: { isFKey: false, type: "string", format: "uuid" },
    views: {
      grid: {
        id: "grid",
      },
    },
  },
];
