import { useCallback, useEffect, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import { useQueryClient } from "@tanstack/react-query";

import orderBy from "lodash/orderBy";
import toast from "utils/toast";
import useAllPagesLite from "hooks/useAllPagesLite";
import useSchemaState from "hooks/useSchemaState";
import useTableActionsState from "hooks/useTableActionsState";
import useUserType from "hooks/useUserType";
import { FormJoinTableRelationInfo, TableColumnType, TableFilterType } from "types/baTypes";
import useTableData from "hooks/useTableData";
import useFormFieldsSubmission from "hooks/useFormFieldsSubmission";
import useRemoveRecord from "hooks/useRemoveRecord";
import usePageDataById from "hooks/usePageDataById";
import useRelationPages from "hooks/useRelationPages";
import useErrorLogger from "hooks/useErrorLogger";
import { transformRecordItemToSelectOptions } from "components/CellGroup/utils";
import { generateFinalDataColumns } from "utils/dataUtils";
import {
  AdditionalFormActions,
  CellType,
  CrudActions,
  ERROR_TYPES,
  FILTER_OPERATOR,
  SPECIAL_DEFAULTS,
  STATIC_SIDEBAR_IDS,
  ViewOption
} from "utils/constants";
import { DETAIL_TAB_VISIBILITY, RecordItem, SelectOption } from "types/common";
import useRecordTypes from "hooks/useRecordTypes";
import FormUI from "./FormUI";
import { getPageTitle, getRelationFormColumnPagesMap } from "./utils";

type BulkActionEditMatrixProps = {
  parentRecordSlug?: string;
  parentRecordId?: string;
  tabSlug?: string;
};

const BulkActionEditMatrix = ({ parentRecordSlug, parentRecordId, tabSlug }: BulkActionEditMatrixProps) => {
  const { logError } = useErrorLogger();
  const queryClient = useQueryClient();
  const { schemaInstance } = useSchemaState();
  const { userType } = useUserType();
  const {
    currentProjectId,
    currentRecordId,
    matrixXAxisColumnIdByTableSlug,
    bulkActionsStateByTableSlug,
    updateBulkActionsStateByTableSlug,
    updateSidebarState,
    currentTablePage,
    superAdminOrgIdFilterDisabledByTableSlug
  } = useTableActionsState();
  const { data: allPages } = useAllPagesLite({
    refetchOnWindowFocus: false
  });
  const { data: recordTypesData } = useRecordTypes();
  const { data: relationPages, getRelationPages } = useRelationPages();
  const currentTableSlug = currentTablePage?.path?.replace("/", "") as string;
  const bulkActionsState = bulkActionsStateByTableSlug?.[currentTableSlug];
  const recordsData = bulkActionsState?.selectedRows?.map((row) => row.originalRowByColId);

  const pageDataId = useMemo(() => {
    if (!allPages?.length || !bulkActionsState?.tablePageSlug) return;

    return allPages.find((page) => page.path === `/${bulkActionsState.tablePageSlug}` && page.user_type === userType)
      ?.id;
  }, [allPages, bulkActionsState?.tablePageSlug, userType]);

  const { data: pageData } = usePageDataById(pageDataId);

  const finalBaseSchema = pageData?.page_config?.alternateSchema
    ? schemaInstance?.alternateSchema?.[pageData?.page_config?.alternateSchema]
    : schemaInstance?.extendedSchema;

  const columnsForRecordFetch = useMemo(() => {
    return generateFinalDataColumns({
      columns: pageData?.columns,
      view: ViewOption.GRID,
      additionalColMatch: (col) => {
        return !!col.hasBulkEdit;
      },
      recordTypesData,
      tableName: pageData?.table_name || "",
      extendedSchema: finalBaseSchema
    });
  }, [pageData, recordTypesData, finalBaseSchema]);

  const formRelationPageIds = useMemo(
    () =>
      pageData?.columns
        ?.map((column) =>
          column.views?.[ViewOption.FORM]?.id && !!column.views?.[ViewOption.FORM]?.formRelationPageId
            ? column.views?.[ViewOption.FORM]?.formRelationPageId
            : null
        )
        .filter(Boolean),
    [pageData?.columns]
  );

  useEffect(() => {
    if (!formRelationPageIds?.length) return;
    getRelationPages(formRelationPageIds as string[]);
  }, [formRelationPageIds, getRelationPages]);

  const relationFormColumnPagesMap = useMemo(() => {
    if (!relationPages?.length || !pageData?.columns?.length) return {};

    return getRelationFormColumnPagesMap(pageData.columns, relationPages);
  }, [pageData?.columns, relationPages]);

  const joinTableRelationInfo: FormJoinTableRelationInfo[] = useMemo(
    () =>
      Object.keys(relationFormColumnPagesMap).map((relationField) => {
        const relationTablepage = relationFormColumnPagesMap[relationField];
        return {
          tableName: relationTablepage.table_name,
          columns: relationTablepage.columns?.filter((col) => col.views?.[ViewOption.FORM]?.id),
          relationField
        };
      }),
    [relationFormColumnPagesMap]
  );

  const viewConfig = useMemo(
    () => pageData?.views?.find((view) => view.viewType === ViewOption.MATRIX),
    [pageData?.views]
  );

  const finalXAxisCols = useMemo(
    () => sortBy(viewConfig?.additionalConfig?.matrixXAxisCols, "sort_order"),
    [viewConfig?.additionalConfig?.matrixXAxisCols]
  );

  const xAxisColumn = useMemo(() => {
    if (!finalXAxisCols.length || !pageData?.columns?.length) {
      return null;
    }

    const matrixXAxisColId = matrixXAxisColumnIdByTableSlug?.[currentTableSlug || ""] || finalXAxisCols?.[0]?.id;

    const xAxisCol = pageData?.columns.find((col) => col.id === matrixXAxisColId);
    return xAxisCol;
  }, [finalXAxisCols, pageData?.columns, matrixXAxisColumnIdByTableSlug, currentTableSlug]);

  const yAxisColumn = useMemo(() => {
    if (!viewConfig?.additionalConfig?.matrixYAxisColId || !pageData?.columns?.length) {
      return null;
    }
    const yAxisColumn = pageData?.columns.find((col) => col.id === viewConfig.additionalConfig?.matrixYAxisColId);
    return yAxisColumn;
  }, [viewConfig, pageData?.columns]);

  const [xAxisTableName, setXAxisTableName] = useState<string>("");
  const [xAxisColumns, setXAxisColumns] = useState<TableColumnType[]>([]);
  const [xAxisFilters, setXAxisFilters] = useState<TableFilterType[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumnType[]>([]);

  const [formFieldsArray, setFormFieldsArray] = useState<RecordItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const finalTitle = pageData?.title ? getPageTitle("", pageData?.title) : "";

  const { removeRecordAsync } = useRemoveRecord();
  const { onFormSubmitWithOptions } = useFormFieldsSubmission({
    tableName: pageData?.table_name || "",
    formColumns: columnsForRecordFetch || [],
    record: recordsData || undefined,
    slug: bulkActionsState?.tablePageSlug || "",
    joinTableRelationInfo,
    alternateSchema: pageData?.page_config?.alternateSchema,
    source: "BulkActionEditMatrix"
  });

  // Fetch xAxis column options
  const { data: xAxisData } = useTableData({
    tableName: xAxisTableName,
    columns: xAxisColumns,
    tableFiltersOption: xAxisFilters?.length ? { filters: xAxisFilters } : undefined,
    hookOptions: {
      refetchOnWindowFocus: false,
      enabled: !!xAxisTableName && !!xAxisColumns?.length
    },
    disableOrgIdFilter: !!superAdminOrgIdFilterDisabledByTableSlug?.[currentTableSlug]
  });

  const tableName = pageData?.table_name;

  const isJoinTable = useMemo(
    () => !!(tableName && finalBaseSchema?.[tableName]?.compositePk?.length),
    [tableName, finalBaseSchema]
  );

  const parentRecordPageId = useMemo(() => {
    return allPages?.find((page) => page.path === `/${parentRecordSlug}` && page.user_type === userType)?.id;
  }, [parentRecordSlug, allPages, userType]);

  const { data: parentRecordPage } = usePageDataById(parentRecordPageId);

  const parentRecordForForeignKey = useMemo(() => {
    if (isJoinTable || !allPages?.length || !tableName) {
      return null;
    }

    const parentPageDetailTabs = parentRecordPage?.views?.find(
      (view) => view.viewType === ViewOption.DETAIL_MAIN
    )?.tabs;
    if (!parentRecordPage || !parentRecordId || !parentPageDetailTabs?.length) return null;

    const tabDetails = parentPageDetailTabs
      ?.filter(
        (tab) =>
          (tab.visibility === DETAIL_TAB_VISIBILITY.DETAIL_TAB || tab.visibility === DETAIL_TAB_VISIBILITY.ALL) &&
          tab.page?.id
      )
      .find((tab) => (tab.page?.path || "").replace("/", "") === tabSlug);

    if (!tabDetails) return null;
    const { filterRelations } = tabDetails;

    if (filterRelations?.["0"]?.lookupTableName) {
      return null;
    }
    const parentRecordForeignKey = filterRelations?.["0"]?.lookupColumn;

    if (!parentRecordForeignKey) return null;

    return {
      name: parentRecordForeignKey,
      record: {
        id: parentRecordId
      }
    };
  }, [isJoinTable, tableName, allPages, tabSlug, parentRecordId, parentRecordPage]);

  const getInputForRequest = useCallback(
    ({ value, row, option }: { value: boolean; row: RecordItem; option: SelectOption }) => {
      const finalData: RecordItem = {};
      const finalColumns: TableColumnType[] = [];
      // New record has to be created
      if (value) {
        let finalRecord: RecordItem | undefined = undefined;
        if (
          !viewConfig?.additionalConfig?.matrixToggleAction ||
          viewConfig.additionalConfig?.matrixToggleAction === CrudActions.CREATE
        ) {
          tableColumns.forEach((col) => {
            if (col.id === xAxisColumn?.id) {
              finalData[col.id] = option;
              finalColumns.push(col);
              return;
            }
            if (col.id === yAxisColumn?.id) {
              if (row?.yColData) {
                if (Array.isArray(row.yColData) && row.yColData.length) {
                  finalData[col.id] = row?.yRowData[0];
                } else {
                  finalData[col.id] = row?.yColData;
                }
                finalColumns.push(col);
                return;
              }
            }
            if (parentRecordForForeignKey?.name && col.name === parentRecordForForeignKey?.name) {
              finalData[col.id] = parentRecordForForeignKey?.record?.id;
              finalColumns.push(col);
              return;
            }
            if (col.defaultValues?.value) {
              if (col.defaultValues?.value === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID && currentProjectId) {
                finalData[col.id] = currentProjectId;
                finalColumns.push(col);
                return;
              } else if (col.defaultValues?.value === SPECIAL_DEFAULTS.CURRENT_RECORD_ID && currentRecordId) {
                finalData[col.id] = currentRecordId;
                finalColumns.push(col);
                return;
              }
              finalData[col.id] = col.defaultValues?.value;
              finalColumns.push(col);
              return;
            }
          });
        } else if (viewConfig.additionalConfig?.matrixToggleAction === CrudActions.UPDATE && xAxisColumn) {
          finalRecord = row?.originalRow;
          finalData[xAxisColumn.id] = option;
          finalColumns.push(xAxisColumn);
        }

        const additionalDataToSave =
          viewConfig?.additionalConfig?.matrixToggleAction !== CrudActions.UPDATE
            ? {
                createdIn: "Matrix",
                createdInPath: window.location.pathname
              }
            : {};

        return {
          finalData,
          options: {
            record: finalRecord,
            finalFormColumns: finalColumns,
            isFromBulkEdit: true,
            ...additionalDataToSave
          }
        };
      } else {
        if (
          (!viewConfig?.additionalConfig?.matrixViewYColumnIsOptions && !row?.originalRow?.id) ||
          (viewConfig?.additionalConfig?.matrixViewYColumnIsOptions && !row?.originalRowByColId?.[option.value])
        ) {
          return;
        }
        const finalId = viewConfig?.additionalConfig?.matrixViewYColumnIsOptions
          ? row?.originalRowByColId?.[option.value]?.id
          : row?.originalRow?.id;

        return {
          id: finalId,
          tableName: tableName || ""
        };
      }
    },
    [
      currentProjectId,
      currentRecordId,
      parentRecordForForeignKey?.name,
      parentRecordForForeignKey?.record?.id,
      tableColumns,
      tableName,
      viewConfig?.additionalConfig?.matrixToggleAction,
      viewConfig?.additionalConfig?.matrixViewYColumnIsOptions,
      xAxisColumn,
      yAxisColumn?.id
    ]
  );

  const onSubmit = async (data: RecordItem) => {
    try {
      setIsLoading(true);
      if (!xAxisData || !xAxisColumn) return;

      const options = transformRecordItemToSelectOptions({
        items: xAxisData,
        column: xAxisColumn,
        extendedSchema: finalBaseSchema
      });

      const requestsToUpsert: RecordItem[] = [];
      const requestsToDelete: RecordItem[] = [];

      bulkActionsState?.selectedRows?.forEach((row) => {
        Object.keys(data).forEach(async (key) => {
          const option = options.find((option) => option.value?.toString() === key);

          if (!option) return;

          const value = !!data[key]?.inputValue;

          if (value !== !!row[key]) {
            const requestInput = getInputForRequest({ value, row, option });

            if (value === true && requestInput) {
              requestsToUpsert.push(requestInput);
            } else if (value === false && requestInput) {
              // Only delete if all values were true and now are false
              const allValuesAreTrue = bulkActionsState?.selectedRows?.every((row) => row?.[key] === true);

              if (allValuesAreTrue) {
                requestsToDelete.push(requestInput);
              }
            }
          }
        });
      });

      if (requestsToUpsert.length || requestsToDelete.length) {
        const responsesToUpsert = await Promise.all(
          requestsToUpsert.map((reqInput: RecordItem) => onFormSubmitWithOptions(reqInput.finalData, reqInput.options))
        );
        const responsesToDelete = await Promise.all(
          requestsToDelete.map((reqInput: RecordItem) =>
            removeRecordAsync(reqInput as { id: string; tableName: string })
          )
        );

        const allUpsertFailed = responsesToUpsert?.every((record) => !record);
        const allDeleteFailed = responsesToDelete?.every((record) => !record);

        const someUpsertFailed = responsesToUpsert?.some((record) => !record);
        const someDeleteFailed = responsesToDelete?.some((record) => !record);

        if (allUpsertFailed && allDeleteFailed) {
          toast.error("Error saving records");
        } else if (someUpsertFailed || someDeleteFailed) {
          toast.error("Some records were not saved due to incomplete data");
        } else {
          toast.success("Records saved successfully!");
        }

        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
      }

      updateBulkActionsStateByTableSlug(
        {
          selectedRows: []
        },
        currentTableSlug
      );

      updateSidebarState(
        {
          isOpen: false,
          action: undefined,
          showDetailView: false,
          sidebarType: undefined,
          tabSlug: undefined,
          parentRecordSlug: undefined,
          parentRecordId: undefined
        },
        STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
      );
    } catch (error: any) {
      logError({
        error: error as Error,
        message: error.message || "Error saving record",
        source: "BulkActionEditMatrix.tsx - onSubmit",
        type: ERROR_TYPES.STAFF_TABLE,
        url: window.location.href,
        additionalInfo: {
          data
        }
      });
      toast.error("Error saving records");
    } finally {
      setIsLoading(false);
    }
  };

  // This useEffect is needed to get data from xAxisColumn
  useEffect(() => {
    if (!xAxisColumn) {
      return;
    }
    const updatedFinalColumns: TableColumnType[] = [];
    if (xAxisColumn.columnOptionsLookUp) {
      const baseTableName = xAxisColumn.columnOptionsLookUp["0"].lookupTableName;
      setXAxisTableName(baseTableName);

      if (finalBaseSchema?.[baseTableName]?.compositePk?.length) {
        finalBaseSchema?.[baseTableName].compositePk?.forEach((compositeKey) => {
          updatedFinalColumns.push({
            id: xAxisColumn.id,
            hasFilter: false,
            hasBulkEdit: false,
            name: compositeKey.attributeId,
            views: xAxisColumn.views,
            sortOrder: xAxisColumn.sortOrder,
            type: xAxisColumn.type,
            isLookup: false,
            header: xAxisColumn.header
          });
        });
      }
      if (Object.keys(xAxisColumn.columnOptionsLookUp || {}).length >= 1) {
        const newLookupPath: RecordItem = {};
        Object.keys(xAxisColumn.columnOptionsLookUp || {}).forEach((lookupLevel: string) => {
          if (lookupLevel !== "0") {
            newLookupPath[lookupLevel] = xAxisColumn.columnOptionsLookUp?.[lookupLevel];
          }
        });
        if (xAxisColumn.columnOptionsLookUp?.["0"]?.lookupColumns?.length) {
          const { lookupColumns = [] } = xAxisColumn.columnOptionsLookUp["0"];
          // Add as basic column for each lookup column
          // It is necessary for example in People (full_name and type)
          lookupColumns?.forEach((lookupColumn) => {
            updatedFinalColumns.push({
              id: xAxisColumn.id,
              hasFilter: false,
              hasBulkEdit: false,
              name: lookupColumn,
              views: xAxisColumn.views,
              sortOrder: xAxisColumn.sortOrder,
              type: xAxisColumn.type,
              isLookup: false,
              header: xAxisColumn.header
            });
          });
        }
        updatedFinalColumns.push({
          id: xAxisColumn.id,
          hasFilter: false,
          hasBulkEdit: false,
          name: xAxisColumn.name,
          views: xAxisColumn.views,
          sortOrder: xAxisColumn.sortOrder,
          type: xAxisColumn.type,
          isLookup: true,
          lookupPath: newLookupPath,
          header: xAxisColumn.header
        });
      }
      const updatedColFilters: TableFilterType[] = [];
      if (xAxisColumn.columnFilters) {
        xAxisColumn.columnFilters.forEach((filter) => {
          const updatedFilter = { ...filter };
          if (filter.filterLookupPath) {
            const newLookupPath: RecordItem = {};
            Object.keys(filter.filterLookupPath || {}).forEach((lookupLevel: string) => {
              if (
                lookupLevel !== "0" ||
                (lookupLevel === "0" && filter.filterLookupPath?.[lookupLevel]?.lookupForeignKey)
              ) {
                newLookupPath[lookupLevel] = filter.filterLookupPath?.[lookupLevel];
                if (lookupLevel === "0" && filter.filterLookupPath?.[lookupLevel]?.lookupForeignKey) {
                  // Will need to update with a column label to avoid conflict
                  newLookupPath[lookupLevel].lookupColumnLabel =
                    `${filter.filterLookupPath?.[lookupLevel]?.lookupTableName}_${lookupLevel}_colFilter`;
                }
                // Check if any existing column has this same table name
                const isExistingColumn = updatedFinalColumns?.find(
                  (colData) =>
                    colData.isLookup &&
                    !!colData.lookupPath?.["0"]?.lookupTableName &&
                    colData.lookupPath?.["0"]?.lookupTableName === newLookupPath[lookupLevel].lookupTableName
                );
                if (isExistingColumn) {
                  newLookupPath[lookupLevel].lookupColumnLabel =
                    `${newLookupPath[lookupLevel].lookupTableName}_${lookupLevel}`;
                }
              }
            });

            updatedFinalColumns.push({
              id: xAxisColumn.id,
              hasFilter: false,
              hasBulkEdit: false,
              name: xAxisColumn.name,
              views: xAxisColumn.views,
              sortOrder: xAxisColumn.sortOrder,
              type: xAxisColumn.type,
              isLookup: true,
              lookupPath: newLookupPath,
              header: xAxisColumn.header
            });
          }
          if (filter.filterOperator === FILTER_OPERATOR.CURRENT_PROJECT && currentProjectId) {
            updatedFilter.filterValue = currentProjectId;
          }
          if (filter.filterOperator === FILTER_OPERATOR.CURRENT_RECORD && currentRecordId) {
            updatedFilter.filterValue = currentRecordId;
          }
          updatedColFilters.push(updatedFilter);
        });
      }

      if (updatedFinalColumns.length) {
        setXAxisColumns(updatedFinalColumns);
      }
      // Update filter value with current ids
      setXAxisFilters(updatedColFilters || []);
    }
  }, [xAxisColumn, finalBaseSchema, currentProjectId, currentRecordId]);

  // This useEffect is needed to get columns for form
  useEffect(() => {
    if (!xAxisData?.length || !xAxisColumn?.id) {
      return;
    }
    const finalCols: RecordItem[] = [];

    const options = transformRecordItemToSelectOptions({
      items: xAxisData,
      column: xAxisColumn,
      extendedSchema: finalBaseSchema
    });
    const finalOptions = !!xAxisColumn.columnOptionSortOrder
      ? orderBy(
          options,
          (option) => option?.record?.[xAxisColumn.columnOptionSortOrder || ""],
          xAxisColumn?.cellConfig?.columnOptionsProps?.sortDescending ? "desc" : "asc"
        )
      : options;
    finalOptions.forEach((option) => {
      // Verify if all values are true to turn on the toggle
      const allValuesAreTrue = bulkActionsState?.selectedRows?.every((row) => row?.[option.value?.toString()] === true);
      const finalValue = allValuesAreTrue ? true : undefined;

      finalCols.push({
        ...xAxisColumn,
        column: {
          ...xAxisColumn,
          label: option.title,
          header: option.title,
          type: CellType.BOOLEAN,
          id: option.value?.toString()
        },
        header: option.title,
        label: option.title,
        type: CellType.BOOLEAN,
        isEditable: true,
        inForm: true,
        id: option.value?.toString(),
        columnInitValue: xAxisColumn.isLookup ? { displayValue: finalValue, inputValue: finalValue } : finalValue
      });
    });
    setFormFieldsArray(finalCols);
  }, [xAxisData, xAxisColumn, bulkActionsState?.selectedRows, finalBaseSchema]);

  // This useEffect is needed to get columns for table
  useEffect(() => {
    if (!pageData?.columns?.length || !xAxisColumn || !yAxisColumn) {
      return;
    }
    const formHiddenColumns = pageData?.columns?.filter((col) => col.views?.[ViewOption.FORM]?.isHidden);
    setTableColumns([yAxisColumn, xAxisColumn, ...formHiddenColumns]);
  }, [pageData?.columns, xAxisColumn, yAxisColumn]);

  return (
    <FormUI
      onSubmit={onSubmit}
      formFieldsArray={formFieldsArray}
      finalTitle={finalTitle}
      formRequiredErrors={{}}
      relationFormColumnPagesMap={{}}
      fileCol={undefined}
      isLoading={isLoading}
      isJoinTable={false}
      action={AdditionalFormActions.BULK_MATRIX_EDIT}
    />
  );
};

export default BulkActionEditMatrix;
