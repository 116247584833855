"use client";

import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { useEffect, useState } from "react";

import FileUpload from "components/FileUpload";
import FileUploadWindow from "components/FileUpload/FileUploadWindow";
import Modal from "components/Modal";
import useUploadFiles from "hooks/useUploadFiles";
import { FileUploadResults, RecordItem } from "types/common";
import { FORM_TRIGGER_TYPES } from "utils/constants";
import { ACCEPTED_FILE_TYPES } from "utils/file";

type GeneralActionUploadFileProps = {
  isOpen: boolean;
  onSuccess?: (results?: FileUploadResults, triggeredBy?: string) => void;
  onClose?: (...args: RecordItem[]) => void;
  onFilesChange?: (files: File[]) => void;
  returnFiles?: boolean;
  isMultiple?: boolean;
  acceptedFileTypes?: Record<string, string[]>;
  newFiles?: File[];
  tableName: string;
  pageId: string;
  parentRecordInfo?: {
    path: string;
    recordId: string;
    tableName: string;
    pageId?: string;
  };
  createdIn?: string;
  createdInPath?: string;
  trigger?: FORM_TRIGGER_TYPES;
  uploadStatusFromDragAndDrop?: Record<string, string>;
  uploadedFilesFromDragAndDrop?: RecordItem[];
};

const GenActionUploadFiles = ({
  isOpen,
  onSuccess = noop,
  onClose = noop,
  onFilesChange = noop,
  returnFiles = false,
  isMultiple = true,
  acceptedFileTypes = ACCEPTED_FILE_TYPES,
  tableName,
  pageId,
  parentRecordInfo,
  createdIn,
  createdInPath,
  trigger,
  newFiles,
  uploadStatusFromDragAndDrop,
  uploadedFilesFromDragAndDrop
}: GeneralActionUploadFileProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isCurrentFilesSetUpload,
    uploadResults,
    onRejectedFiles,
    onReset,
    isUploading,
    uploadStatus,
    uploadingQueue,
    onUploadFinished,
    triggeredBy,
    onSelectUploadedFiles,
    uploadFiles
  } = useUploadFiles({
    isFilePage: true,
    pageId,
    tableName,
    newFilesToUpload: newFiles,
    uploadedFilesFromDragAndDrop,
    onSuccess,
    onClose,
    returnFiles,
    parentRecordInfo,
    createdIn,
    createdInPath,
    trigger,
    onFilesChange
  });
  const onHandleRejectedFiles = (rejectedFiles: File[]) => {
    setIsModalOpen(false);
    onRejectedFiles(rejectedFiles);
  };

  const onUploadFinishedOrClose = () => {
    setIsModalOpen(false);
    onUploadFinished();
  };

  const resetAction = () => {
    setIsModalOpen(false);
    onReset();
  };

  useEffect(() => {
    if (isOpen !== isModalOpen) {
      setIsModalOpen(isOpen);
    }
  }, [isOpen, isModalOpen]);

  return (
    <>
      {isUploading && (!isEmpty(uploadStatus) || !isEmpty(uploadStatusFromDragAndDrop)) ? (
        <FileUploadWindow
          totalFilesCount={uploadingQueue?.length}
          fileUploadStatus={
            !isEmpty(uploadStatus) ? uploadStatus : (uploadStatusFromDragAndDrop as Record<string, string>)
          }
          onDone={onUploadFinishedOrClose}
          onCloseClick={resetAction}
          triggeredBy={triggeredBy}
          onSelectUploadedFiles={onSelectUploadedFiles}
        />
      ) : null}
      {isModalOpen ? (
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            onClose({
              isOpen: false
            });
          }}
          title={`Add File${isMultiple ? "s" : ""}`}
          titleClassName="h-6"
          classNameBg="overflow-y-hidden h-full min-h-[300px] inline-flex flex-col relative"
        >
          <div className="text-primary w-full overflow-y-auto">
            <div className="w-full flex-1 overflow-y-auto px-6 pb-2 pt-8">
              <FileUpload
                handleAcceptedFiles={(files) => {
                  uploadFiles(files);
                }}
                handleRejectedFiles={onHandleRejectedFiles}
                acceptedFileTypes={acceptedFileTypes}
                multiple={isMultiple}
                isDisabled={isCurrentFilesSetUpload}
                maxFiles={100}
                uploadResults={uploadResults}
                className="mx-auto py-4"
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default GenActionUploadFiles;
