import { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { useSelectedLayoutSegments } from "next/navigation";

const useStableLayoutSegments = () => {
  const [segments, setSegments] = useState<string[] | null>();
  const layoutSegments = useSelectedLayoutSegments();

  useEffect(() => {
    if (!isEqual(segments, layoutSegments)) {
      setSegments(layoutSegments);
    }
  }, [layoutSegments, segments]);

  return segments;
};

export default useStableLayoutSegments;
