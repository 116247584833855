import { Fragment, useMemo } from "react";
import clsx from "clsx";
import groupBy from "lodash/groupBy";

import AvatarMultiple from "components/AvatarMultiple";
import MenuDropdown from "components/MenuDropdown";
import useOnlineUsers from "hooks/useOnlineUsers";

import { AvatarSize } from "utils/constants";
import generateInitials from "utils/generateInitials";

const OnlineUsers = ({ className }: { className?: string }) => {
  const onlineUsers = useOnlineUsers();

  const onlineUsersByType = useMemo(() => {
    const users = onlineUsers.map((user) => ({
      id: user.id,
      src: user?.file?.path,
      title: user?.full_name,
      current_page: user?.current_page,
      initials: generateInitials(user?.full_name),
      alt: `online user ${user?.full_name || ""} image`,
      type: user?.type,
      iconWrapperClassName: "!bg-green-00 !h-2 !w-2",
      disabled: !!user?.disabled
    }));
    return groupBy(users, "type");
  }, [onlineUsers]);

  const onlineUsersList = useMemo(() => {
    const users = onlineUsers.map((user) => ({
      id: user.id,
      label: user?.full_name,
      avatar: user?.file?.path,
      link: user?.current_page,
      initials: generateInitials(user?.full_name),
      alt: `online user ${user?.full_name || ""} image`,
      type: user?.type,
      disabled: !!user?.disabled
    }));
    return groupBy(users, "type");
  }, [onlineUsers]);

  return (
    <div className={clsx("flex h-[49px] items-center", className)}>
      <div className="flex items-center gap-x-2">
        {onlineUsersByType?.Client?.length && (
          <>
            <AvatarMultiple size={AvatarSize.XS} images={onlineUsersByType?.Client} maxImages={4} />
            {onlineUsersByType.Client.length > 4 && (
              <MenuDropdown
                labelComponent={<span className="text-primary text-3xs">+{onlineUsersByType.Client.length - 4}</span>}
                labelClassName="!pl-2 !py-0 h-[49px]"
                items={onlineUsersList.Client.slice(4)}
                showArrow={false}
                wrapper={<Fragment />}
              />
            )}
            <div className="dark:bg-background h-6 w-[1px] bg-neutral-200"></div>
          </>
        )}
        {onlineUsersByType?.Staff?.length && (
          <>
            <AvatarMultiple size={AvatarSize.XS} images={onlineUsersByType?.Staff} maxImages={4} />
            {onlineUsersByType.Staff.length > 4 && (
              <MenuDropdown
                labelComponent={<span className="text-primary text-3xs">+{onlineUsersByType.Staff.length - 4}</span>}
                labelClassName="!pl-2 !py-0 h-[49px]"
                items={onlineUsersList.Staff.slice(4)}
                showArrow={false}
                wrapper={<Fragment />}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OnlineUsers;
