import { useQuery } from "@tanstack/react-query";

import { useMemo } from "react";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getOrganizations } from "lib/adminApi";

const useOrganizations = () => {
  const supabaseClient = useSupabaseBrowser();
  const { data } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizations(supabaseClient),
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });

  const options = useMemo(() => {
    if (!data?.data) return [];
    return data.data.map((org) => ({
      title: org.name,
      value: org.id,
      organization: org
    }));
  }, [data?.data]);

  return { data: data?.data, options };
};

export default useOrganizations;
