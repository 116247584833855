import { Fragment, useCallback, Dispatch, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { BellIcon, XIcon } from "lucide-react";

import useNotificationCenter from "hooks/useNotificationCenter";
import NotificationCenter from "components/NotificationCenter";
import IconButton from "components/IconButton";

type NavigationNotificationsProps = {
  isCollapsed?: boolean;
  menuPosition: "top" | "left";
  isOpen?: boolean;
  onToggle: Dispatch<SetStateAction<boolean>>;
};

const NavigationNotifications = ({ isCollapsed, menuPosition, isOpen, onToggle }: NavigationNotificationsProps) => {
  const { unreadCount } = useNotificationCenter();
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const handleClose = useCallback(() => {
    onToggle(false);
  }, [onToggle]);

  const toggleNotificationCenter = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onToggle((prev) => !prev);
    },
    [onToggle]
  );

  return (
    <>
      <div
        className={clsx(
          "relative flex cursor-pointer items-center justify-center p-1",
          isCollapsed ? "hidden group-hover/nav:flex" : "hidden lg:flex"
        )}
      >
        {!!unreadCount && (
          <span className="absolute right-1 top-1 flex h-2 w-2">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-700 opacity-70 dark:bg-red-dark-700" />
            <span className="relative inline-flex h-full w-full rounded-full bg-red-700 dark:bg-red-dark-700" />
          </span>
        )}
        <BellIcon
          className="block h-5 w-5 text-base-primary dark:text-base-dark-primary"
          onMouseDown={toggleNotificationCenter}
        />
      </div>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={clsx("z-50", !isMobile ? "absolute left-72 right-0" : "relative")}
          onClose={() => {
            onToggle(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                !isMobile ? " inset-y-0 left-72 right-0" : "inset-0",
                "fixed bg-neutral-dark-0/40",
                menuPosition === "top" ? "clients lg:inset-x-0 lg:top-[88px]" : ""
              )}
              aria-hidden="true"
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel
              className={clsx(
                "bg-background border-separator fixed inset-y-0 z-10 overflow-hidden border-l",
                !isMobile ? "left-72 w-[474px]" : "left-0 right-0",
                menuPosition === "top" ? "clientsp lg:left-auto lg:right-0 lg:top-[88px]" : ""
              )}
              aria-label="Global"
            >
              {isMobile && (
                <div className="w-full border-b border-neutral-200 py-3 pl-6 dark:border-neutral-dark-200 lg:hidden">
                  <IconButton icon={XIcon} onClick={() => onToggle(false)} />
                </div>
              )}
              <NotificationCenter onClose={handleClose} />
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default NavigationNotifications;
