"use client";

import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import uniqBy from "lodash/uniqBy";

import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import toast from "utils/toast";
import useTableActionsState from "hooks/useTableActionsState";
import useSchemaState from "hooks/useSchemaState";
import useFormFieldsSubmission from "hooks/useFormFieldsSubmission";
import {
  AdditionalFormActions,
  CellType,
  ERROR_TYPES,
  FILTER_OPERATOR,
  STATIC_SIDEBAR_IDS,
  SelectCellTypes,
  ViewOption
} from "utils/constants";
import { SelectOptions, RecordItem, SelectOption } from "types/common";

import usePageByPath from "hooks/usePageByPath";
import useTableData from "hooks/useTableData";
import useErrorLogger from "hooks/useErrorLogger";
import { readLookupFieldsForCellGroups } from "lib/utils";
import { generateFinalDataColumns, getLastLookupColumnName, isColumnFileTag } from "utils/dataUtils";
import { appendRecordTypeLookupToCol, isColumnTextWithOptions } from "utils/columnUtils";
import { FormJoinTableRelationInfo, TableColumnType } from "types/baTypes";
import { getUpdatedFormulaColumns } from "components/DetailView/utils";
import useRelationPages from "hooks/useRelationPages";
import useRecordTypes from "hooks/useRecordTypes";
import ErrorView from "components/ErrorView";
import { getPageTitle, getRelationFormColumnPagesMap, getColumnForeignKeyOnTable } from "./utils";
import FormUI from "./FormUI";

/**
 * This component handles Bulk Edit action, including fetching
 *  page data, setting up Add / Remove fields
 *  submission is made with useFormFieldsSubmission hook
 */

const BulkActionEditForm = () => {
  const { logError } = useErrorLogger();
  const queryClient = useQueryClient();
  const { schemaInstance } = useSchemaState();
  const { data: recordTypesData } = useRecordTypes();
  const {
    bulkActionsStateByTableSlug,
    updateBulkActionsStateByTableSlug,
    updateSidebarState,
    currentTablePage,
    superAdminOrgIdFilterDisabledByTableSlug
  } = useTableActionsState();
  const { data: relationPages, getRelationPages } = useRelationPages();
  const currentTableSlug = currentTablePage?.path?.replace("/", "") as string;
  const bulkActionsState = bulkActionsStateByTableSlug?.[currentTableSlug];

  const { data: pageData, isLoading: pageLoading } = usePageByPath(
    { slug: (bulkActionsState?.tablePageSlug || "")?.replace("/", "") },
    {
      enabled: !!bulkActionsState?.tablePageSlug,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );
  const finalBaseSchema = pageData?.page_config?.alternateSchema
    ? schemaInstance?.alternateSchema?.[pageData?.page_config?.alternateSchema]
    : schemaInstance?.extendedSchema;

  const finalColumnOptions = useMemo(() => {
    return !finalBaseSchema
      ? []
      : (pageData?.columns
          ?.map((col) => appendRecordTypeLookupToCol(col, recordTypesData || [], pageData?.table_name, finalBaseSchema))
          .filter(Boolean) as TableColumnType[]) || [];
  }, [pageData?.columns, recordTypesData, pageData?.table_name, finalBaseSchema]);

  const columnsForRecordFetch = useMemo(() => {
    return generateFinalDataColumns({
      columns: finalColumnOptions,
      view: ViewOption.GRID,
      additionalColMatch: (col) => {
        return !!col.hasBulkEdit;
      },
      recordTypesData,
      tableName: pageData?.table_name || "",
      extendedSchema: finalBaseSchema
    });
  }, [pageData, recordTypesData, finalBaseSchema, finalColumnOptions]);

  const formColumnsForBulkEdit = useMemo(
    () =>
      sortBy(
        finalColumnOptions?.filter((col) => col.hasBulkEdit),
        "cellConfig.bulkEditConfig.sortOrder"
      ),
    [finalColumnOptions]
  );

  const {
    data: recordsData,
    error: recordsErr,
    refetch
  } = useTableData({
    tableName: pageData?.table_name || "",
    columns: columnsForRecordFetch || [],
    filters: bulkActionsState?.selectedRows?.length
      ? [
          {
            filterField: "id",
            filterValue: bulkActionsState?.selectedRows?.map((row) => row.id),
            filterOperator: FILTER_OPERATOR.IN
          }
        ]
      : [],
    hookOptions: {
      enabled: !!bulkActionsState?.selectedRows?.length
    },
    disableOrgIdFilter: !!superAdminOrgIdFilterDisabledByTableSlug?.[currentTableSlug]
  });

  const formRelationPageIds = useMemo(
    () =>
      pageData?.columns
        ?.map((column) =>
          column.views?.[ViewOption.FORM]?.id && !!column.views?.[ViewOption.FORM]?.formRelationPageId
            ? column.views?.[ViewOption.FORM]?.formRelationPageId
            : null
        )
        .filter(Boolean),
    [pageData?.columns]
  );

  useEffect(() => {
    if (!formRelationPageIds?.length) return;

    getRelationPages(formRelationPageIds as string[]);
  }, [formRelationPageIds, getRelationPages]);

  const relationFormColumnPagesMap = useMemo(() => {
    if (!relationPages?.length || !pageData?.columns?.length) return {};

    return getRelationFormColumnPagesMap(pageData.columns, relationPages);
  }, [pageData?.columns, relationPages]);

  const joinTableRelationInfo: FormJoinTableRelationInfo[] = useMemo(
    () =>
      Object.keys(relationFormColumnPagesMap).map((relationField) => {
        const relationTablepage = relationFormColumnPagesMap[relationField];
        return {
          tableName: relationTablepage.table_name,
          columns: relationTablepage.columns?.filter((col) => col.views?.[ViewOption.FORM]?.id),
          relationField
        };
      }),
    [relationFormColumnPagesMap]
  );

  const { onFormSubmitWithOptions } = useFormFieldsSubmission({
    tableName: pageData?.table_name || "",
    formColumns: columnsForRecordFetch || [],
    record: recordsData || undefined,
    slug: bulkActionsState?.tablePageSlug || "",
    joinTableRelationInfo,
    alternateSchema: pageData?.page_config?.alternateSchema,
    source: "Bulk Edit Action Form"
  });

  const isJoinTable = !!(pageData && finalBaseSchema?.[pageData?.table_name].compositePk?.length);

  const [formFieldsArray, setFormFieldsArray] = useState<RecordItem[]>([]);
  const [formRequiredErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState(false);

  const [formFormulaFieldsMap, setFormFormulaFieldsMap] = useState<Record<string, string[]>>({});
  const [formulaFieldIds, setFormulaFieldIds] = useState<string[]>([]);

  const fileCol = useMemo(() => {
    return finalColumnOptions?.find((column) => column.type === CellType.FILE);
  }, [finalColumnOptions]);

  useEffect(() => {
    if (!finalColumnOptions?.length) return;

    let finalFormulaColIds: string[] = [];
    const finalRollupSourceFields: string[] = [];
    const formulaColMap: { [colId: string]: string[] } = {};
    finalColumnOptions.forEach((col: TableColumnType) => {
      if (col.isRollup) {
        const { rollupConfig } = col;
        if (rollupConfig?.sourceColId) {
          finalRollupSourceFields.push(rollupConfig.sourceColId);
        }
      }
      if (col.isFormula) {
        const { formula } = col;
        if (formula) {
          const formulaIds = formula.match(/\{(.*?)\}/g);
          const formulaColIds = formulaIds?.map((id) => id.replace("{", "").replace("}", ""));
          if (formulaColIds) {
            finalFormulaColIds = finalFormulaColIds.concat(formulaColIds);
            formulaColMap[col.id] = formulaColIds;
          }
        }
      }
    });

    if (!isEmpty(formulaColMap)) {
      setFormulaFieldIds(finalFormulaColIds);
      setFormFormulaFieldsMap(formulaColMap);
    }
  }, [finalColumnOptions]);

  const onSubmit = async (data: RecordItem) => {
    if (!recordsData?.length || !pageData?.table_name) {
      if (pageData?.table_name) {
        refetch();
        toast.error("Records not fetched yet, please close the sidebar and try again");
        return;
      }
      if (!bulkActionsState?.selectedRows?.length) {
        toast.error("Please reselect records and try again");
        return;
      }
      toast.error("Please reload the page and try again");
      return;
    }

    try {
      setIsLoading(true);
      // Pick out all direct columns
      const directRecordColumns: RecordItem[] = [];
      const lookupRecordColumns: RecordItem[] = [];
      const colIdToNameMap: { [colId: string]: string } = {};
      const colIdsWithRemove: string[] = [];
      const removeFormKeys = Object.keys(data).filter((key) => key.includes("_remove"));
      // All direct columns and direct foreign key columns
      formFieldsArray.forEach((col) => {
        if (
          !col.column.isLookup ||
          (col.column.isLookup && !!getColumnForeignKeyOnTable(col.column) && !isColumnFileTag(col.column))
        ) {
          directRecordColumns.push(col);
        } else {
          lookupRecordColumns.push(col);
        }
        if (removeFormKeys.find((key) => key === col.id + "_remove" && !colIdsWithRemove.includes(col.id))) {
          colIdsWithRemove.push(col.id);
        }
      });
      const directData: RecordItem = {};
      directRecordColumns.forEach((col) => {
        if (col.column.isLookup && !!getColumnForeignKeyOnTable(col.column)) {
          const lookupFKName = getColumnForeignKeyOnTable(col.column);
          if (lookupFKName && (data[col.id]?.inputValue?.record?.id || data[col.id]?.inputValue?.optionData?.id)) {
            colIdToNameMap[col.id] = lookupFKName;
            directData[col.id] = data[col.id]?.inputValue;
          }
        } else if (isColumnTextWithOptions(col.column)) {
          colIdToNameMap[col.id] = col.column.name;
          const fieldData = data[col.id];
          if (Array.isArray(fieldData)) {
            // This is to handle a misconfig as this should be a single value
            directData[col.id] = fieldData[0]?.title || "";
          } else {
            if (fieldData?.title) {
              directData[col.id] = fieldData?.title;
            }
          }
        } else if (data[col.id]) {
          colIdToNameMap[col.id] = col.column.name;
          directData[col.id] = data[col.id];
        }
      });
      const lookupFormData: RecordItem = {};
      lookupRecordColumns.forEach((col) => {
        if (!data[col.id]?.inputValue) {
          return;
        }
        const firstLookupLevel = col.column?.lookupPath?.[0];
        const colName =
          firstLookupLevel?.lookupColumnLabel ||
          firstLookupLevel?.lookupForeignKey ||
          firstLookupLevel?.lookupTableName;
        if (colName) {
          colIdToNameMap[col.id] = colName;
        }
        if (!col.id.includes("_remove")) {
          if (colIdsWithRemove.includes(col.id)) {
            lookupFormData[col.id] = [...data[col.id].inputValue];
          } else {
            lookupFormData[col.id] = data[col.id].inputValue;
          }
        } else if (data[col.id].inputValue) {
          const finalColId = col.id.replace("_remove", "");
          // Updated inputValue optionData to match record
          // This is to fix picking the wrong id when submitting form
          const finalRemoveValues = data[col.id].inputValue.map((option: SelectOption) => ({
            ...option,
            optionData: { ...option.record }
          }));
          lookupFormData[finalColId] = [...(lookupFormData[finalColId] || []), ...finalRemoveValues];
        }
      });
      const finalRequests: any = [];
      recordsData.forEach(async (record) => {
        // Calculate any related rollup or formula columns
        const finalInput = {
          ...directData,
          ...lookupFormData
        };
        let finalRecord = { ...record };
        Object.keys(finalInput).forEach((colId) => {
          finalRecord = { ...finalRecord };
          // Check and add any formula columns that depend on changed column
          if (formulaFieldIds.includes(colId)) {
            finalRecord[colIdToNameMap[colId]] = finalInput[colId];
            try {
              const updatedFinalData = getUpdatedFormulaColumns({
                updatedColumnId: colId,
                updatedValue: finalInput[colId],
                finalRecord,
                pageColumns: pageData?.columns || [],
                formulaFieldIds,
                formFormulaFieldsMap
              });
              if (!isEmpty(updatedFinalData)) {
                Object.keys(updatedFinalData || {}).forEach((colId: string) => {
                  const formulaResult = updatedFinalData?.[colId];
                  if (Number.isFinite(formulaResult)) {
                    finalInput[colId] = formulaResult;
                  }
                });
              }
            } catch (err) {
              console.log("@@ Error in formula ", err);
            }
          }
        });

        finalRequests.push({
          input: { ...finalInput },
          record: finalRecord
        });
      });
      const newRecords = await Promise.all(
        finalRequests.map((reqInput: any) =>
          onFormSubmitWithOptions(
            {
              ...reqInput.input
            },
            {
              ignoreJoinTableCheck: true, // TODO: Added to resolve projects_files edit, check if this is causing issues in other pages
              record: reqInput.record,
              isFromBulkEdit: true
            }
          )
        )
      );
      const allRecordsFailed = newRecords?.every((record) => !record);
      if (allRecordsFailed) {
        toast.error("Error saving records");
      } else if ((newRecords || []).some((val) => !val)) {
        toast.error("Some lookup records were not saved due to incomplete data");
      } else {
        toast.success("Records saved successfully!");
        if (newRecords?.length < 10) {
          queryClient.refetchQueries({ queryKey: ["table", pageData.table_name], exact: false });
        } else {
          // Added another delayed request to allow uncached response to be returned when record count is high
          setTimeout(() => {
            queryClient.refetchQueries({ queryKey: ["table", pageData.table_name], exact: false });
          }, 700);
        }
      }

      setIsLoading(false);
      updateBulkActionsStateByTableSlug(
        {
          selectedRows: []
        },
        currentTableSlug
      );
      updateSidebarState(
        {
          isOpen: false,
          action: undefined,
          showDetailView: false,
          sidebarType: undefined
        },
        STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
      );
    } catch (err: any) {
      logError({
        error: err as Error,
        message: err.message || "Error saving record",
        source: "BulkActionEditForm - onSubmit",
        type: ERROR_TYPES.STAFF_TABLE,
        url: window.location.href,
        additionalInfo: {
          data
        }
      });
      toast.error("Error saving records..");
    }
  };

  useEffect(() => {
    if (!formColumnsForBulkEdit?.length || !recordsData?.length) return;
    // Build Add / Remove fields for all multiple select columns

    if (!formColumnsForBulkEdit?.length) return;
    const updatedFormColumns: RecordItem[] = [];
    // Pick all formula columns and columns within formulas
    let finalFormulaColIds: string[] = [];
    const formulaColMap: { [colId: string]: string[] } = {};
    formColumnsForBulkEdit.forEach((col: TableColumnType) => {
      if (col.isFormula) {
        const { formula } = col;
        if (formula) {
          const formulaIds = formula.match(/\{(.*?)\}/g);
          const formulaColIds = formulaIds?.map((id) => id.replace("{", "").replace("}", ""));
          if (formulaColIds) {
            finalFormulaColIds = finalFormulaColIds.concat(formulaColIds);
            formulaColMap[col.id] = formulaColIds;
          }
        }
      }
    });
    formColumnsForBulkEdit.forEach((column) => {
      if (SelectCellTypes.includes(column.type) && column.isMultiple) {
        updatedFormColumns.push({
          id: column.id,
          name: column.name,
          label: column.header,
          type: column.type,
          isError: false,
          column,
          columnInitValue: null
        });
        const removeOptions: SelectOptions = [];
        const removeOptionsCountList: RecordItem = {};
        recordsData.forEach((record) => {
          const recordValue = readLookupFieldsForCellGroups(column, record, finalBaseSchema);
          const lastLookupCol = getLastLookupColumnName(column);
          if (Array.isArray(recordValue)) {
            recordValue.forEach((rec) => {
              if (rec?.[lastLookupCol]) {
                removeOptions.push({
                  value: rec?.id || "",
                  title: lastLookupCol ? rec?.[lastLookupCol] || "" : "",
                  record: { ...rec, value: lastLookupCol ? rec?.[lastLookupCol] || "" : "" },
                  optionData: { ...record }
                });
                if (removeOptionsCountList[rec?.id]?.option?.value === rec?.id) {
                  removeOptionsCountList[rec?.id].count = removeOptionsCountList[rec?.id].count + 1;
                } else {
                  removeOptionsCountList[rec?.id] = {
                    option: {
                      value: rec?.id,
                      title: lastLookupCol ? rec?.[lastLookupCol] || "" : "",
                      record: { ...rec, value: lastLookupCol ? rec?.[lastLookupCol] || "" : "" },
                      optionData: { ...record }
                    },
                    count: 1
                  };
                }
              }
            });
          } else if (recordValue?.[lastLookupCol]) {
            removeOptions.push({
              value: recordValue?.id || "",
              title: lastLookupCol ? recordValue?.[lastLookupCol] || "" : "",
              record: { ...recordValue, value: lastLookupCol ? recordValue?.[lastLookupCol] || "" : "" },
              optionData: { ...record }
            });
            if (removeOptionsCountList[recordValue?.id]?.option?.value === recordValue?.id) {
              removeOptionsCountList[recordValue?.id].count = removeOptionsCountList[recordValue?.id].count + 1;
            } else {
              removeOptionsCountList[recordValue?.id] = {
                option: {
                  value: recordValue?.id,
                  title: lastLookupCol ? recordValue?.[lastLookupCol] || "" : "",
                  record: { ...recordValue, value: lastLookupCol ? recordValue?.[lastLookupCol] || "" : "" },
                  optionData: { ...record }
                },
                count: 1
              };
            }
          }
        });
        // Only add options available in all selected columns
        const finalRemoveOptions: SelectOptions = [];
        Object.keys(removeOptionsCountList).forEach((key) => {
          const optionAndCount = removeOptionsCountList[key];
          if (optionAndCount.count === recordsData.length) {
            finalRemoveOptions.push(optionAndCount.option);
          }
        });
        if (!!finalRemoveOptions?.length) {
          updatedFormColumns.push({
            id: column.id + "_remove",
            name: column.name,
            label: "Remove",
            type: column.type,
            isError: false,
            column,
            columnInitValue: null,
            options: uniqBy(finalRemoveOptions, "title").filter((option) => !!option.value)
          });
        }
      } else {
        updatedFormColumns.push({
          id: column.id,
          name: column.name,
          label: column.header,
          type: column.type,
          isError: false,
          column,
          columnInitValue: null
        });
      }
    });

    setFormFieldsArray(updatedFormColumns);
  }, [formColumnsForBulkEdit, recordsData, finalBaseSchema]);

  const finalTitle = pageData?.title ? getPageTitle("", pageData?.title) : "";

  if (recordsErr) {
    return <ErrorView error={recordsErr} />;
  }

  return (
    <FormUI
      onSubmit={onSubmit}
      formFieldsArray={formFieldsArray}
      finalTitle={finalTitle}
      formRequiredErrors={formRequiredErrors}
      relationFormColumnPagesMap={relationFormColumnPagesMap}
      fileCol={fileCol}
      isLoading={isLoading || pageLoading}
      isJoinTable={isJoinTable}
      action={AdditionalFormActions.BULK_EDIT}
    />
  );
};

export default BulkActionEditForm;
