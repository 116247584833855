"use client";

import { LicenseInfo } from "@mui/x-license";
import { ThemeProvider } from "next-themes";

const MUI_KEY = process.env.NEXT_PUBLIC_MUI_KEY;

LicenseInfo.setLicenseKey(MUI_KEY || "");

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider enableSystem={false} attribute="class" defaultTheme="light">
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
