"use client";

import { Dialog, Transition } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { MenuSquareIcon } from "lucide-react";
import { usePathname, useRouter, useSearchParams, useSelectedLayoutSegments } from "next/navigation";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import NavigationBackButton from "components/NavigationItem/NavigationBackButton";
import { SearchContainer } from "components/Search/SearchContainer";

import useCurrentUser from "hooks/useCurrentUser";
import useInLayout from "hooks/useInLayout";
import useNavItemsRecordCounts from "hooks/useNavItemsRecordCounts";
import useNotificationCenter from "hooks/useNotificationCenter";
import usePageByPath from "hooks/usePageByPath";
import usePageConfigState from "hooks/usePageConfigState";
import usePageDataById from "hooks/usePageDataById";
import usePageViews from "hooks/usePageViews";
import useSessionState from "hooks/useSessionState";
import useUIState from "hooks/useUIState";
import useUpdateRecord from "hooks/useUpdateRecord";
import useUpsertDeletePageViewConfig from "hooks/useUpsertDeletePageViewConfig";

import { CONFIG_MODAL_TYPES, NavigationItem, PAGE_TYPES } from "types/common";

import { getAdminTablePageNavigation } from "app/(authRoutes)/(global)/utils";
import { ACTIVITY_TABLE, USER_TYPE, ViewOption } from "utils/constants";
import { getPageViewRoute } from "utils/pageUtils";
import { getDefaultNavigationItem } from "utils/pathUtils";

import { Organization } from "types/apiTypes";

import NavOrgSelectionMenu from "./NavOrgSelectionMenu";
import NavigationItemsList from "./NavigationItemsList";
import NavigationNotifications from "./NavigationNotifications";
import NavigationUserMenu from "./NavigationUserMenu";
import NavigationUserRecentHistory from "./NavigationUserRecentHistory";

type NavigationLeftProps = {
  navigationMenuItems: NavigationItem[];
  searchBarEnabled: boolean;
};

const NavigationLeft = ({ navigationMenuItems, searchBarEnabled }: NavigationLeftProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const layoutSegments = useSelectedLayoutSegments();
  const currentUser = useCurrentUser();
  const { updatePageConfigModalState } = usePageConfigState();
  const queryClient = useQueryClient();
  const { handleDeleteView } = useUpsertDeletePageViewConfig();

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showNotificationCenter, setShowNotificationCenter] = useState(false);
  const [navigationUserMenuOpen, setNavigationUserMenuOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { unreadCount } = useNotificationCenter();
  const { uiState } = useUIState();
  const [isDesktop, setIsDesktop] = useState(false);
  const [isOrgSelectOpen, setIsOrgSelectOpen] = useState(false);
  const [navUserRecentHistoryOpen, setNavUserRecentHistoryOpen] = useState(false);

  const { updateCurrentUserOrgId } = useSessionState();

  const { isAdminPage, isTable, isRecord, isReportsPage } = useInLayout();

  const { countByNavItem } = useNavItemsRecordCounts({
    items: navigationMenuItems,
    source: "NavigationLeft"
  });

  const currentMenuLocation = !layoutSegments?.length
    ? currentUser?.organizationName || "BuildAppeal"
    : isTable || isReportsPage
      ? "Main"
      : isAdminPage
        ? "Admin"
        : isRecord
          ? "Detail"
          : "BuildAppeal";

  const defaultMenuItem = getDefaultNavigationItem(navigationMenuItems || []);
  const finalSlug =
    !layoutSegments?.length || isAdminPage
      ? ""
      : ["table", "r"].includes(layoutSegments[0]) && layoutSegments.length > 1
        ? layoutSegments?.[1]
        : layoutSegments?.[0];

  const adminPageId = !layoutSegments?.length || !isAdminPage ? undefined : layoutSegments?.[2];

  const { data: pageViews, refetch } = usePageViews(adminPageId);

  const userTypeQP = searchParams?.get("user_type") || undefined;
  const { data: pageData } = usePageByPath({ slug: finalSlug, userTypeQP, source: "NavigationLeft" });

  const isDesktopView = useMediaQuery("(min-width: 1024px)");
  const isCollapsed =
    (isRecord && !showNotificationCenter && !navigationUserMenuOpen && !isOrgSelectOpen && !navUserRecentHistoryOpen) ||
    uiState?.isCollapsed;

  useEffect(() => {
    setIsDesktop(isDesktopView);
  }, [isDesktopView]);

  const adminTablePageId =
    isAdminPage && layoutSegments?.includes("tables") && layoutSegments?.length > 2 ? layoutSegments[2] : undefined;

  const { data: adminTablePageData } = usePageDataById(adminTablePageId, {
    enabled: !!adminTablePageId
  });

  const addNewPageFormView = useCallback(() => {
    updatePageConfigModalState({
      isOpen: true,
      type: CONFIG_MODAL_TYPES.FORM_VIEW,
      additionalConfig: {
        isPageView: true
      },
      onSuccess: () => {
        const adminPageId = layoutSegments?.[2];
        refetch();
        queryClient.invalidateQueries({ queryKey: ["page-child", adminPageId] });
        queryClient.refetchQueries({ queryKey: ["page-child", adminPageId] });
      }
    });
  }, [updatePageConfigModalState, refetch, queryClient, layoutSegments]);

  const deletePageFormView = useCallback(
    (viewId?: string) => {
      if (!viewId) return;
      handleDeleteView({
        viewId,
        onSuccess: () => {
          const adminPageId = layoutSegments?.[2];
          router.refresh();
          refetch();
          router.push(`/admin/tables/${adminPageId}/form`);
        }
      });
    },
    [router, handleDeleteView, refetch, layoutSegments]
  );

  const renamePageFormView = useCallback(
    (pageViewId?: string, isDefaultForm = false) => {
      if (!pageViewId) return;
      const viewToEdit = pageViews?.views?.find((view) => view.id === pageViewId);
      updatePageConfigModalState({
        isOpen: true,
        type: CONFIG_MODAL_TYPES.FORM_VIEW,
        additionalConfig: {
          isDefaultForm: !!isDefaultForm,
          isPageView: true,
          initialViewConfig: viewToEdit
        },
        onSuccess: () => {
          const adminPageId = layoutSegments?.[2];
          refetch();
          queryClient.invalidateQueries({ queryKey: ["page-child", adminPageId] });
          queryClient.refetchQueries({ queryKey: ["page-child", adminPageId] });
        }
      });
    },
    [updatePageConfigModalState, pageViews, layoutSegments, refetch, queryClient]
  );

  const duplicatePageFormView = useCallback(
    (pageViewId?: string) => {
      if (!pageViewId) return;
      const viewToDuplicate = pageViews?.views?.find((view) => view.id === pageViewId);
      updatePageConfigModalState({
        isOpen: true,
        type: CONFIG_MODAL_TYPES.FORM_VIEW,
        additionalConfig: {
          isPageView: true,
          initialViewConfig: viewToDuplicate,
          duplicateView: true
        },
        onSuccess: () => {
          const adminPageId = layoutSegments?.[2];
          refetch();
          queryClient.invalidateQueries({ queryKey: ["page-child", adminPageId] });
          queryClient.refetchQueries({ queryKey: ["page-child", adminPageId] });
        }
      });
    },
    [layoutSegments, pageViews, refetch, queryClient, updatePageConfigModalState]
  );

  /**
   * Each section is picked based on pageType current it only shows dynamic menu for main /table
   * and for other section via util. We will shift this whole section to be dynamic later
   * */
  const firstNavItems = useMemo(() => {
    return isAdminPage && adminTablePageId
      ? getAdminTablePageNavigation({
          adminId: layoutSegments?.[2],
          adminSection: layoutSegments?.[1],
          pageType: searchParams?.get("type") ?? PAGE_TYPES.DATABASE,
          isAdminPage: !!adminTablePageData?.is_admin_page,
          isActivityTable: adminTablePageData?.table_name === ACTIVITY_TABLE,
          additionalActionsClickHandlers: {
            [ViewOption.FORM]: {
              add: addNewPageFormView,
              delete: deletePageFormView,
              rename: renamePageFormView,
              duplicate: duplicatePageFormView
            }
          },
          pageViews
        })
      : [];
  }, [
    isAdminPage,
    layoutSegments,
    searchParams,
    adminTablePageData?.is_admin_page,
    adminTablePageData?.table_name,
    adminTablePageId,
    addNewPageFormView,
    pageViews,
    deletePageFormView,
    renamePageFormView,
    duplicatePageFormView
  ]);

  const showAdminMenu =
    (currentMenuLocation === "Main" || currentMenuLocation === "Detail" || currentMenuLocation === "Admin") &&
    currentUser?.type === USER_TYPE.STAFF &&
    currentUser?.is_admin;

  const showBack = isAdminPage && (layoutSegments?.length || 0) > 0;

  const backButtonLink =
    isAdminPage && (layoutSegments?.length || 0) <= 2
      ? "/table"
      : isAdminPage && (layoutSegments?.length || 0) > 2
        ? `/admin/${layoutSegments?.[1]}`
        : isRecord
          ? pageData?.id
            ? `/table/${layoutSegments?.[0]}/${getPageViewRoute(pageData, !isDesktop).replace("_", "")}`
            : `/table/${layoutSegments?.[0]}`
          : "";

  const { updateRecordAsync } = useUpdateRecord();

  const onEditMenuItem = useCallback(
    async (input: any) => {
      try {
        await updateRecordAsync({ tableName: "ui_menu_items", input });
      } catch (error) {
        console.error(error);
      }
    },
    [updateRecordAsync]
  );

  const createNewPage = useCallback(() => {
    updatePageConfigModalState({
      isOpen: true,
      type: CONFIG_MODAL_TYPES.BASIC_PAGE
    });
  }, [updatePageConfigModalState]);

  useEffect(() => {
    if (isDesktop) return;
    setShowMobileMenu(false);
  }, [pathname, isDesktop]);

  const backButtonLabel = isRecord
    ? pageData?.title
    : isAdminPage && (layoutSegments?.length || 0) <= 2
      ? undefined
      : "Go Back";

  const redirectToApp = useCallback(() => {
    const urlToRedirect = isAdminPage ? "/table" : (defaultMenuItem?.href as string) || "/table";

    router.push(urlToRedirect);
    router.refresh();
  }, [router, isAdminPage, defaultMenuItem]);

  const handleOrgSelectChange = useCallback(
    (org: Organization) => {
      setIsOrgSelectOpen(false);
      updateCurrentUserOrgId(org.id);
    },
    [updateCurrentUserOrgId]
  );

  const onCloseMobileMenu = useCallback(() => setShowMobileMenu(false), []);

  const onSearchBoxClick = useCallback((open: boolean) => {
    setOpenSearch(open);

    open && setShowMobileMenu(false);
  }, []);

  const handleNavigationUserMenuShow = useCallback((open: boolean) => {
    setNavigationUserMenuOpen(open);
  }, []);

  return (
    <section
      className={clsx(
        "group/nav border-separator bg-background sbar contents border-r pb-2 pt-4 transition-width scrollbar-hide",
        (isRecord &&
          !showNotificationCenter &&
          !navigationUserMenuOpen &&
          !isOrgSelectOpen &&
          !navUserRecentHistoryOpen) ||
          isCollapsed
          ? "w-16 hover:w-80"
          : "w-80",
        isRecord && !showNotificationCenter && !navigationUserMenuOpen && !isOrgSelectOpen && !navUserRecentHistoryOpen
          ? "z-40 hover:z-50"
          : "z-40",
        "lg:fixed lg:inset-y-0 lg:left-0 lg:flex lg:flex-col lg:overflow-y-auto"
      )}
    >
      {!(isRecord && !isDesktop) && (
        <header className="border-separator flex items-center gap-2 border-b px-6 py-3 lg:border-b-0 lg:py-0">
          <div className="flex w-full">
            <NavOrgSelectionMenu
              currentUser={currentUser}
              onMenuOpen={setIsOrgSelectOpen}
              onOrgSelectionChange={handleOrgSelectChange}
              onClick={redirectToApp}
              isCollapsed={isCollapsed}
              open={isOrgSelectOpen}
            />
          </div>

          <NavigationNotifications
            isCollapsed={isCollapsed}
            menuPosition="left"
            isOpen={showNotificationCenter}
            onToggle={setShowNotificationCenter}
          />

          <NavigationUserRecentHistory
            isCollapsed={isCollapsed}
            open={navUserRecentHistoryOpen}
            onMenuOpen={setNavUserRecentHistoryOpen}
          />
          <div
            className={clsx(
              "-mt-[1px] mr-1 cursor-pointer",
              isCollapsed ? "hidden group-hover/nav:flex" : "hidden justify-end lg:flex"
            )}
          >
            <NavigationUserMenu currentUser={currentUser} onMenuOpen={handleNavigationUserMenuShow} />
          </div>
          {!showMobileMenu && (
            <div className="min-w-4 lg:hidden">
              <MenuSquareIcon className="block h-6 w-6" aria-hidden="true" onClick={() => setShowMobileMenu(true)} />
            </div>
          )}
        </header>
      )}

      {showBack && backButtonLabel ? (
        <NavigationBackButton
          label={backButtonLabel}
          backButtonLink={backButtonLink}
          className="lg:hidden"
          isCollapsed={isCollapsed}
        />
      ) : null}

      {!isDesktop ? (
        <Transition.Root show={showMobileMenu} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={onCloseMobileMenu}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-neutral-dark-0/40" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enterFrom={isRecord ? "-translate-x-full" : "translate-x-full"}
              enterTo={isRecord ? "-translate-x-0" : "translate-x-0"}
              leave="transform transition ease-in-out duration-300 sm:duration-300"
              leaveFrom={isRecord ? "-translate-x-0" : "translate-x-0"}
              leaveTo={isRecord ? "-translate-x-full" : "translate-x-full"}
            >
              <Dialog.Panel
                className={clsx(
                  "bg-background border-separator fixed inset-y-0 z-10 w-64 overflow-y-auto border-l",
                  isRecord ? "left-0" : "right-0"
                )}
                aria-label="Global"
              >
                <NavigationItemsList
                  onSearchBoxClick={onSearchBoxClick}
                  onCloseMobileMenu={onCloseMobileMenu}
                  isRecord={isRecord}
                  searchBarEnabled={searchBarEnabled}
                  showMobileMenu={showMobileMenu}
                  isCollapsed={isCollapsed}
                  showBackButton={showBack}
                  backButtonLabel={backButtonLabel}
                  backButtonLink={backButtonLink}
                  isDesktop={isDesktop}
                  unreadCount={unreadCount}
                  onEditMenuItem={onEditMenuItem}
                  firstNavItems={firstNavItems}
                  adminTablePageId={adminTablePageId}
                  navigationMenuItems={navigationMenuItems}
                  showAdminMenu={showAdminMenu}
                  onCreateNewPage={createNewPage}
                  countByNavItem={countByNavItem}
                />
              </Dialog.Panel>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      ) : (
        <NavigationItemsList
          onSearchBoxClick={onSearchBoxClick}
          onCloseMobileMenu={onCloseMobileMenu}
          isRecord={isRecord}
          searchBarEnabled={searchBarEnabled}
          showMobileMenu={showMobileMenu}
          isCollapsed={isCollapsed}
          showBackButton={showBack}
          backButtonLabel={backButtonLabel}
          backButtonLink={backButtonLink}
          isDesktop={isDesktop}
          unreadCount={unreadCount}
          onEditMenuItem={onEditMenuItem}
          firstNavItems={firstNavItems}
          adminTablePageId={adminTablePageId}
          navigationMenuItems={navigationMenuItems}
          showAdminMenu={showAdminMenu}
          onCreateNewPage={createNewPage}
          countByNavItem={countByNavItem}
        />
      )}

      {searchBarEnabled && <SearchContainer open={openSearch} setOpen={setOpenSearch} />}
    </section>
  );
};

export default NavigationLeft;
