"use client";

import { useMemo, useState } from "react";
import dynamic from "next/dynamic";
import { XIcon } from "lucide-react";

import IconButton from "components/IconButton";
import MapStreetView from "components/MapStreetView";
import Tabs from "components/Tabs";
import useTableActionsState from "hooks/useTableActionsState";
import { formatAddress } from "utils/format";

const Map = dynamic(() => import("components/Map"), { ssr: false });
const CenterModal = dynamic(() => import("components/DetailViewSidebarModal/CenterModal"), { ssr: false });

const MapModal = () => {
  const { mapState, updateMapState } = useTableActionsState();
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = useMemo(() => {
    if (!mapState?.isOpen) return [];
    const tabList = [
      {
        id: "view",
        label: "Map View",
        content: <Map items={mapState.items} />
      }
    ];

    const longitude = mapState.items?.[0]?.longitude;
    const latitude = mapState.items?.[0]?.latitude;

    if (longitude && latitude) {
      tabList.unshift({
        id: "street",
        label: "Street View",
        content: <MapStreetView latitude={latitude} longitude={longitude} />
      });
    }

    return tabList;
  }, [mapState.items, mapState.isOpen]);

  const handleClose = () => {
    updateMapState({ isOpen: false });
  };

  return (
    <CenterModal isOpen={!!mapState.isOpen} onClose={handleClose} className="!bg-background !left-0 !z-50">
      <div className="bg-background h-screen lg:h-full">
        {mapState.address ? (
          <h1 className="text-primary px-8 pt-8 text-xl font-semibold">{formatAddress(mapState.address)}</h1>
        ) : null}

        <IconButton icon={XIcon} onClick={handleClose} className="!absolute right-3 top-3" />

        <Tabs
          tabs={tabs}
          className="h-[calc(100vh_-_98px)] "
          classNamePanel="h-[calc(100vh_-_160px)] w-full"
          classNameHeader="mx-8"
          selectedTab={selectedTab}
          onChange={setSelectedTab}
        />
      </div>
    </CenterModal>
  );
};

export default MapModal;
