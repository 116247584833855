"use client";

import { useMemo } from "react";
import dynamic from "next/dynamic";

import usePageByPath from "hooks/usePageByPath";
import useRecordTypes from "hooks/useRecordTypes";
import useSchemaState from "hooks/useSchemaState";
import useTableActionsState from "hooks/useTableActionsState";
import useTableDataById from "hooks/useTableDataById";
import { getMultiTitleText } from "lib/utils";
import { Page, TableColumnType } from "types/baTypes";
import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import { ViewOption } from "utils/constants";
import { generateFinalDataColumns } from "utils/dataUtils";

const DetailViewSidebarModalUI = dynamic(() => import("components/DetailViewSidebarModal/DetailViewSidebarModalUI"), {
  ssr: false
});

const SingleFileView = () => {
  const { singleFileViewState, updateSingleFileViewState } = useTableActionsState();
  const { schemaInstance } = useSchemaState();
  const { data: recordTypesData } = useRecordTypes();

  // ##HARDCODED
  const { data: pageData } = usePageByPath(
    {
      slug: "files"
    },
    {
      enabled: !!singleFileViewState.id
    }
  );

  const finalColumnsForData = useMemo(() => {
    const updatedFinalColumns = pageData?.columns
      ?.map((col) =>
        appendRecordTypeLookupToCol(col, recordTypesData || [], pageData?.table_name, schemaInstance?.extendedSchema)
      )
      .filter(Boolean) as TableColumnType[];
    return generateFinalDataColumns({
      columns: updatedFinalColumns,
      recordTypesData,
      tableName: pageData?.table_name || "",
      extendedSchema: schemaInstance?.extendedSchema
    });
  }, [pageData?.columns, recordTypesData, pageData?.table_name, schemaInstance?.extendedSchema]);

  // ##HARDCODED
  const { data: recordData } = useTableDataById(
    {
      tableName: "files",
      id: singleFileViewState.id as string,
      slug: "files",
      columns: finalColumnsForData,
      source: "Single File View"
    },
    {
      enabled: !!singleFileViewState.id && !!pageData?.columns?.length
    }
  );

  const title = useMemo(() => {
    if (!pageData || !recordData) {
      return "";
    }
    const detailConfig = pageData?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN);
    return getMultiTitleText({
      config: detailConfig,
      columns: pageData.columns || [],
      recordData,
      extendedSchema: schemaInstance?.extendedSchema
    });
  }, [pageData, recordData, schemaInstance?.extendedSchema]);

  const additionalConfigForMultiTitle = useMemo(() => {
    if (!pageData?.id || !recordData?.id) return;
    const detailConfig = pageData?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN);
    return {
      config: detailConfig,
      columns: finalColumnsForData,
      recordData,
      extendedSchema: schemaInstance?.extendedSchema
    };
  }, [pageData, finalColumnsForData, recordData, schemaInstance?.extendedSchema]);

  const handleClose = () => {
    updateSingleFileViewState({ isOpen: false, id: undefined, src: undefined });
  };

  return (
    <DetailViewSidebarModalUI
      isOpen={singleFileViewState.isOpen}
      onClose={handleClose}
      srcFile={singleFileViewState.src as string}
      fileId={singleFileViewState.id}
      fileType={recordData?.file_type}
      fileName={singleFileViewState.id ? recordData?.name : singleFileViewState.name}
      mimetype={recordData?.mimetype}
      fileDimensions={
        recordData?.width && recordData?.height ? `${recordData.width} X ${recordData.height}` : undefined
      }
      hideNavigation={true}
      hideSidebar={true}
      isExpanded={false}
      recordData={recordData}
      pageData={pageData as Page}
      title={title}
      additionalConfigForMultiTitle={additionalConfigForMultiTitle}
    />
  );
};

export default SingleFileView;
