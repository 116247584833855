import mimeType from "mime-types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "components/Button";
import Modal from "components/Modal";
import TextField from "components/TextField";
import { ButtonAppearance } from "utils/constants";
import toast from "utils/toast";

// Source: https://stackoverflow.com/a/8234912/2013580
const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
);

const FileUrlImportModal = ({
  open,
  onOpenChange,
  onFileImport
}: {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  onFileImport: (files: File[]) => void;
}) => {
  const [url, setUrl] = useState("");
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setUrl("");
    setLoading(false);
    onOpenChange(false);
  };

  const isValidUrl = !!url && urlRegExp.test(url);

  const handleFileImport = async () => {
    if (!isValidUrl) return;
    setLoading(true);
    try {
      const fileBlob = await fetch(url).then((res) => res.blob());
      const fileUrl = new URL(url);
      const fileNameStr = fileUrl.pathname.split("/").pop() || `image-${uuidv4()}`;
      // split name from extension
      const fileExtension = mimeType.extension(fileBlob.type);
      const fileName = `${fileNameStr.split(".")[0]}${fileExtension ? `.${fileExtension}` : ""}`;

      const fileData = new File([fileBlob], fileName, { type: fileBlob.type });
      onFileImport([fileData]);
      handleClose();
    } catch (err) {
      toast.error("Failed to import file from URL", { autoClose: 2000 });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (inputRef && open) {
      inputRef?.focus();
    }
  }, [open, inputRef]);
  return (
    <Modal
      showClose={false}
      closeOnOutsideClick={false}
      isOpen={open}
      title="Import File from URL"
      onClose={handleClose}
    >
      <div className="space-y-4 p-4">
        <TextField
          ref={setInputRef}
          inputClassName="!ring-base-primary"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          label="Enter Url"
        />
        <div className="flex justify-end gap-3">
          <Button disabled={loading} label="Cancel" appearance={ButtonAppearance.NEUTRAL} onClick={handleClose} />
          <Button disabled={!isValidUrl} label="Import" isLoading={loading} onClick={handleFileImport} />
        </div>
      </div>
    </Modal>
  );
};

export default FileUrlImportModal;
