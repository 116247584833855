import { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";

import omit from "lodash/omit";
import CellGroup from "components/CellGroup";
import Button from "components/Button";
import InputCellGroup from "components/CellGroup/CellGroupHandler/InputCellGroup";
import usePageDataById from "hooks/usePageDataById";
import { SelectOption, RecordItem } from "types/common";
import { ButtonAppearance, CellType, PAGE_BULKACTION_PAYLOAD_TYPE } from "utils/constants";
import { getTimeStamp } from "utils/columnUtils";
import useNotificationsState from "hooks/useNotificationsState";
import { NOTIFICATION_TYPE, NotificationRecord } from "components/Notifications/utils";

const WebhookForm = ({
  pageId,
  columnId,
  webhookURL,
  webhookProps,
  type
}: {
  type: PAGE_BULKACTION_PAYLOAD_TYPE;
  pageId?: string;
  columnId?: string;
  webhookURL: string;
  webhookProps: RecordItem;
}) => {
  const { addPendingNotification } = useNotificationsState();
  const [selectedValue, setSelectedValue] = useState<SelectOption | string>();
  const { data: pageData } = usePageDataById(pageId, {
    enabled: !!pageId,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const column = useMemo(() => {
    return pageData?.columns?.find((col) => col.id === columnId);
  }, [pageData, columnId]);

  const handleSubmit = useCallback(() => {
    const notificationId = v4();
    const timestamp = getTimeStamp();
    fetch(webhookURL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...webhookProps,
        timestamp,
        action_props: {
          id: notificationId,
          ...webhookProps.action_props,
          addPayloadPage: pageData?.path,
          addPayload: selectedValue
        }
      })
    });
    addPendingNotification({
      id: notificationId,
      ...omit(webhookProps, "actionId"),
      action_props: {
        id: notificationId,
        ...webhookProps.action_props
      },
      type: NOTIFICATION_TYPE.ACTION
    } as NotificationRecord);
  }, [addPendingNotification, webhookProps, webhookURL, pageData, selectedValue]);

  return (
    <div className="flex flex-col p-5">
      {type === PAGE_BULKACTION_PAYLOAD_TYPE.PAGE_COLUMN && column ? (
        <CellGroup
          label={column.header}
          type={column.type ? (column.type as CellType) : CellType.SELECT}
          column={column}
          value={selectedValue}
          onEdit={(values) => {
            setSelectedValue(values as SelectOption);
          }}
          isLoading={false}
          className="mt-4"
          isEditable
          inForm
        />
      ) : (
        <InputCellGroup
          type={CellType.TEXT}
          value={selectedValue as string}
          onEdit={(values) => {
            setSelectedValue(values as string);
          }}
          maskOptions={{}}
          inForm
        />
      )}
      <Button
        label="Submit"
        appearance={ButtonAppearance.PRIMARY}
        className="mt-4 h-12 w-full px-4 py-6"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default WebhookForm;
