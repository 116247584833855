import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import Link from "next/link";
import dynamic from "next/dynamic";
import { BellDotIcon, CheckCheckIcon, ExternalLinkIcon } from "lucide-react";

import Avatar from "components/Avatar";
import Breadcrumb from "components/Breadcrumb";
import { NotificationRecord } from "components/Notifications/utils";
import useTableDataById from "hooks/useTableDataById";
import { AvatarSize, SidebarContainer } from "utils/constants";
import generateInitials from "utils/generateInitials";
import { formatDateTime } from "utils/format";
import Icon from "components/Icon";
import { Page, ViewAction } from "types/baTypes";
import ActionsHandler from "components/ActionsHandler";
import IconButton from "components/IconButton";
import { IconButtonColor, IconButtonSize } from "components/IconButton/utils";
import Tooltip from "components/Tooltip";
import useSearchQueryParams from "hooks/useSearchQueryParams";

const LexicalTextEditor = dynamic(() => import("components/LexicalTextEditor"), { ssr: false });

type NoteMentionNotificationItemProps = {
  item: NotificationRecord;
  onMarkRead: (notificationId: string, read?: boolean) => void;
  onClose: () => void;
  actions?: ViewAction[];
  onMarkDone: (notification: NotificationRecord) => void;
  notesPageData?: Page | null;
};

type NoteInfo =
  | {
      from_user: {
        id: string;
        name: string;
        avatar?: string;
      };
      markAsDone?: boolean;
    }
  | null
  | undefined;

const NoteMentionNotificationItem = ({
  item,
  onMarkRead,
  onClose,
  actions,
  onMarkDone,
  notesPageData,
  ...rest
}: NoteMentionNotificationItemProps) => {
  const noteInfo = item.action_props as NoteInfo;

  const { data: noteData, isLoading } = useTableDataById(
    {
      slug: "note_notification",
      id: item?.recordId || "",
      tableName: item.tableName,
      source: "Notification Center - Note mention"
    },
    {
      enabled: !!item?.recordId,
      refetchOnWindowFocus: false
    }
  );
  const { setParams } = useSearchQueryParams();

  const finalUrl = useMemo(
    () =>
      noteData?.source_url && noteData?.source_url?.includes(".com") && !noteData?.source_url?.includes("/r/")
        ? noteData.source_url.split(".com").join(".com/r")
        : noteData?.source_url,
    [noteData]
  );

  const breadcrumbItems = useMemo(() => {
    const breadcrumbs = [];

    if (noteData?.source_root_title) {
      breadcrumbs.push({ title: noteData?.source_root_title, link: noteData?.source_root_url });
    }
    if (noteData?.source_title) {
      breadcrumbs.push({ title: noteData?.source_title, link: finalUrl });
    }

    return breadcrumbs;
  }, [noteData, finalUrl]);

  const handleNotificationClick = () => {
    onMarkRead(item.id, true);
    onClose();
  };

  const handleOpenRecordInCollapsibleSidebar = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // e.stopPropagation();
      if (notesPageData?.id) {
        setParams({
          cellSide: {
            pageId: notesPageData?.id,
            recordId: item?.recordId as string,
            type: SidebarContainer.Collapsible,
            title: `${notesPageData?.title ? notesPageData?.title : "record"}-${item?.recordId}`
          }
        });
      }
    },
    [notesPageData?.id, setParams, notesPageData?.title, item?.recordId]
  );

  if (!noteInfo) return null;

  return (
    <div className="relative flex w-full" {...rest}>
      <div className="flex flex-col items-center gap-3">
        <Avatar
          size={AvatarSize.XS}
          src={noteInfo.from_user.avatar}
          initials={generateInitials(noteInfo.from_user.name)}
          isLoading={isLoading}
        />
        <div className="h-full w-0 border border-neutral-300 dark:border-neutral-dark-300" />
      </div>
      <div className="flex w-full flex-col gap-2 pb-4">
        <div className="flex flex-1 items-start justify-between">
          <div className="flex-1">
            {breadcrumbItems.length > 0 && (
              <Breadcrumb
                items={breadcrumbItems}
                className="mx-3 text-2xs"
                disabledLastLink={false}
                onLinkClick={onClose}
                enableTextWrap
              />
            )}
          </div>
          <div className="flex items-center gap-2 py-1">
            {!noteInfo?.markAsDone && (
              <Tooltip title="Mark as Done">
                <IconButton
                  icon={CheckCheckIcon}
                  size={IconButtonSize.XS}
                  color={IconButtonColor.TRANSPARENT}
                  onClick={() => onMarkDone(item)}
                />
              </Tooltip>
            )}
            {!item.read ? (
              <Tooltip title="Mark as read">
                <div
                  className="h-3 w-3 cursor-pointer rounded-full bg-red-700 dark:bg-red-dark-700"
                  onClick={() => {
                    onMarkRead(item.id, true);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Mark as unread">
                <IconButton
                  icon={BellDotIcon}
                  size={IconButtonSize.XS}
                  color={IconButtonColor.TRANSPARENT}
                  onClick={() => onMarkRead(item.id, false)}
                />
              </Tooltip>
            )}
            {!!actions?.length && (
              <ActionsHandler
                tablePath={item.action_props?.tablePath || ""}
                actions={actions}
                record={noteData}
                tableName={item.tableName as string}
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 pl-3">
          <p className="flex-1 text-sm font-normal text-base-secondary dark:text-base-dark-secondary">
            <span className="font-semibold">{noteInfo.from_user.name}</span>
            {item.message?.replace(noteInfo.from_user.name, "")}
          </p>
          <span className="text-xs text-base-disabled dark:text-base-dark-disabled">
            {formatDateTime(dayjs(item.createdAt).toString())}
          </span>
        </div>
        <Link
          href={finalUrl || ""}
          className="group relative ml-3 flex cursor-pointer gap-3 rounded-lg border border-neutral-300 p-4 text-base-disabled hover:bg-neutral-100 active:bg-neutral-200 dark:border-neutral-dark-300 dark:text-base-dark-disabled dark:hover:bg-neutral-dark-100 dark:active:bg-neutral-dark-200"
          onClick={handleNotificationClick}
        >
          <Icon name="sticky-note" className="m-1 h-4 w-4" />
          <div className="flex w-full flex-col gap-2 overflow-hidden">
            {noteData?.name && (
              <p className="text-sm font-semibold text-base-primary dark:text-base-dark-primary">{noteData.name}</p>
            )}
            {noteData?.message && (
              <LexicalTextEditor
                isEditable={false}
                initialValue={noteData.message}
                hideControls
                className="!min-h-min !border-transparent"
                editorClassName="!pl-0 !py-0 line-clamp-2 !min-h-min"
              />
            )}
          </div>
          <Tooltip title="Open in Sidebar">
            <IconButton
              title="Open in Sidebar"
              icon={ExternalLinkIcon}
              onClick={handleOpenRecordInCollapsibleSidebar}
              className="!absolute right-2 top-2 hidden group-hover:flex"
              size={IconButtonSize.XS}
              color={IconButtonColor.TRANSPARENT}
            />
          </Tooltip>
        </Link>
      </div>
    </div>
  );
};

export default NoteMentionNotificationItem;
