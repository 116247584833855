"use client";
import { useQuery } from "@tanstack/react-query";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getSearchTables } from "lib/adminApi";
import { QueryHookOptions } from "types/common";
import useCurrentUser from "./useCurrentUser";

const useSearchTables = (options?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["algolia_search_tables", currentUser?.org_id],
    queryFn: () => getSearchTables(supabaseClient, currentUser?.org_id),
    refetchOnMount: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...options
  });

  return {
    data: data?.data || [],
    isLoading,
    refetch
  };
};

export default useSearchTables;
