import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getPageByPath } from "lib/adminApi";
import { USER_TYPE } from "utils/constants";
import { PageAction } from "types/baTypes";
import useCurrentUser from "./useCurrentUser";

const useNotificationConfig = () => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data, isLoading } = useQuery({
    queryKey: ["notifications_config", currentUser?.org_id],
    queryFn: () => getPageByPath(supabaseClient, "/notifications", USER_TYPE.STAFF, "", currentUser?.org_id),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  const notificationActions: PageAction[] = useMemo(() => {
    if (!data?.data?.ui_pages_actions?.length) return [];
    return data.data.ui_pages_actions?.map((action) => ({ ...action, viewId: action.viewId })) as PageAction[];
  }, [data?.data]);

  return {
    isLoading,
    actions: notificationActions
  };
};

export default useNotificationConfig;
