import { Tab } from "@headlessui/react";
import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";

import noop from "lodash/noop";
import Icon from "components/Icon";
import { TabSize } from "./utils";

export type Tab = {
  id: string;
  label: string;
  icon?: string;
  content: React.ReactNode;
  path?: string;
  recordCount?: React.ReactNode; // this should return an element rendering the recordCount
};

type TabsProps = {
  tabs: Tab[];
  size?: TabSize;
  className?: string;
  classNameHeader?: string;
  classNamePanel?: string;
  selectedTab?: number;
  onChange?: (tabIndex: number) => void;
  isControlled?: boolean;
};

const Tabs = ({
  tabs = [],
  className = "",
  classNameHeader = "",
  classNamePanel = "",
  size = TabSize.MD,
  selectedTab = 0,
  onChange = noop,
  isControlled = false,
  ...rest
}: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(() => {
    return selectedTab;
  });

  useEffect(() => {
    if (selectedIndex !== selectedTab) {
      setSelectedIndex(selectedTab);
    }
  }, [selectedTab, selectedIndex]);

  const handleSelectTab = (tabIndex: number) => {
    onChange(tabIndex);
  };

  return (
    <div data-testid="Tabs" className={clsx("dark:bg-midnight text-primary w-full", className)} {...rest}>
      <Tab.Group selectedIndex={isControlled ? selectedTab : selectedIndex} onChange={(e) => handleSelectTab(e)}>
        <Tab.List
          className={clsx(
            " flex space-x-6 border-b border-b-neutral-200 dark:border-b-neutral-dark-200",
            classNameHeader
          )}
        >
          {tabs.map((tab: Tab) => (
            <Tab
              key={tab.id}
              className={({ selected }) =>
                clsx(
                  "-mb-[1px] scale-100 border-b-4 border-transparent text-sm font-medium transition-transform active:scale-[0.98]",
                  selected
                    ? "border-b-3 text-primary border-b-primary-700 font-medium dark:border-b-primary-dark-700"
                    : "text-base-disabled dark:text-base-dark-disabled",
                  size === TabSize.MD && "py-2"
                )
              }
            >
              <div
                className={clsx(
                  "flex items-center rounded-md p-1.5 hover:bg-neutral-200 dark:hover:bg-neutral-dark-200"
                )}
              >
                {tab.icon && (
                  <Icon name={tab.icon as any} className="h-4 w-4 text-base-disabled dark:text-base-dark-disabled" />
                )}
                <div className="hover:bg-neutral-50 dark:hover:bg-neutral-700 rounded-md px-1.5">{tab.label}</div>
                {tab.recordCount}
              </div>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels as={React.Fragment}>
          {tabs.map((tab: Tab) => (
            <Tab.Panel key={tab.id} className={clsx("dark:text-neutral-400", classNamePanel)}>
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;
