import { useHover } from "usehooks-ts";
import { useMemo, useRef } from "react";
import { DropdownMenuItem } from "components/ui/dropdownMenu";
import { NavigationItem } from "types/common";
import IconComp from "components/Icon";
import useFormNavItem from "hooks/useFormNavItem";
import MenuActions from "components/NavigationItem/MenuActions";
import useCurrentUser from "hooks/useCurrentUser";

const CreateActionsMenuItem = ({
  item,
  recordId,
  recordPageId
}: {
  item: NavigationItem;
  recordPageId?: string;
  recordId?: string;
}) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const currentUser = useCurrentUser();
  const { onFormItemClick } = useFormNavItem(
    { formViewId: item.additionalConfig?.createFormViewId, pageId: item.ui_page_id?.id, recordId, recordPageId, item },
    { enabled: true }
  );

  const menuActions = useMemo(() => {
    if (!item.id || !item.actions?.length || !currentUser?.is_admin) return [];

    const finalActions = (item.actions || []).map((menuItm) => ({ ...menuItm, tableName: item.tableName }));

    return finalActions;
  }, [currentUser?.is_admin, item.actions, item.id, item.tableName]);

  return (
    <DropdownMenuItem
      ref={hoverRef}
      className="navtext cursor-pointer gap-2 rounded-md fill-gray-600 px-2 text-[15px]  leading-5  hover:text-gray-800 dark:fill-gray-300 dark:text-gray-400 dark:hover:text-gray-200"
      onClick={() => {
        item.onClick?.();
        onFormItemClick();
      }}
    >
      <div className="flex flex-1 items-center gap-2">
        {item.icon && (
          <div className="h-4 w-4 !shrink-0">
            <IconComp name={item.icon as any} className="tpicon h-4 w-4" />
          </div>
        )}
        <div className="tpnavitem whitespace-nowrap">{item.name}</div>
      </div>
      <MenuActions actions={menuActions} isHovered={isHovered} item={item} recordPageId={recordPageId} />
    </DropdownMenuItem>
  );
};

export default CreateActionsMenuItem;
