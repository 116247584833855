import React from "react";
import clsx from "clsx";

export enum TabSize {
  SM = "sm",
  MD = "md",
}

export const mockTabs = [
  {
    id: "tab-1",
    label: "First",
    content: <h1 className={clsx("mt-6 text-primary-600 dark:text-neutral-400")}>First Content</h1>,
  },
  {
    id: "tab-2",
    label: "Second",
    content: <h1 className={clsx("mt-6 text-primary-600 dark:text-neutral-400")}>Second Content</h1>,
  },
  {
    id: "tab-3",
    label: "Third",
    content: <h1 className={clsx("mt-6 text-primary-600 dark:text-neutral-400")}>Third Content</h1>,
  },
  {
    id: "tab-4",
    label: "Fourth",
    content: <h1 className={clsx("mt-6 text-primary-600 dark:text-neutral-400")}>Fourth Content</h1>,
  },
  {
    id: "tab-5",
    label: "Fifth",
    content: <h1 className={clsx("mt-6 text-primary-600 dark:text-neutral-400")}>Fifth Content</h1>,
  },
  {
    id: "tab-6",
    label: "Sixth",
    content: (
      <div className={clsx("mt-6 text-primary-600 dark:text-neutral-400")}>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga quam quisquam sed quidem ab culpa accusamus ea
          eaque velit dolore consectetur dolores ut veniam incidunt cumque magni, laborum, assumenda voluptatum.
        </p>
      </div>
    ),
  },
];
