import { useQuery } from "@tanstack/react-query";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getUserNotifications } from "lib/supabaseApi";
import { QueryHookOptions } from "types/common";
import useCurrentUser from "./useCurrentUser";

/**
 * This hook is used to read user notifications;
 */
const useUserNotifications = (options?: QueryHookOptions) => {
  const currentUser = useCurrentUser();
  const supabaseClient = useSupabaseBrowser();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["user_notifications", currentUser?.org_id],
    queryFn: () => getUserNotifications(supabaseClient, currentUser?.user_id),
    enabled: !!currentUser?.user_id,
    refetchOnMount: true,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    ...options
  });

  return {
    data: data?.data || [],
    isLoading,
    refetch
  };
};

export default useUserNotifications;
