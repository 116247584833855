const ErrorDisplay = ({ resetErrorBoundary }: { resetErrorBoundary: () => void }) => {
  return (
    <div className="px-6 py-5">
      <span>Something went wrong! Please refresh the page or contact us.</span>
      <button onClick={resetErrorBoundary}>Reset page</button>
    </div>
  );
};

export default ErrorDisplay;
