import clsx from "clsx";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import IconButton from "components/IconButton";
import { IconButtonColor } from "components/IconButton/utils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "components/ui/dropdownMenu";
import { NavigationItem } from "types/common";
import CreateActionsMenuItem from "./CreateActionsMenuItem";

const CreateActionMenu = ({
  menuItems,
  recordPageId,
  recordId
}: {
  menuItems: NavigationItem[];
  recordPageId?: string;
  recordId?: string;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  if (!menuItems.length) return null;

  return (
    <DropdownMenu onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          color={IconButtonColor.TRANSPARENT}
          icon={PlusIcon}
          classNameIcon={clsx("duration-300", menuOpen ? "rotate-45" : "rotate-0")}
          className={"h-4 w-4"}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="border-separator w-72 rounded-xl border p-2" align="end">
        {menuItems.map((item) => (
          <CreateActionsMenuItem item={item} key={item.id} recordId={recordId} recordPageId={recordPageId} />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CreateActionMenu;
