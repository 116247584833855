"use client";
import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";

import useCustomViewById from "hooks/useCustomViewById";
import useTransformFormData from "hooks/useTransformFormData";
import useTableActionsState from "hooks/useTableActionsState";
import useCurrentUser from "hooks/useCurrentUser";
import useNotificationsState from "hooks/useNotificationsState";
import useFormFieldsSubmission from "hooks/useFormFieldsSubmission";
import useAddRecord from "hooks/useAddRecord";
import usePageDataById from "hooks/usePageDataById";

import { RecordItem } from "types/common";
import {
  AdditionalFormActions,
  CrudActions,
  FORM_DEFAULT_VALUE_TYPE,
  LookupTypes,
  SPECIAL_DEFAULTS
} from "utils/constants";
import toast, { ToastActionType } from "utils/toast";
import { NOTIFICATION_ACTION, NOTIFICATION_TYPE } from "components/Notifications/utils";

import FormUI from "./FormUI";

type CustomFormProps = {
  formViewId: string;
  action: CrudActions | AdditionalFormActions;
  record?: any;
  onActionSuccess?: (
    newRecord?: RecordItem,
    options?: {
      loadSidebarOnSubmission: boolean;
      loadRecordOnSubmission: boolean;
      closeSidebar: boolean;
    }
  ) => void;
  hideTitle?: boolean;
  className?: string;
  classNameContent?: string;
  classNameEditAdminBtn?: string;
  onValueChange?: (values: RecordItem) => void;
  formSubType?: string;
};

const CustomForm = ({ formViewId, action, onActionSuccess, record }: CustomFormProps) => {
  const {
    data: viewData,
    isLoading: viewDataLoading,
    hasDifferentTables,
    pagesInColumns,
    isStaticCustomForm
  } = useCustomViewById(formViewId);
  const currentUser = useCurrentUser();
  const { addPendingNotification } = useNotificationsState();
  const { addRecordAsync } = useAddRecord();

  const [pageIdToFetch, setPageIdToFetch] = useState<string>();

  const { currentProjectId } = useTableActionsState();

  const { data: pageData, isLoading: pageLoading } = usePageDataById(pageIdToFetch, {
    enabled: !!pageIdToFetch,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const {
    formColumns,
    recordData,
    joinTableRelationInfo,
    loading,
    relationFormColumnPagesMap,
    fileCol,
    isJoinTable,
    formFields,
    currentFocus,
    formFieldsArray,
    formRequiredErrors,
    sections
  } = useTransformFormData({
    columns: viewData?.viewColumns,
    tablePath: "",
    action: CrudActions.CREATE,
    isCustomView: true,
    customViewSections: viewData?.sections
  });

  const { onFormSubmitWithOptions } = useFormFieldsSubmission({
    tableName: pageData?.table_name || "",
    formColumns: formColumns || [],
    slug: (pageData?.path || "").replace("/", ""),
    joinTableRelationInfo,
    alternateSchema: pageData?.page_config?.alternateSchema,
    source: "CustomForm",
    formViewId
  });

  const submitDataToWebhook = useCallback(
    async (formData: RecordItem, newRecord?: RecordItem) => {
      if (!viewData?.additionalConfig?.formSubmitWebhook?.url) {
        return;
      }

      const dataToWebhook: RecordItem = {
        formData,
        parentRecord: record,
        pages: pagesInColumns,
        formName: viewData?.title || ""
      };

      if (viewData?.additionalConfig?.byPassDbInsert) {
        dataToWebhook["additionalData"] = {
          user: currentUser?.id,
          project: currentProjectId,
          path: pageData?.path,
          triggeredFromPath: window.location.pathname
        };
      }
      // Add custom default values
      if (viewData?.additionalConfig?.hasDefaultValues) {
        const defaultData: RecordItem = {};
        viewData?.additionalConfig?.defaultValues?.forEach((defaultValue) => {
          if (defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.CUSTOM) {
            defaultData[defaultValue?.customField || ""] = defaultValue?.value;
          } else if (
            (defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN ||
              defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN) &&
            defaultValue?.columnId
          ) {
            const defaultCol = viewData?.viewColumns?.find((col) => col.id === defaultValue?.columnId);
            if (defaultCol) {
              if (defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN) {
                defaultData[defaultCol?.name || ""] = defaultValue?.value;
              } else if (defaultCol.lookupPath?.["0"]?.lookupType === LookupTypes.FOREIGN) {
                defaultData[defaultCol.lookupPath?.["0"]?.lookupForeignKey || ""] = defaultValue?.value;
              } else if (Object.keys(defaultValue?.value || {})?.length) {
                Object.keys(defaultValue?.value || {}).forEach((colKey: string) => {
                  defaultData[colKey] = (defaultValue?.value as RecordItem)?.[colKey];
                });
              }
            }
          }
        });

        dataToWebhook.additionalData = {
          ...dataToWebhook.additionalData,
          ...defaultData
        };
      }

      const notificationId = v4();

      if (viewData?.additionalConfig?.waitForSuccess) {
        dataToWebhook["action_props"] = {
          id: notificationId,
          pageTable: pageData?.table_name,
          currentTablePage: pageData?.path,
          projectId: currentProjectId,
          action: NOTIFICATION_ACTION.ACTION_SUCCESS
        };
      }

      fetch(viewData.additionalConfig.formSubmitWebhook.url, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToWebhook)
      });

      if (viewData?.additionalConfig?.waitForSuccess) {
        addPendingNotification({
          id: notificationId,
          recordId: "",
          pageId: pageData?.id || "",
          tableName: pageData?.table_name || "",
          userId: currentUser?.user_id || "",
          action: "",
          action_props: {
            id: notificationId,
            currentTablePage: pageData?.path,
            hasWaitForSuccess: viewData?.additionalConfig?.waitForSuccess,
            newRecordFromSidebar: newRecord
          },
          type: NOTIFICATION_TYPE.INFO
        });
      }
    },
    [
      addPendingNotification,
      currentProjectId,
      currentUser?.id,
      currentUser?.user_id,
      viewData,
      record,
      pagesInColumns,
      pageData
    ]
  );

  const onSubmit = useCallback(
    async (data: Record<string, any>, event: any, closeSidebar = true) => {
      const dataByColName: RecordItem = {};
      const dataByColHeader: RecordItem = {}; // using this to send data to webhook when using static customForm

      const finalData = Object.keys(data).reduce((acc: RecordItem, colId: string) => {
        const col = formFields[colId];
        if (col.column?.isLookup) {
          acc[colId] = data[colId]?.inputValue;
          const { lookupPath } = col.column;
          const colKey = `${
            lookupPath?.["0"]?.lookupColumnLabel ? lookupPath?.["0"]?.lookupColumnLabel + "." : ""
          }${lookupPath?.["0"]?.lookupTableName}`;
          dataByColName[colKey] = data[colId]?.inputValue;
          if (isStaticCustomForm && col.column?.header) {
            dataByColHeader[col.column.header] = data[colId]?.inputValue;
          }
        } else if (col.column?.name || isStaticCustomForm) {
          acc[colId] = data[colId];
          if (isStaticCustomForm && col.column?.header) {
            dataByColHeader[col.column.header] = data[colId];
          }
          if (col.column?.name) {
            dataByColName[col.column.name] = data[colId];
          }
          if (data[colId] === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID && currentProjectId) {
            if (isStaticCustomForm && col.column?.header) {
              dataByColHeader[col.column.header] = currentProjectId;
            }
            if (col.column?.name) {
              dataByColName[col.column.name] = currentProjectId;
            }
          }
        }
        return acc;
      }, {});

      // When we only call webhook and not insert in db
      if (viewData?.additionalConfig?.byPassDbInsert) {
        submitDataToWebhook(isStaticCustomForm ? dataByColHeader : dataByColName);
        onActionSuccess?.(
          {},
          {
            loadSidebarOnSubmission: false,
            loadRecordOnSubmission: false,
            closeSidebar: true
          }
        );
        return;
      }
      if (!pageData) return;

      const newRecord = await onFormSubmitWithOptions(finalData, {
        createdIn: "form",
        createdInPath: pageData?.path,
        record,
        inCustomView: true
      });

      if (!!newRecord) {
        toast.success("Record saved successfully", {
          actions: [
            {
              link: `/r/${pageData?.path?.replace("/", "")}/${newRecord.id}`,
              type: ToastActionType.LINK
            }
          ]
        });
        onActionSuccess?.(
          {},
          {
            loadSidebarOnSubmission: false,
            loadRecordOnSubmission: false,
            closeSidebar: true
          }
        );
        if (viewData?.additionalConfig?.formSubmitWebhook?.url) {
          submitDataToWebhook(dataByColName, newRecord);
        }
        if (pageData?.table_name) {
          // Add record in user_notifications to refetch table for all users
          await addRecordAsync({
            tableName: "user_notifications",
            input: {
              type: NOTIFICATION_TYPE.SYSTEM,
              action: NOTIFICATION_ACTION.TABLE_RESET,
              tablename: pageData?.table_name,
              record_id: newRecord?.id
            }
          });
        }
      } else {
        toast.error("Error saving record");
      }
    },
    [
      currentProjectId,
      formFields,
      onActionSuccess,
      submitDataToWebhook,
      viewData?.additionalConfig?.byPassDbInsert,
      addRecordAsync,
      onFormSubmitWithOptions,
      pageData,
      viewData?.additionalConfig?.formSubmitWebhook?.url,
      isStaticCustomForm,
      record
    ]
  );

  useEffect(() => {
    if (!viewData?.viewColumns?.length || hasDifferentTables) {
      return;
    }
    const pageIdFromCol = viewData?.viewColumns?.[0]?.pageId;
    if (pageIdFromCol && pageIdFromCol !== pageIdToFetch) {
      setPageIdToFetch(pageIdFromCol);
    }
  }, [hasDifferentTables, viewData, pageIdToFetch]);

  return (
    <FormUI
      onSubmit={onSubmit}
      formFieldsArray={formFieldsArray || []}
      finalTitle={viewData?.title || ""}
      formRequiredErrors={formRequiredErrors}
      relationFormColumnPagesMap={relationFormColumnPagesMap}
      fileCol={fileCol}
      isLoading={viewDataLoading}
      isJoinTable={isJoinTable}
      action={action}
      sections={sections}
      currentFocus={currentFocus}
      showCollapse={!viewData?.additionalConfig?.hideCollapseSection}
    />
  );
};

export default CustomForm;
