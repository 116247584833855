import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  NotificationRecord,
  NOTIFICATION_TYPE,
  transformNotificationRecordToNotificationType,
  VISIBLE_NOTIFICATION_TYPES
} from "components/Notifications/utils";
import toast from "utils/toast";
import { TOAST_TYPE } from "utils/constants";
import useNotificationsState from "./useNotificationsState";
import useUpdateRecord from "./useUpdateRecord";
import useUpsertRecord from "./useUpsertRecord";
import useAddRecord from "./useAddRecord";

export type SortFn = (l: NotificationRecord, r: NotificationRecord) => number;

export type FilterFn = (item: NotificationRecord) => boolean;

export interface UseNotificationCenterParam {
  sort?: SortFn;
  filter?: FilterFn;
}

// newest to oldest
function defaultSort(l: NotificationRecord, r: NotificationRecord) {
  return dayjs(r.createdAt).valueOf() - dayjs(l.createdAt).valueOf();
}

// filter action notification as we don't want to  show action notification in notification center
function defaultFilter(item: NotificationRecord) {
  return item.type !== NOTIFICATION_TYPE.ACTION;
}
/**
 * hook to manage notifications
 * 1. mark as read
 * 2. mark all as read
 * 3. notifications
 * 4. unreadCount
 * 5. sort
 * 6. clear notifications
 * 7. send notification
 */
const useNotificationCenter = (params: UseNotificationCenterParam = {}) => {
  const sortFn = useRef(params.sort || defaultSort);
  const filterFn = useRef(params.filter || defaultFilter);
  const { notificationsList, updateNotification, removePendingNotification } = useNotificationsState();
  const { updateRecordAsync } = useUpdateRecord();
  const { upsertRecordAsync } = useUpsertRecord();
  const { addRecordAsync } = useAddRecord();

  const getFinalNotificationItems = (notifications: NotificationRecord[]) => {
    return filterFn.current
      ? notifications.filter(filterFn.current).sort(sortFn.current)
      : [...notifications].sort(sortFn.current);
  };
  const [notificationCenterItems, setNotificationCenterItems] = useState<NotificationRecord[]>([]);

  useEffect(() => {
    setNotificationCenterItems(getFinalNotificationItems(notificationsList));
  }, [notificationsList]);

  const sort = useCallback(
    (compareFn: SortFn) => {
      sortFn.current = compareFn;
      getFinalNotificationItems(notificationsList);
    },
    [notificationsList]
  );

  const markRead = useCallback(
    async (notificationId: string, read = true) => {
      try {
        const { error } = await updateRecordAsync({
          tableName: "user_notifications",
          input: { id: notificationId, read: read }
        });
        if (error) {
          console.log(`Failed to mark notification ${notificationId} as ${read ? "read" : "undread"}: ${error}`);
          return;
        }
        updateNotification(notificationId, {
          read: read
        });
      } catch (err) {
        console.log(`Failed to mark notification ${notificationId} as ${read ? "read" : "undread"}: ${err as Error}`);
      }
    },
    [updateNotification, updateRecordAsync]
  );

  const markAllAsRead = useCallback(async () => {
    const input = notificationCenterItems.map((item) => ({ id: item.id, read: true }));
    if (!input.length) return;
    try {
      const { error } = await upsertRecordAsync({
        tableName: "user_notifications",
        input
      });
      if (error) {
        console.log(`Failed to mark all notifications as read, ${error}`);
        return;
      }

      input.map((item) => {
        updateNotification(item.id, item);
      });
    } catch (err) {
      console.log(`Failed to mark all notification as read: ${err as Error}`);
    }
  }, [updateNotification, upsertRecordAsync, notificationCenterItems]);

  const clearNotifications = useCallback(async () => {
    const input = notificationCenterItems.map((item) => ({ id: item.id, is_active: false, read: true }));
    if (!input.length) return;
    try {
      const { error } = await upsertRecordAsync({
        tableName: "user_notifications",
        input
      });
      if (error) {
        console.log(`Failed to clear notifications, ${error}`);
        return;
      }

      input.map((item) => {
        removePendingNotification(item.id);
      });
    } catch (err) {
      console.log(`Failed to clear notifications: ${err as Error}`);
    }
  }, [upsertRecordAsync, notificationCenterItems, removePendingNotification]);

  const sendNotification = useCallback(
    async (notification: Partial<NotificationRecord>, showToast = true) => {
      let toastId;
      if (showToast) {
        toastId = toast.success("Sending notification...", { autoClose: false });
      }
      try {
        const { error } = await addRecordAsync({
          tableName: "user_notifications",
          input: transformNotificationRecordToNotificationType(notification)
        });
        if (error) {
          console.log(`Failed to send notification: ${error}`);
          if (showToast && toastId) {
            toast.update(toastId, "Failed to send notification, Try Again", {
              type: TOAST_TYPE.ERROR,
              autoClose: 2000
            });
          }
          return;
        }
      } catch (err) {
        console.log(`Failed to send notification: ${err as Error}`);
        if (showToast && toastId) {
          toast.update(toastId, "Failed to send notification, Try Again", {
            type: TOAST_TYPE.ERROR,
            autoClose: 2000
          });
        }
      }
    },
    [addRecordAsync]
  );

  const updateNotificationData = useCallback(
    async (notificationData: Partial<NotificationRecord>) => {
      if (!notificationData.id) return;
      try {
        const { error } = await updateRecordAsync({
          tableName: "user_notifications",
          input: transformNotificationRecordToNotificationType(notificationData)
        });
        if (error) {
          console.log(`Failed to update notification ${notificationData.id}: ${error}`);
          return;
        }
        updateNotification(notificationData.id, {
          ...notificationData
        });
      } catch (err) {
        console.log(`Failed to update notification ${notificationData.id}: ${err as Error}`);
      }
    },
    [updateNotification, updateRecordAsync]
  );

  const markAsDone = useCallback(
    (updatedNotification: Partial<NotificationRecord>, notificationToSend?: Partial<NotificationRecord>) => {
      if (updatedNotification.id) {
        updateNotificationData(updatedNotification);
      }
      if (notificationToSend) {
        sendNotification(notificationToSend, false);
      }
    },
    [sendNotification, updateNotificationData]
  );

  return {
    notifications: notificationCenterItems,
    sort,
    markRead,
    markAllAsRead,
    clear: clearNotifications,
    send: sendNotification,
    markAsDone,
    get unreadCount() {
      // we only have display for  VISIBLE_NOTIFICATION_TYPES notification so only use it for unread Count
      return notificationCenterItems
        .filter((notification) => VISIBLE_NOTIFICATION_TYPES.includes(notification.type))
        .reduce((prev, cur) => (!cur.read ? prev + 1 : prev), 0);
    }
  };
};

export default useNotificationCenter;
