"use client";

import React, { createContext, useState, useCallback } from "react";
import type { Menu } from "types/baTypes";

export interface AdminMenuContextState {
  menuState: Menu | undefined;
  updateMenuStateProp: (newPropValues: Partial<Menu>) => void;
  resetMenuState: () => void;
}

export const AdminMenuContext = createContext<AdminMenuContextState | null>(null);

const { Provider } = AdminMenuContext;

export const AdminMenuContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [menuState, setMenuState] = useState<Menu | undefined>();

  const updateMenuStateProp = useCallback((newPropValues: Partial<Menu>) => {
    setMenuState((prev: any) => {
      return {
        ...prev,
        ...newPropValues,
      };
    });
  }, []);

  const resetMenuState = useCallback(() => {
    setMenuState(undefined);
  }, []);

  return (
    <Provider
      value={{
        menuState,
        updateMenuStateProp,
        resetMenuState,
      }}
    >
      {children}
    </Provider>
  );
};
