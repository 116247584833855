"use client";

import clsx from "clsx";
import { ChevronDownIcon } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import Logo from "components/logo";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "components/ui/dropdownMenu";
import useOrganizations from "hooks/useOrganizations";
import { Organization } from "types/apiTypes";
import { RecordItem } from "types/common";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { buildSupabasePublicUrl } from "components/Image/utils";

type NavOrgSelectionMenuProps = {
  currentUser?: RecordItem;
  onMenuOpen: Dispatch<SetStateAction<boolean>>;
  onOrgSelectionChange: (org: Organization) => void;
  onClick?: () => void;
  isCollapsed?: boolean;
  open?: boolean;
};

const NavOrgSelectionMenu = ({
  currentUser,
  onMenuOpen,
  onOrgSelectionChange,
  onClick,
  isCollapsed,
  open
}: NavOrgSelectionMenuProps) => {
  const { data: orgData } = useOrganizations();
  const currentUserOrg = orgData?.find((org) => `${org.id}` === `${currentUser?.org_id}`);

  if (!currentUser?.is_super_admin) {
    return (
      <div className="flex cursor-pointer items-center gap-2">
        <button onClick={onClick} className="self-center">
          {`${currentUser?.org_id || ""}` !== "1" ? (
            <span className="">{currentUserOrg?.name}</span>
          ) : (
            <Logo className="w-auto self-center" isCollapsed={isCollapsed} />
          )}
        </button>
      </div>
    );
  }

  // only show org selection menu for super admins
  return (
    <div className="flex items-center gap-2">
      <button onClick={onClick} className="self-center">
        {`${currentUser?.org_id || ""}` !== "1" ? (
          <div className="flex items-center gap-2">
            <Avatar className="rounded-md">
              {!!currentUserOrg?.logo?.path && <AvatarImage src={buildSupabasePublicUrl(currentUserOrg.logo.path)} />}
              <AvatarFallback className="rounded-md text-xs">
                {(currentUserOrg?.name || "").charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <span className="font-semibold">{currentUserOrg?.name}</span>
          </div>
        ) : (
          <Logo className="w-auto self-center" isCollapsed={isCollapsed} />
        )}
      </button>

      <DropdownMenu open={open} onOpenChange={onMenuOpen}>
        <DropdownMenuTrigger asChild>
          <div className="flex cursor-pointer items-center gap-2">
            <ChevronDownIcon className={clsx("h-6 w-6 transition duration-200", open ? "rotate-180" : "rotate-0")} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="border-separator w-72 rounded-xl border py-2 " align="end">
          {orgData?.map((org) => {
            return (
              <DropdownMenuCheckboxItem
                checked={`${org.id}` === `${currentUser?.org_id}`}
                position="end"
                className={clsx(
                  "cursor-pointer gap-2",
                  `${org.id}` === `${currentUser?.org_id}` ? "font-bold" : "font-medium"
                )}
                key={org.id}
                onClick={() => onOrgSelectionChange(org)}
              >
                <Avatar className="h-7 w-7 rounded-md">
                  {!!org.logo?.path && <AvatarImage src={buildSupabasePublicUrl(org.logo.path)} />}
                  <AvatarFallback className="rounded-md text-xs">{org.name.charAt(0).toUpperCase()}</AvatarFallback>
                </Avatar>
                {org.name}
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default NavOrgSelectionMenu;
