import clsx from "clsx";
import { ArrowLeftIcon } from "lucide-react";
import Link from "next/link";

type NavigationBackButtonProps = {
  className?: string;
  isCollapsed?: boolean;
  label?: string;
  backButtonLink: string;
};
const NavigationBackButton = ({
  className,
  isCollapsed,
  label = "Go back",
  backButtonLink,
  ...rest
}: NavigationBackButtonProps) => {
  return (
    <div className={clsx("border-separator border-b lg:mx-2 lg:mb-2.5 lg:border-b-0", className)}>
      <Link
        className="flex w-full items-center justify-between gap-x-1 rounded px-4 py-2.5 align-middle hover:bg-neutral-100 dark:hover:bg-neutral-dark-100 lg:justify-start"
        href={backButtonLink}
        {...rest}
      >
        <ArrowLeftIcon className="text-icons h-5 w-5" />
        {!isCollapsed && <div className="text-sm font-medium uppercase lg:normal-case">{label}</div>}
        <div />
      </Link>
    </div>
  );
};

export default NavigationBackButton;
