"use client";
import useTableActionsState from "hooks/useTableActionsState";
import BeforeAfterFileModal from "./BeforeAfterFileModal";

const BeforeAfterFileView = () => {
  const { beforeAfterFileViewState, updateBeforeAfterFileViewState } = useTableActionsState();

  const handleClose = () => {
    updateBeforeAfterFileViewState({ isOpen: false, beforeImage: undefined, afterImage: undefined });
  };

  return (
    <BeforeAfterFileModal
      isOpen={beforeAfterFileViewState.isOpen}
      beforeImage={beforeAfterFileViewState.beforeImage as string}
      afterImage={beforeAfterFileViewState.afterImage as string}
      onClose={handleClose}
    />
  );
};

export default BeforeAfterFileView;
