"use client";
import { useCallback, useEffect } from "react";
import clsx from "clsx";
import { LinkIcon, Minus as MinimizeIcon } from "lucide-react";
import { useRouter, useSearchParams } from "next/navigation";
import dynamic from "next/dynamic";
import { v4 as uuidv4 } from "uuid";
import noop from "lodash/noop";

import IconButton from "components/IconButton";
import SidebarUI from "components/SidebarUI";
import { CollapsibleViewerItem, CollapsibleViewerItemType } from "context/CollapsibleViewerContext";
import useCollapsibleViewerState from "hooks/useCollapsibleViewerState";
import useInLayout from "hooks/useInLayout";
import useNestedViewState from "hooks/useNestedViewState";
import usePageByPath from "hooks/usePageByPath";
import useTableActionsState from "hooks/useTableActionsState";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import { Page } from "types/baTypes";
import { RecordItem } from "types/common";
import { APP_QUERY_PARAM_TYPES, CrudActions, SidebarContainer, ViewOption } from "utils/constants";
import { getNestedPageUrl, getRecordInNestedView } from "utils/pageUtils";
import { copyText } from "utils/texts";
import toast from "utils/toast";
import CollapsibleItem from "./CollapsibleItem";

const DetailViewSidebarModal = dynamic(() => import("components/DetailViewSidebarModal"), { ssr: false });
const Form = dynamic(() => import("components/Form"), { ssr: false });

const CollapsibleViewer = () => {
  const {
    expandedItem,
    collapsedViewerItems,
    removeViewerItem,
    toggleExpandViewerItem,
    updateViewerItem,
    addViewerItem
  } = useCollapsibleViewerState();
  const { nestedViewStack, updateNavigateCompleted } = useNestedViewState();
  const { currentTablePage, currentRecordId } = useTableActionsState();
  const { isTable, isRecord } = useInLayout();
  const searchParams = useSearchParams();
  const { data: parentRecordPage } = usePageByPath({
    slug: expandedItem?.additionalProps?.tablePath as string
  });
  const router = useRouter();
  const { setParams, clearParams, pushRoute } = useSearchQueryParams();

  const handleSidebarClose = () => {
    if (expandedItem) {
      setParams({
        collapsibleViewer: {
          removeViewerId: expandedItem.id
        },
        excludeParams: [APP_QUERY_PARAM_TYPES.FORM_PATH, APP_QUERY_PARAM_TYPES.CELL_SIDEPAGE]
      });
    }
  };

  const handleExpandedItemMinimize = () => {
    if (expandedItem) {
      if (expandedItem.type === CollapsibleViewerItemType.FORM) {
        clearParams(APP_QUERY_PARAM_TYPES.FORM_PATH);
      } else if (expandedItem.type === CollapsibleViewerItemType.RECORD) {
        clearParams(APP_QUERY_PARAM_TYPES.CELL_SIDEPAGE);
      }
      toggleExpandViewerItem(expandedItem.id);
    }
  };

  const handleExpandItem = (viewerItem: CollapsibleViewerItem) => {
    if (viewerItem.type === CollapsibleViewerItemType.RECORD) {
      setParams({
        cellSide: {
          pageId: viewerItem.additionalProps?.pageId,
          recordId: viewerItem.additionalProps?.recordId,
          type: SidebarContainer.Collapsible,
          title: viewerItem.title
        }
      });
    } else if (viewerItem.type === CollapsibleViewerItemType.FORM) {
      setParams({
        form: {
          title: viewerItem.title,
          tablePath: viewerItem.additionalProps?.tablePath ?? viewerItem.itemIdentifier,
          sidebar: SidebarContainer.Collapsible,
          action: CrudActions.CREATE,
          parentRecordId: viewerItem.additionalProps?.parentRecordId,
          parentRecordSlug: viewerItem.additionalProps?.parentRecordSlug,
          add: !!viewerItem.additionalProps?.addState
            ? {
                addPageId: viewerItem.additionalProps?.addState?.addPageId,
                expanded: !!viewerItem.additionalProps?.addState?.defaultAddExpanded
              }
            : undefined
        }
      });
    }
  };

  const onCopyLink = useCallback(async () => {
    if (expandedItem?.viewerPath) {
      await copyText(expandedItem.viewerPath);
      toast.success("Copied URL successfully...", {
        autoClose: 2000
      });
    }
  }, [expandedItem?.viewerPath]);

  const handleValueChange = (data: RecordItem) => {
    if (!expandedItem) return;
    updateViewerItem(expandedItem.id, { additionalProps: { ...expandedItem.additionalProps, prefillValues: data } });
  };

  const handleActionSuccess = (
    newRecord?: RecordItem,
    options: {
      pageData?: Page;
      loadSidebarOnSubmission?: boolean;
      loadRecordOnSubmission?: boolean;
      closeSidebar?: boolean;
      item?: CollapsibleViewerItem;
    } = {
      loadSidebarOnSubmission: false,
      loadRecordOnSubmission: false,
      closeSidebar: true
    }
  ) => {
    const { item } = options;
    if (!item) return;
    item?.additionalProps?.onActionComplete?.(newRecord);
    if (options.closeSidebar && expandedItem?.id === item.id && !options?.loadRecordOnSubmission) {
      handleSidebarClose();
    }
    if (
      item.additionalProps?.action === CrudActions.CREATE &&
      !!options?.loadSidebarOnSubmission &&
      !item.additionalProps?.skiploadSidebaronSubmission &&
      options?.pageData
    ) {
      setParams({
        cellSide: {
          pageId: options?.pageData?.id,
          recordId: newRecord?.id
        }
      });
    } else if (
      item.additionalProps?.action === CrudActions.CREATE &&
      !!options?.loadRecordOnSubmission &&
      !item.additionalProps?.skiploadSidebaronSubmission &&
      options?.pageData
    ) {
      /** this is to handle loading record on form submission in modal view if it is enabled in form view config */
      if (isTable) {
        router.push(`/r/${item.additionalProps?.tablePath}/${newRecord?.id}`);
      } else if (isRecord) {
        const pathname = window.location.pathname;
        const pathParts = pathname.split("/").filter(Boolean);
        if (nestedViewStack?.length) {
          // Important to set to false to work the next useEffect in DetailTabView
          updateNavigateCompleted(false);

          const nestedViewRecordProps = getRecordInNestedView(nestedViewStack, currentTablePage);
          const finalURL = getNestedPageUrl(nestedViewRecordProps, newRecord?.id || "", pathParts);
          router.push(`${window.location.pathname}?path=${finalURL}`);
        } else {
          // Important to set to false to work the next useEffect in DetailTabView
          updateNavigateCompleted(false);
          if (currentRecordId && currentTablePage && item.additionalProps?.parentRecordSlug) {
            const pageDetailViewTabs = parentRecordPage?.views?.find(
              (view) => view.viewType === ViewOption.DETAIL_MAIN
            )?.tabs;
            const currentTab = pageDetailViewTabs?.find((tab) => tab.page?.id === currentTablePage.id);
            const defaultTabId = currentTablePage?.views?.find(
              (view) => view.viewType === ViewOption.DETAIL_MAIN
            )?.defaultPageTab;
            const defaultTabPath = pageDetailViewTabs?.find((tab) => tab.id === defaultTabId)?.page?.path;
            if (!currentTab && !defaultTabId) return;

            const finalURL = getNestedPageUrl(
              {
                queryParams: defaultTabId
                  ? [{ tabId: defaultTabId, tabPath: defaultTabPath || "", nestedRecordId: "" }]
                  : currentTab
                    ? [{ tabId: currentTab.id, tabPath: currentTab.page?.path || "", nestedRecordId: currentRecordId }]
                    : []
              },
              newRecord?.id || "",
              pathParts
            );

            if (finalURL) {
              pushRoute({
                newPath: finalURL,
                addParams: `${APP_QUERY_PARAM_TYPES.COLLAPSIBLE_VIEWER_REMOVE_ITEM}=${expandedItem?.id}`,
                removeParams: [APP_QUERY_PARAM_TYPES.FORM_PATH]
              });
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    const viewerIdToRemove = searchParams?.get(APP_QUERY_PARAM_TYPES.COLLAPSIBLE_VIEWER_REMOVE_ITEM);
    // only handle sidebar state if form path is present  and a collapsibleForm
    // or cellSide params are present and is of type collapsible
    if (
      ((!searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_PATH) ||
        !(searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_SIDEBAR) === SidebarContainer.Collapsible)) &&
        (!(searchParams?.get(APP_QUERY_PARAM_TYPES.CELL_SIDETYPE) === SidebarContainer.Collapsible) ||
          !searchParams?.get(APP_QUERY_PARAM_TYPES.CELL_SIDEPAGE))) ||
      viewerIdToRemove
    ) {
      return;
    }

    const formPath = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PATH);
    const formTitle = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_TITLE) ?? "";
    const formAction = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_ACTION);
    const pageFormViewId = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PAGEID) ?? undefined;
    const formViewId = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_ID) ?? undefined;
    const hadAdd = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_ADD) ?? undefined;
    const formParentId = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PARENTID) ?? undefined;
    const formAddExpanded = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_EXPANDED) ?? undefined;
    const formParentSlug = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PARENTSLUG) ?? undefined;

    const sidebarPageId = searchParams.get(APP_QUERY_PARAM_TYPES.CELL_SIDEPAGE);
    const sidebarRecordId = searchParams.get(APP_QUERY_PARAM_TYPES.CELL_SIDERECORD);
    const sidebarTitle = searchParams.get(APP_QUERY_PARAM_TYPES.CELL_SIDETITLE);

    if (formPath) {
      addViewerItem(
        {
          id: uuidv4(),
          type: CollapsibleViewerItemType.FORM,
          title: formTitle,
          itemIdentifier: formPath,
          viewerPath: window.location.href,
          additionalProps: {
            action: formAction as CrudActions,
            tablePath: formPath,
            parentRecordId: formParentId,
            parentRecordSlug: formParentSlug,
            pageFormViewId,
            formViewId,
            addState: hadAdd
              ? {
                  isOpen: true,
                  tablePath: formPath || "",
                  parentRecordId: formParentId,
                  parentRecordSlug: formParentSlug,
                  defaultAddExpanded: !!formAddExpanded,
                  addPageId: hadAdd
                }
              : undefined
          }
        },
        true
      );
    }

    if (sidebarPageId) {
      addViewerItem(
        {
          id: uuidv4(),
          type: CollapsibleViewerItemType.RECORD,
          title: sidebarTitle || "",
          itemIdentifier: `page_${sidebarPageId}_record_${sidebarRecordId}`,
          additionalProps: {
            pageId: sidebarPageId,
            recordId: sidebarRecordId,
            cellSidePage: {
              pageTable: "",
              pageId: sidebarPageId,
              viewOption: ViewOption.GRID,
              recordId: sidebarRecordId,
              navItems: [],
              hideSidebar: false,
              hideBreadcrumb: true,
              fetchRecordData: true
            }
          }
        },
        true
      );
    }
  }, [searchParams]);

  useEffect(() => {
    const viewerIdToRemove = searchParams?.get(APP_QUERY_PARAM_TYPES.COLLAPSIBLE_VIEWER_REMOVE_ITEM);

    if (!viewerIdToRemove) return;
    clearParams(APP_QUERY_PARAM_TYPES.COLLAPSIBLE_VIEWER_REMOVE_ITEM);
    removeViewerItem(viewerIdToRemove);
  }, [searchParams, removeViewerItem, clearParams]);

  return (
    <div className="customScrollBar fixed bottom-0 right-0 z-50 flex w-fit max-w-full gap-2 overflow-x-auto bg-transparent px-3">
      {collapsedViewerItems.map((viewerItem, index) => (
        <CollapsibleItem
          className={clsx(index === 0 && "ml-auto")}
          key={viewerItem.id}
          title={viewerItem.title}
          onCancel={() => removeViewerItem(viewerItem.id)}
          onExpand={() => handleExpandItem(viewerItem)}
        />
      ))}

      <SidebarUI
        isOpen={!!expandedItem}
        onClose={handleSidebarClose}
        rounded
        classNameHeader="!flex-row-reverse"
        classNameContainer="overflow-hidden"
        rightHeader={
          <>
            {expandedItem?.viewerPath ? <IconButton icon={LinkIcon} onClick={onCopyLink} /> : null}
            <IconButton icon={MinimizeIcon} onClick={handleExpandedItemMinimize} />
          </>
        }
      >
        {expandedItem && expandedItem.type === CollapsibleViewerItemType.FORM ? (
          <Form
            className="pt-4"
            classNameEditAdminBtn={clsx("!top-3", expandedItem?.viewerPath ? "!right-[148px]" : "!right-28")}
            columns={expandedItem.additionalProps?.columns}
            action={expandedItem.additionalProps?.action}
            title={expandedItem.additionalProps?.title}
            onActionSuccess={(newRecord, options) => handleActionSuccess(newRecord, { ...options, item: expandedItem })}
            onValueChange={handleValueChange}
            {...expandedItem.additionalProps}
          />
        ) : expandedItem?.type === CollapsibleViewerItemType.RECORD ? (
          <DetailViewSidebarModal
            nestedViewState={expandedItem.additionalProps?.cellSidePage}
            isOpen
            inCollapsibleView
            onClose={noop}
          />
        ) : null}
      </SidebarUI>
    </div>
  );
};

export default CollapsibleViewer;
