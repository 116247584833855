"use client";
import omit from "lodash/omit";
import { createContext, useCallback, useMemo, useState } from "react";
import { RecordItem } from "types/common";

export enum CollapsibleViewerItemType {
  FORM = "form",
  RECORD = "record"
}

export type CollapsibleViewerItem = {
  id: string;
  type: CollapsibleViewerItemType;
  title?: string;
  additionalProps?: RecordItem;
  itemIdentifier?: string; // this is used to identifiy item in case to prevent adding add again to collapsible view
  viewerPath?: string; // used to store the page path from which the page is opened
};

export interface CollapsibleViewerContextState {
  viewerItems: Array<CollapsibleViewerItem>;
  expandedItem?: CollapsibleViewerItem;
  collapsedViewerItems: Array<CollapsibleViewerItem>;
  addViewerItem: (item: CollapsibleViewerItem, defaultExpanded?: boolean) => void;
  removeViewerItem: (id: string) => void;
  toggleExpandViewerItem: (id: string) => void;
  updateViewerItem: (id: string, values: Partial<CollapsibleViewerItem>) => void;
}

export const CollapsibleViewerContext = createContext<CollapsibleViewerContextState | null>(null);

const { Provider } = CollapsibleViewerContext;

export const CollapsibleViewerContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [viewerItems, setViewerItems] = useState<Array<CollapsibleViewerItem>>([]);
  const [expandedItemId, setExpandedItemId] = useState<string | undefined>();

  const addViewerItem = useCallback(
    (item: CollapsibleViewerItem, defaultExpanded = false) => {
      const existingItem = item.itemIdentifier
        ? viewerItems.find((viewerItem) => viewerItem.itemIdentifier === item.itemIdentifier)
        : undefined;
      if (existingItem) {
        setViewerItems((prev) =>
          prev.map((viewerItem) =>
            viewerItem.id === existingItem.id
              ? {
                  ...viewerItem,
                  ...omit(item, ["id"]),
                  additionalProps: { ...viewerItem.additionalProps, ...item.additionalProps }
                }
              : viewerItem
          )
        );
        setExpandedItemId(existingItem.id);
      } else {
        setViewerItems((prev) => [...prev, item]);
        if (defaultExpanded) {
          setExpandedItemId(item.id);
        }
      }
    },
    [viewerItems]
  );

  const removeViewerItem = useCallback((id: string) => {
    setViewerItems((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const toggleExpandViewerItem = useCallback((id?: string) => {
    setExpandedItemId((prev) => (prev === id ? undefined : id));
  }, []);

  const updateViewerItem = useCallback((id: string, values: Partial<CollapsibleViewerItem>) => {
    setViewerItems((prev) => prev.map((item) => (item.id === id ? { ...item, ...values } : item)));
  }, []);

  const expandedItem = useMemo(() => {
    return viewerItems.find((item) => item.id === expandedItemId);
  }, [expandedItemId, viewerItems]);

  const collapsedViewerItems = useMemo(() => {
    if (expandedItemId) {
      return viewerItems.filter((item) => item.id !== expandedItemId);
    }

    return viewerItems;
  }, [viewerItems, expandedItemId]);

  return (
    <Provider
      value={{
        expandedItem,
        viewerItems,
        collapsedViewerItems,
        addViewerItem,
        removeViewerItem,
        toggleExpandViewerItem,
        updateViewerItem
      }}
    >
      {children}
    </Provider>
  );
};
