"use client";
import { useEffect, useRef } from "react";
import { useAnalytics } from "context/AnalyticsContext";
import { EVENTS } from "utils/analytics";
import useCurrentUser from "hooks/useCurrentUser";

const LayoutClientAnalytics = () => {
  const { identify, track } = useAnalytics();
  const currentUser = useCurrentUser();
  const isAnalyticsEventSent = useRef(false);

  useEffect(() => {
    if (isAnalyticsEventSent.current || !currentUser?.id) return;
    isAnalyticsEventSent.current = true;
    identify();
    track({ eventName: EVENTS.LOGGED_IN });
  }, [identify, track, currentUser?.id]);

  return null;
};

export default LayoutClientAnalytics;
