import { useEffect, useState } from "react";
/**
 * reference: https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react/
 */
export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(() => typeof document !== "undefined" && !document.hidden);
  const onVisibilityChange = () => typeof document !== "undefined" && setIsVisible(!document.hidden);

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange, false);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  return isVisible;
}
