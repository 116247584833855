import clsx from "clsx";
import {
  BookmarkIcon,
  Pencil as EditIcon,
  MoreHorizontal as MenuHorizontalIcon,
  MoreVertical as MenuVerticalIcon,
  PlusIcon
} from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import useKeypress from "react-use-keypress";
import Button from "components/Button";
import CellGroup from "components/CellGroup";
import DragAndDrop from "components/DragAndDrop";
import Dropdown from "components/Dropdown";
import IconButton from "components/IconButton";
import useCurrentUser from "hooks/useCurrentUser";
import useKeypressSave from "hooks/useKeypressSave";
import useErrorLogger from "hooks/useErrorLogger";
import useOverlay from "hooks/useOverlay";
import { Page, TableColumnType } from "types/baTypes";
import { RecordItem, SelectOption } from "types/common";
import {
  AdditionalFormActions,
  ButtonAppearance,
  CellGroupDirection,
  CellType,
  CrudActions,
  ERROR_TYPES,
  GENERIC_CELL_LAYOUTS,
  ViewOption
} from "utils/constants";
import FormSection from "./FormSection";
import { getSettingsForFormColumn } from "./utils";

const FormUI = ({
  finalTitle,
  fileCol,
  formFieldsArray,
  onSubmit,
  onValueChange,
  pageData,
  isLoading,
  isJoinTable,
  formRequiredErrors,
  action,
  record,
  relationFormColumnPagesMap,
  hideTitle,
  className,
  classNameContent,
  sections = [],
  currentFocus,
  showCollapse = false,
  goToEditAdmin,
  classNameEditAdminBtn,
  pageFormViewId,
  allColumns = []
}: {
  finalTitle: string;
  fileCol?: TableColumnType;
  formFieldsArray: RecordItem[];
  onSubmit: (data: any, event?: any, closeSidebar?: boolean) => void;
  pageData?: Page;
  isLoading?: boolean;
  isJoinTable: boolean;
  formRequiredErrors?: Record<string, boolean>;
  action?: CrudActions | AdditionalFormActions;
  record?: RecordItem;
  relationFormColumnPagesMap?: Record<string, Page>;
  hideTitle?: boolean;
  className?: string;
  classNameContent?: string;
  sections?: { title?: string; description?: string; columns: RecordItem[]; isClose?: boolean }[];
  currentFocus?: string | null;
  showCollapse?: boolean;
  goToEditAdmin?: () => void;
  classNameEditAdminBtn?: string;
  onValueChange?: (data: RecordItem) => void;
  pageFormViewId?: string;
  allColumns?: TableColumnType[];
}) => {
  const stateForm = useForm();
  const { overlay, showOverlay, hideOverlay } = useOverlay();
  const currentUser = useCurrentUser();
  const { logError } = useErrorLogger();

  const { control, handleSubmit, setValue, resetField, getValues, reset } = stateForm;
  const [rollupColKeys, setRollupColKeys] = useState<{ [colId: string]: string }>();
  const hasLongTextLexicalColumn = sections?.some((section) =>
    section.columns?.some((col) => col.type === CellType.LONG_TEXT && !col.cellConfig?.showTextarea)
  );
  const [currentFocusColId, setCurrentFocusColId] = useState(hasLongTextLexicalColumn ? null : currentFocus);
  const [lexicalInitStateSet, setLexicalInitStateSet] = useState(false);
  useEffect(() => {
    if (!hasLongTextLexicalColumn) {
      setCurrentFocusColId(currentFocus);
    }
  }, [currentFocus, hasLongTextLexicalColumn]);

  const handleUpdateCurrentFocusCol = useCallback(() => {
    setLexicalInitStateSet(false);
    setCurrentFocusColId(null);
    requestAnimationFrame(() => {
      setLexicalInitStateSet(true);
    });
  }, []);

  useEffect(() => {
    if (!hasLongTextLexicalColumn) return;
    if (lexicalInitStateSet) {
      setCurrentFocusColId(currentFocus);
    }
  }, [currentFocus, lexicalInitStateSet, hasLongTextLexicalColumn]);

  const formViewConfig = useMemo(
    () =>
      pageData?.views?.find(
        (view) =>
          view.viewType === ViewOption.FORM &&
          ((!pageFormViewId && view.isDefault) || `${view.viewId}` === `${pageFormViewId}`)
      ),
    [pageData?.views, pageFormViewId]
  );

  const handleDragAndDropSuccess = useCallback(
    (files: File[]) => {
      if (!fileCol) return;

      setValue(fileCol.id, fileCol.isLookup ? { inputValue: files, displayValue: files } : files);
    },
    [fileCol, setValue]
  );

  const handleSubmitAnother = useCallback(
    async (dataToSave: RecordItem, event: any) => {
      try {
        const closeSidebar = false;
        await onSubmit(dataToSave, event, closeSidebar);
        // Reset values
        reset();
        // Add initial values again
        formFieldsArray.forEach((formCol) => {
          if (formCol.columnInitValue) {
            setValue(formCol.column.id, formCol.columnInitValue);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    [onSubmit, reset, formFieldsArray, setValue]
  );

  const handleSubmitAnotherWithSameData = useCallback(
    (dataToSave: RecordItem, event: any) => {
      const closeSidebar = false;
      onSubmit(dataToSave, event, closeSidebar);
    },
    [onSubmit]
  );

  useEffect(() => {
    if (
      !formFieldsArray?.length ||
      ![
        CrudActions.UPDATE,
        CrudActions.CREATE,
        AdditionalFormActions.EMAIL,
        AdditionalFormActions.BULK_MATRIX_EDIT
      ].includes(action as CrudActions)
    )
      return;

    formFieldsArray.forEach((formCol) => {
      if (formCol.columnInitValue) {
        setValue(formCol.column.id, formCol.columnInitValue);
      }
    });
  }, [action, setValue, formFieldsArray]);

  useEffect(() => {
    if (!formFieldsArray?.length) return;
    const finalKeys: { [colId: string]: string } = {};
    formFieldsArray.forEach((formCol) => {
      if (formCol.column.isRollup) {
        finalKeys[formCol.id] = formCol.column.id;
      }
    });
    setRollupColKeys(finalKeys);
  }, [formFieldsArray]);

  const onSubmitInvalid = useCallback(
    (errors: any) => {
      logError({
        error: new Error("Form submission failed"),
        source: "FormUI",
        message: "Form submission failed",
        type: ERROR_TYPES.FORM,
        url: window.location.href,
        additionalInfo: {
          errors
        }
      });
    },
    [logError]
  );

  // Save with cmd + s
  useKeypressSave(handleSubmit(onSubmit, onSubmitInvalid));

  useKeypress("s", (event: KeyboardEvent) => {
    if (!formViewConfig?.additionalConfig?.allowSaveAndCreateNew) return;
    if (event.shiftKey && event.metaKey) {
      handleSubmit(handleSubmitAnotherWithSameData)();
    } else if (event.metaKey) {
      handleSubmit(handleSubmitAnother)();
    }
  });

  // Needed to force rerender form field
  const resetKey = (newValue: any, colId: string) => {
    setRollupColKeys((prev) => {
      return { ...(prev || {}), [colId]: `${colId}_${newValue}` };
    });
  };

  const handleValueChange = () => {
    onValueChange?.(getValues());
  };

  return (
    <>
      {goToEditAdmin && currentUser?.is_admin && (
        <Dropdown
          MenuButton={<IconButton icon={MenuHorizontalIcon} />}
          classNameContainer={clsx("!fixed !z-50 top-5 right-16", classNameEditAdminBtn)}
          items={[{ id: 1, label: "Edit", onClick: goToEditAdmin, icon: EditIcon }]}
        />
      )}
      <div className={clsx("form-container w-full flex-1 overflow-y-auto pb-4", className)}>
        <FormProvider {...stateForm}>
          {!hideTitle && (
            <div className="text-primary mb-8 w-full px-6 text-2xl font-semibold lg:px-20">{finalTitle}</div>
          )}

          <div className={clsx("relative z-0 px-6 lg:px-20", classNameContent)}>
            {!!fileCol && <DragAndDrop onSuccess={handleDragAndDropSuccess} multiple={fileCol.isMultiple} />}

            <div
              className={clsx("relative z-10", overlay && "pointer-events-none")}
              onDragOver={!!fileCol ? showOverlay : undefined}
              onDragLeave={!!fileCol ? hideOverlay : undefined}
            >
              {/* This is for create form */}
              {sections.map((section) => {
                return (
                  <FormSection
                    title={section.title}
                    description={section.description}
                    isClose={!!section.isClose}
                    key={section.title}
                    showCollapse={showCollapse}
                  >
                    <div className="">
                      {section.columns.map((col, index: number) => {
                        return (
                          <div
                            key={`${rollupColKeys?.[col.id] || col.id}`}
                            className={clsx(
                              `${rollupColKeys?.[col.id] || col.id}`,
                              col.column.views?.[ViewOption.FORM]?.isHidden ||
                                (pageFormViewId && col.column?.customPageViews?.[pageFormViewId]?.isHidden)
                                ? "!m-0 hidden h-0 w-0"
                                : "mb-8 last:mb-0"
                            )}
                          >
                            <Controller
                              key={col.id}
                              name={col.id}
                              control={control}
                              render={({ field }) => {
                                let finalValue = col.column.isLookup ? field.value?.displayValue : field.value;
                                // To render selected record email in to field
                                if (
                                  action === AdditionalFormActions.EMAIL &&
                                  col.name === "to_email" &&
                                  record?.email
                                ) {
                                  finalValue = record.email;
                                }

                                const relationFormColumnPages = relationFormColumnPagesMap
                                  ? Object.values(relationFormColumnPagesMap)
                                  : [];
                                const { isRequired } = getSettingsForFormColumn({
                                  column: col.column,
                                  viewConfig: formViewConfig,
                                  isJoinTable,
                                  relationFormColumnPages
                                });

                                return (
                                  <CellGroup
                                    label={col.label}
                                    type={col.type ? (col.type as CellType) : CellType.TEXT}
                                    column={col.column}
                                    value={finalValue}
                                    onEdit={(values, scope_id, valueCol, additionalValues) => {
                                      if (col.column.type === CellType.LONG_TEXT) {
                                        const columnForPlainText = (col.column as TableColumnType).cellConfig
                                          ?.lexicalEditorProps?.columnForPlainText;
                                        if (columnForPlainText) {
                                          setValue(
                                            columnForPlainText,
                                            col.column.isLookup
                                              ? {
                                                  inputValue: additionalValues?.textContent,
                                                  displayValue: additionalValues?.textContent
                                                }
                                              : additionalValues?.textContent
                                          );
                                        }
                                      } else if (
                                        col.column.type === CellType.GENERIC_CELL &&
                                        (col.column as TableColumnType).cellConfig?.genericConfig?.layout ===
                                          GENERIC_CELL_LAYOUTS.COMBINATION_CELL
                                      ) {
                                        const columnForValue = (values as SelectOption)?.column;
                                        (
                                          col.column as TableColumnType
                                        ).cellConfig?.genericConfig?.comboEditColumnIds?.forEach((comboEditColId) => {
                                          if (comboEditColId === columnForValue?.id) {
                                            setValue(
                                              comboEditColId,
                                              (values as SelectOption).value
                                                ? columnForValue.isLookup
                                                  ? { inputValue: values, displayValue: values }
                                                  : values
                                                : undefined
                                            );
                                          } else {
                                            setValue(comboEditColId, undefined);
                                          }
                                        });

                                        handleValueChange();
                                        return;
                                      }
                                      field.onChange(
                                        col.column.isLookup ? { inputValue: values, displayValue: values } : values
                                      );
                                      handleValueChange();
                                      if (col.onChangeCallback) {
                                        col.onChangeCallback(values, col.column.id, resetField, resetKey, getValues());
                                      }
                                    }}
                                    isLoading={false}
                                    className="mt-4"
                                    direction={CellGroupDirection.VERTICAL}
                                    rules={{
                                      required: isRequired
                                    }}
                                    hasRequiredError={!!formRequiredErrors?.[col.id]}
                                    isEditable
                                    isMultiple={col.column.isMultiple}
                                    autoFocus={index === 0}
                                    options={col.options || undefined}
                                    isJoinTable={isJoinTable}
                                    inForm
                                    isReadOnly={col.isReadOnly}
                                    isFocused={currentFocusColId === col.id}
                                    onLexicalInitState={handleUpdateCurrentFocusCol}
                                    allColumns={allColumns}
                                  />
                                );
                              }}
                              defaultValue={col.columnInitValue}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </FormSection>
                );
              })}

              {/* This is for bulk editing */}
              {!sections?.length && (
                <div className="space-y-8">
                  {formFieldsArray.map((col, index) => {
                    return (
                      <div
                        key={`${rollupColKeys?.[col.id] || col.id}`}
                        className={clsx(`${rollupColKeys?.[col.id] || col.id}`)}
                      >
                        <Controller
                          key={col.id}
                          name={col.id}
                          control={control}
                          render={({ field }) => {
                            let finalValue = col.column.isLookup ? field.value?.displayValue : field.value;
                            // To render selected record email in to field
                            if (action === AdditionalFormActions.EMAIL && col.name === "to_email" && record?.email) {
                              finalValue = record.email;
                            }

                            const relationFormColumnPages = relationFormColumnPagesMap
                              ? Object.values(relationFormColumnPagesMap)
                              : [];
                            const { isRequired } = getSettingsForFormColumn({
                              column: col.column,
                              viewConfig: formViewConfig,
                              isJoinTable,
                              relationFormColumnPages
                            });

                            return (
                              <>
                                <CellGroup
                                  isFocused={index === 0}
                                  label={col.label}
                                  type={col.type ? (col.type as CellType) : CellType.TEXT}
                                  column={col.column}
                                  value={finalValue}
                                  onEdit={(values, scope_id, valueCol, additionalValues) => {
                                    if (col.column.type === CellType.LONG_TEXT) {
                                      const columnForPlainText = (col.column as TableColumnType).cellConfig
                                        ?.lexicalEditorProps?.columnForPlainText;
                                      if (columnForPlainText) {
                                        setValue(
                                          columnForPlainText,
                                          col.column.isLookup
                                            ? {
                                                inputValue: additionalValues?.textContent,
                                                displayValue: additionalValues?.textContent
                                              }
                                            : additionalValues?.textContent
                                        );
                                      }
                                    }
                                    field.onChange(
                                      col.column.isLookup ? { inputValue: values, displayValue: values } : values
                                    );
                                    handleValueChange();
                                    if (col.onChangeCallback) {
                                      col.onChangeCallback(values, col.column.id, resetField, resetKey, getValues());
                                    }
                                  }}
                                  isLoading={false}
                                  className="mt-4"
                                  direction={CellGroupDirection.VERTICAL}
                                  rules={{
                                    required: isRequired
                                  }}
                                  hasRequiredError={!!formRequiredErrors?.[col.id]}
                                  isEditable
                                  isMultiple={col.column.isMultiple}
                                  autoFocus={index === 0 && !isRequired}
                                  options={col.options || undefined}
                                  isJoinTable={isJoinTable}
                                  inForm
                                  isReadOnly={col.isReadOnly}
                                  allColumns={allColumns}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </FormProvider>
      </div>
      <div className="border-separator flex w-full items-center gap-x-5 border-t bg-white px-6 py-6 transition-colors dark:bg-gray-800 lg:px-20">
        <Button
          isLoading={isLoading}
          label={finalTitle}
          appearance={ButtonAppearance.PRIMARY}
          className="h-12 w-full px-4 py-6"
          onClick={handleSubmit(onSubmit, onSubmitInvalid)}
          disabled={isLoading}
        />
        {formViewConfig?.additionalConfig?.allowSaveAndCreateNew && (
          <Dropdown
            MenuButton={<IconButton icon={MenuVerticalIcon} />}
            classNameContainer=""
            items={[
              {
                id: 1,
                label: "Create & Add Another",
                onClick: handleSubmit(handleSubmitAnother),
                icon: PlusIcon,
                shortcutKey: "⌘S"
              },
              {
                id: 2,
                label: "Create & Add Another w/Same Data",
                onClick: handleSubmit(handleSubmitAnotherWithSameData),
                icon: BookmarkIcon,
                shortcutKey: "⌘⇧S"
              }
            ]}
          />
        )}
      </div>
    </>
  );
};

export default FormUI;
