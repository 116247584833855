"use client";

import { useCallback, useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { LinkIcon } from "lucide-react";
import dynamic from "next/dynamic";

import useTableActionsState from "hooks/useTableActionsState";
import useNestedViewState from "hooks/useNestedViewState";
import useInLayout from "hooks/useInLayout";
import usePageByPath from "hooks/usePageByPath";
import useSearchQueryParams from "hooks/useSearchQueryParams";

import SidebarUI from "components/SidebarUI";
import BulkActionEditForm from "components/Form/BulkActionEditForm";
import Modal from "components/Modal";
import WebhookForm from "components/Form/WebhookForm";
import IconButton from "components/IconButton";
import { ViewsLabel } from "components/ViewSelector/utils";
import BulkActionEditMatrix from "components/Form/BulkActionEditMatrix";
import CustomForm from "components/Form/CustomForm";
import AddContainer from "components/Add/AddContainer";
import AuditSidebar from "components/Sidebar/AuditSidebar";

import { AdminPageContextProvider } from "context/AdminPageContext";
import { AdminMenuContextProvider } from "context/AdminMenuContext";
import { AdminContextProvider } from "context/AdminContext";

import { getNestedPageUrl, getRecordInNestedView } from "utils/pageUtils";
import { copyText } from "utils/texts";
import {
  AdditionalFormActions,
  CrudActions,
  SidebarContainer,
  SIDEBAR_TYPE,
  ViewOption,
  STATIC_SIDEBAR_IDS,
  PAGE_BULKACTION_PAYLOAD_TYPE,
  APP_QUERY_PARAM_TYPES
} from "utils/constants";
import toast from "utils/toast";

import { SelectOptions, RecordItem } from "types/common";
import { Page } from "types/baTypes";

const SingleColumnEdit = dynamic(() => import("components/Admin/SingleColumnEdit"), { ssr: false });
const AdminSearchTableConfigEdit = dynamic(() => import("components/Admin/AdminSearchTableConfigEdit"), { ssr: false });
const AdminEditSinglePageViewConfig = dynamic(() => import("components/Admin/AdminEditSinglePageViewConfig"), {
  ssr: false
});
const Add = dynamic(() => import("components/Add"), { ssr: false });
const Form = dynamic(() => import("components/Form"), { ssr: false });
const JSONEditor = dynamic(() => import("components/JSONEditor"), { ssr: false });
const AdminMenuConfigEdit = dynamic(() => import("components/Admin/AdminMenuConfigEdit"), { ssr: false });

/**
 *
 * This component manages sidebar state and side effects
 * depending on the invoking component
 */

const Sidebar = () => {
  const router = useRouter();
  const { currentTablePage, sidebarState, updateSidebarState, currentRecordId } = useTableActionsState();
  const { nestedViewStack, updateNavigateCompleted } = useNestedViewState();
  const searchParams = useSearchParams();
  const { clearParams, setParams, updateCurrentSearchContextProjectId, updateCurrentSearchContextRecordId } =
    useSearchQueryParams();
  const { isTable, isRecord } = useInLayout();
  const { data: parentRecordPage } = usePageByPath({
    slug: sidebarState?.parentRecordSlug as string
  });

  //  check if form param is present to show copyLinkIcon
  const isFormParamsPresent = !!searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_PATH);

  const handleOnClose = () => {
    sidebarState.onActionCancel?.();
    if (searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_PATH)) {
      clearParams(APP_QUERY_PARAM_TYPES.FORM_PATH);
    }
    updateSidebarState(
      { isOpen: false, showDetailView: false, sidebarType: undefined, container: undefined, title: undefined },
      STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
    );
  };

  const handleOnColumnEditClose = () => {
    sidebarState.onActionCancel?.();
    updateSidebarState(
      { isOpen: false, showDetailView: false, sidebarType: undefined, container: undefined, title: undefined },
      STATIC_SIDEBAR_IDS.COLUMN_CONFIG_EDIT_SIDEBAR
    );
  };

  const handleOnAddSidebarClose = () => {
    if (searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_ADD)) {
      clearParams(APP_QUERY_PARAM_TYPES.FORM_ADD);
    }
    if (searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_TRIGGER)) {
      updateCurrentSearchContextRecordId(null);
      updateCurrentSearchContextProjectId(null);
    }
    if (sidebarState?.addState?.tablePath === searchParams?.get(APP_QUERY_PARAM_TYPES.FILTERS)) {
      clearParams(APP_QUERY_PARAM_TYPES.FILTERS);
    }
    sidebarState.addState?.onActionCancel?.();
    updateSidebarState({ addState: undefined }, STATIC_SIDEBAR_IDS.ADD_SIDEBAR);
  };

  const handlePageViewConfigSidebarClose = () => {
    updateSidebarState({ pageViewConfigEditState: undefined }, STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR);
  };

  const handleActionSuccess = (
    newRecord?: RecordItem,
    options: {
      pageData?: Page;
      loadSidebarOnSubmission?: boolean;
      loadRecordOnSubmission?: boolean;
      closeSidebar?: boolean;
    } = {
      loadSidebarOnSubmission: false,
      loadRecordOnSubmission: false,
      closeSidebar: true
    }
  ) => {
    if (searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_PATH) && options?.closeSidebar) {
      if (searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_TRIGGER)) {
        updateCurrentSearchContextRecordId(null);
        updateCurrentSearchContextProjectId(null);
      }
      clearParams(APP_QUERY_PARAM_TYPES.FORM_PATH);
    }
    sidebarState.onActionComplete?.(newRecord);
    if (options?.closeSidebar) {
      updateSidebarState(
        { isOpen: !options.closeSidebar, showDetailView: false, sidebarType: undefined },
        STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
      );
    }
    if (
      sidebarState.action === CrudActions.CREATE &&
      !!options?.loadSidebarOnSubmission &&
      !sidebarState?.skiploadSidebaronSubmission
    ) {
      if (options?.pageData) {
        setParams({
          cellSide: {
            pageId: options?.pageData?.id,
            recordId: newRecord?.id
          },
          excludeParams: options?.closeSidebar ? [APP_QUERY_PARAM_TYPES.FORM_PATH] : []
        });
      }
    } else if (
      sidebarState.action === CrudActions.CREATE &&
      !!options?.loadRecordOnSubmission &&
      !sidebarState?.skiploadSidebaronSubmission
    ) {
      if (isTable) {
        router.push(`/r/${sidebarState.tablePath}/${newRecord?.id}`);
      } else if (isRecord) {
        const pathname = window.location.pathname;
        const pathParts = pathname.split("/").filter(Boolean);

        if (nestedViewStack?.length) {
          // Important to set to false to work the next useEffect in DetailTabView
          updateNavigateCompleted(false);
          const nestedViewRecordProps = getRecordInNestedView(nestedViewStack, currentTablePage);
          const finalURL = getNestedPageUrl(nestedViewRecordProps, newRecord?.id || "", pathParts);

          router.push(`${window.location.pathname}?path=${finalURL}`);
        } else {
          // Important to set to false to work the next useEffect in DetailTabView
          updateNavigateCompleted(false);
          if (currentRecordId && currentTablePage && sidebarState?.parentRecordSlug) {
            const pageDetailTabs = parentRecordPage?.views?.find(
              (view) => view.viewType === ViewOption.DETAIL_MAIN
            )?.tabs;
            const currentTab = pageDetailTabs?.find((tab) => tab.page?.id && tab.page.id === currentTablePage.id);
            const defaultTabId = currentTablePage?.views?.find(
              (view) => view.viewType === ViewOption.DETAIL_MAIN
            )?.defaultPageTab;
            const defaultTabPath = pageDetailTabs?.find((tab) => tab.id === defaultTabId)?.page?.path;
            if (!currentTab && !defaultTabId) return;
            const finalURL = getNestedPageUrl(
              {
                queryParams: defaultTabId
                  ? [{ tabId: defaultTabId, tabPath: defaultTabPath || "", nestedRecordId: "" }]
                  : currentTab
                    ? [{ tabId: currentTab.id, tabPath: currentTab.page?.path || "", nestedRecordId: currentRecordId }]
                    : []
              },
              newRecord?.id || "",
              pathParts
            );
            if (finalURL) {
              router.push(finalURL);
            }
          }
        }
      }
    }
  };

  const handleAddActionSuccess = (options?: SelectOptions | RecordItem) => {
    sidebarState.addState?.onActionComplete?.(options as SelectOptions);
    updateSidebarState({ addState: undefined }, STATIC_SIDEBAR_IDS.ADD_SIDEBAR);
    clearParams(APP_QUERY_PARAM_TYPES.FORM_ADD);
  };

  const handlePageViewConfigActionSuccess = (options?: SelectOptions | RecordItem) => {
    sidebarState.pageViewConfigEditState?.onActionComplete?.(options as SelectOptions);
    if (!sidebarState.pageViewConfigEditState?.showActionConfig && !(options as RecordItem)?.keepSidebarOpen) {
      updateSidebarState({ pageViewConfigEditState: undefined }, STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR);
    }
  };

  const handleMenuConfigActionSuccess = () => {
    sidebarState.menuConfigEditState?.onActionComplete?.();
    updateSidebarState({ menuConfigEditState: undefined }, STATIC_SIDEBAR_IDS.MENU_CONFIG_EDIT_SIDEBAR);
  };

  const handleSearchTableConfigSuccess = () => {
    sidebarState.searchTableConfigEditState?.onActionComplete?.();
    updateSidebarState({ searchTableConfigEditState: undefined }, STATIC_SIDEBAR_IDS.SEARCH_TABLE_CONFIG_EDIT_SIDEBAR);
  };

  const handleAuditSidebarClose = useCallback(() => {
    updateSidebarState({ auditState: undefined, showAuditSidebar: false }, STATIC_SIDEBAR_IDS.AUDIT_SIDEBAR);
  }, [updateSidebarState]);

  const onCopyLink = useCallback(async () => {
    await copyText(window.location.href);
    toast.success("Copied URL successfully...", {
      autoClose: 2000
    });
  }, []);

  useEffect(() => {
    // only handle sidebar state if form path is present and not a collapsibleForm
    if (
      !searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_PATH) ||
      searchParams?.get(APP_QUERY_PARAM_TYPES.FORM_SIDEBAR) === SidebarContainer.Collapsible
    ) {
      return;
    }
    const formPath = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PATH);
    const formAction = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_ACTION);
    const pageFormViewId = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PAGEID) ?? undefined;
    const formViewId = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_ID) ?? undefined;
    const hadAdd = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_ADD) ?? undefined;
    const formParentId = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PARENTID) ?? undefined;
    const formAddExpanded = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_EXPANDED) ?? undefined;
    const formParentSlug = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_PARENTSLUG) ?? undefined;
    const addTabId = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_ADD_TAB) ?? undefined;
    const formTrigger = searchParams.get(APP_QUERY_PARAM_TYPES.FORM_TRIGGER) ?? undefined;
    const containerType =
      (searchParams.get(APP_QUERY_PARAM_TYPES.FORM_SIDEBAR) as SidebarContainer) ?? SidebarContainer.Sidebar;
    const updatedState: RecordItem = {
      isOpen: !hadAdd,
      action: formAction as CrudActions,
      columns: [],
      tablePath: formPath || "",
      container: containerType || SidebarContainer.Sidebar,
      showDetailView: false,
      parentRecordId: formParentId,
      parentRecordSlug: formParentSlug,
      // tabSlug,
      pageFormViewId,
      formViewId,
      trigger: formTrigger
    };
    // This is to ensure addState is not unset if internally set, for example through CellGroup Add click
    if (hadAdd) {
      updatedState["addState"] = {
        isOpen: true,
        tablePath: formPath || "",
        parentRecordId: formParentId,
        parentRecordSlug: formParentSlug,
        defaultAddExpanded: !!formAddExpanded,
        addPageId: hadAdd,
        tabId: addTabId
      };
    }
    updateSidebarState(updatedState, STATIC_SIDEBAR_IDS.MAIN_SIDEBAR);
  }, [searchParams, updateSidebarState]);

  if (
    sidebarState.container === SidebarContainer.Modal &&
    !sidebarState.addState &&
    !sidebarState.pageViewConfigEditState &&
    !sidebarState.menuConfigEditState
  ) {
    return (
      <Modal isOpen={!!sidebarState.isOpen} onClose={handleOnClose}>
        {!sidebarState?.formViewId && (
          <>
            {sidebarState.action === AdditionalFormActions.BULK_EDIT ? (
              <BulkActionEditForm />
            ) : sidebarState.action === AdditionalFormActions.EDIT_JSON ? (
              <JSONEditor
                tableName={sidebarState.tableName}
                record={sidebarState.record}
                valueToUpdate={sidebarState.valueToUpdate}
                columnToUpdate={sidebarState.columnToUpdate}
                onClose={handleOnClose}
                inModal
              />
            ) : sidebarState.action === AdditionalFormActions.WEBHOOK_FORM ? (
              <WebhookForm
                pageId={sidebarState.additionalProps?.pageId}
                columnId={sidebarState.additionalProps?.columnId}
                type={sidebarState.additionalProps?.type || PAGE_BULKACTION_PAYLOAD_TYPE.TEXT}
                webhookProps={sidebarState.additionalProps?.webhookProps}
                webhookURL={sidebarState.additionalProps?.webhookURL}
              />
            ) : (
              <Form
                action={sidebarState.action as CrudActions | AdditionalFormActions}
                columns={sidebarState.columns || null}
                record={sidebarState.record}
                tablePath={sidebarState?.tablePath}
                title={sidebarState.title || ""}
                onActionSuccess={handleActionSuccess}
                parentRecordId={sidebarState.parentRecordId}
                parentRecordSlug={sidebarState.parentRecordSlug}
                tabSlug={sidebarState.tabSlug}
                prefillValues={sidebarState.prefillValues}
                pageFormViewId={sidebarState.pageFormViewId}
                isBulkAction={sidebarState.isBulkAction}
                additionalProps={sidebarState.additionalProps}
                trigger={sidebarState.trigger}
              />
            )}
          </>
        )}
        {sidebarState?.formViewId ? (
          <CustomForm
            formViewId={sidebarState.formViewId}
            action={sidebarState.action as CrudActions | AdditionalFormActions}
            onActionSuccess={handleActionSuccess}
            record={sidebarState.record}
          />
        ) : null}
      </Modal>
    );
  }

  return (
    <>
      {sidebarState.sidebarType === SIDEBAR_TYPE.ADMIN_COLUMN_EDIT ? (
        <SingleColumnEdit
          column={sidebarState.additionalProps?.column}
          allColumns={sidebarState.additionalProps?.allColumns}
          onSuccess={handleActionSuccess}
          pageSlug={sidebarState.additionalProps?.pageSlug}
          isOpen={!!sidebarState.isOpen}
          onClose={handleOnColumnEditClose}
          parentTableName={sidebarState.additionalProps?.parentTableName}
          displayColumns={sidebarState.additionalProps?.displayColumns}
          isNew={sidebarState.additionalProps?.isNew}
          currentView={sidebarState.additionalProps?.currentView}
          visibleSidebars={sidebarState.visibleSidebars}
        />
      ) : null}
      <SidebarUI
        id={STATIC_SIDEBAR_IDS.MAIN_SIDEBAR}
        isOpen={
          !sidebarState.showDetailView && sidebarState?.sidebarType !== SIDEBAR_TYPE.ADMIN_COLUMN_EDIT
            ? !!sidebarState.isOpen
            : false
        }
        onClose={handleOnClose}
        title={sidebarState.action === AdditionalFormActions.EDIT_JSON ? "Edit JSON" : ""}
        visibleSidebars={sidebarState.visibleSidebars}
        rightHeader={
          isFormParamsPresent ? (
            <>
              {" "}
              <IconButton icon={LinkIcon} onClick={onCopyLink} />
            </>
          ) : null
        }
      >
        {!sidebarState.showDetailView && !sidebarState?.formViewId && (
          <>
            {sidebarState.fromView === ViewOption.MATRIX && sidebarState.action === AdditionalFormActions.BULK_EDIT ? (
              <BulkActionEditMatrix
                tabSlug={sidebarState.tabSlug}
                parentRecordId={sidebarState.parentRecordId}
                parentRecordSlug={sidebarState.parentRecordSlug}
              />
            ) : sidebarState.action === AdditionalFormActions.BULK_EDIT ? (
              <BulkActionEditForm />
            ) : sidebarState.action === AdditionalFormActions.EDIT_JSON ? (
              <JSONEditor
                tableName={sidebarState.tableName}
                record={sidebarState.record}
                valueToUpdate={sidebarState.valueToUpdate}
                columnToUpdate={sidebarState.columnToUpdate}
                onClose={handleOnClose}
              />
            ) : (
              <Form
                action={sidebarState.action as CrudActions | AdditionalFormActions}
                columns={sidebarState.columns || null}
                record={sidebarState.record}
                tablePath={sidebarState?.tablePath}
                title={sidebarState.title || ""}
                onActionSuccess={handleActionSuccess}
                parentRecordId={sidebarState.parentRecordId}
                parentRecordSlug={sidebarState.parentRecordSlug}
                tabSlug={sidebarState.tabSlug}
                prefillValues={sidebarState.prefillValues}
                pageFormViewId={sidebarState.pageFormViewId}
                classNameEditAdminBtn={isFormParamsPresent ? "!right-28" : ""}
                isBulkAction={sidebarState.isBulkAction}
                additionalProps={sidebarState.additionalProps}
                actionSource={sidebarState.actionSource}
                trigger={sidebarState.trigger}
              />
            )}
          </>
        )}
        {sidebarState?.formViewId ? (
          <CustomForm
            formViewId={sidebarState.formViewId}
            action={sidebarState.action as CrudActions | AdditionalFormActions}
            onActionSuccess={handleActionSuccess}
            record={sidebarState.record}
          />
        ) : null}
      </SidebarUI>

      <SidebarUI
        id={STATIC_SIDEBAR_IDS.AUDIT_SIDEBAR}
        isOpen={
          !sidebarState.showDetailView && sidebarState.showAuditSidebar ? !!sidebarState.auditState?.isOpen : false
        }
        onClose={handleAuditSidebarClose}
        title={"Logs"}
        visibleSidebars={sidebarState.visibleSidebars}
      >
        {sidebarState.auditState?.tableName ? (
          <AuditSidebar tableName={sidebarState.auditState.tableName} recordId={sidebarState.auditState?.record?.id} />
        ) : null}
      </SidebarUI>

      <AddContainer>
        <SidebarUI
          id={STATIC_SIDEBAR_IDS.ADD_SIDEBAR}
          isOpen={!!sidebarState.addState?.isOpen}
          onClose={handleOnAddSidebarClose}
          allowDroppable
          rounded
          showExpandButton
          visibleSidebars={sidebarState.visibleSidebars}
          defaultExpanded={sidebarState.addState?.defaultAddExpanded}
        >
          <Add
            tablePath={sidebarState.addState?.tablePath}
            tableFilters={sidebarState.addState?.tableFilters}
            onActionSuccess={handleAddActionSuccess}
            tabConfig={sidebarState.addState?.tabConfig}
            parentRecordId={sidebarState.addState?.parentRecordId}
            parentRecordSlug={sidebarState.addState?.parentRecordSlug}
            tabSlug={sidebarState.addState?.tabSlug}
            inForm={sidebarState.addState?.inForm}
            columnConfig={sidebarState.addState?.columnConfig}
            isMultiple={sidebarState.addState?.isMultiple}
            addPageId={sidebarState.addState?.addPageId}
            addTabId={sidebarState.addState?.tabId}
            trigger={sidebarState.trigger}
          />
        </SidebarUI>
      </AddContainer>
      <SidebarUI
        id={STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR}
        isOpen={!!sidebarState?.pageViewConfigEditState?.isOpen}
        onClose={handlePageViewConfigSidebarClose}
        title={
          sidebarState?.pageViewConfigEditState?.view
            ? `Edit ${ViewsLabel[sidebarState.pageViewConfigEditState.view]} View config`
            : sidebarState?.pageViewConfigEditState?.showActionConfig
              ? "Edit Actions"
              : sidebarState?.pageViewConfigEditState?.showFiltersConfig
                ? "Filters Settings"
                : sidebarState?.pageViewConfigEditState?.showBulkEditConfig
                  ? " Bulk Edit Settings"
                  : sidebarState?.pageViewConfigEditState?.showColumnsFormattingConfig
                    ? "Columns Formatting"
                    : ""
        }
        leftHeader={null}
        defaultExpanded={
          sidebarState?.pageViewConfigEditState?.showFiltersConfig ||
          sidebarState?.pageViewConfigEditState?.showBulkEditConfig
        }
        rounded={
          sidebarState?.pageViewConfigEditState?.showFiltersConfig ||
          sidebarState?.pageViewConfigEditState?.showBulkEditConfig
        }
        visibleSidebars={sidebarState.visibleSidebars}
      >
        {sidebarState.pageViewConfigEditState ? (
          <AdminContextProvider>
            <AdminPageContextProvider>
              <AdminEditSinglePageViewConfig
                onSuccess={handlePageViewConfigActionSuccess}
                pageId={sidebarState?.pageViewConfigEditState?.pageId}
                view={sidebarState?.pageViewConfigEditState?.view}
                showActionConfig={sidebarState?.pageViewConfigEditState?.showActionConfig}
                showFiltersConfig={sidebarState?.pageViewConfigEditState?.showFiltersConfig}
                showBulkEditConfig={sidebarState?.pageViewConfigEditState?.showBulkEditConfig}
                showColumnsFormattingConfig={sidebarState?.pageViewConfigEditState?.showColumnsFormattingConfig}
                scrollToSection={sidebarState?.pageViewConfigEditState?.scrollToSection}
                pageViewId={sidebarState?.pageViewConfigEditState?.pageFormViewId}
              />
            </AdminPageContextProvider>
          </AdminContextProvider>
        ) : null}
      </SidebarUI>
      <SidebarUI
        id={STATIC_SIDEBAR_IDS.MENU_CONFIG_EDIT_SIDEBAR}
        isOpen={!!sidebarState?.menuConfigEditState?.isOpen}
        onClose={handleMenuConfigActionSuccess}
        title={sidebarState?.menuConfigEditState?.menuItemId ? "Edit Menu Item" : "Edit Menu"}
        leftHeader={null}
        visibleSidebars={sidebarState.visibleSidebars}
      >
        {sidebarState.menuConfigEditState ? (
          <AdminMenuContextProvider>
            <AdminMenuConfigEdit
              onSuccess={handleMenuConfigActionSuccess}
              menuId={sidebarState?.menuConfigEditState?.menuId}
              menuItemId={sidebarState?.menuConfigEditState?.menuItemId}
              pageId={sidebarState?.menuConfigEditState?.pageId}
              inSidebar
            />
          </AdminMenuContextProvider>
        ) : null}
      </SidebarUI>
      <SidebarUI
        id={STATIC_SIDEBAR_IDS.SEARCH_TABLE_CONFIG_EDIT_SIDEBAR}
        isOpen={!!sidebarState?.searchTableConfigEditState?.isOpen}
        onClose={handleSearchTableConfigSuccess}
        title="Edit Search Table"
        leftHeader={null}
        visibleSidebars={sidebarState.visibleSidebars}
      >
        {sidebarState.searchTableConfigEditState ? (
          <AdminContextProvider>
            <AdminSearchTableConfigEdit
              onSuccess={handleSearchTableConfigSuccess}
              searchTableId={sidebarState?.searchTableConfigEditState?.searchTableId}
              inSidebar
            />
          </AdminContextProvider>
        ) : null}
      </SidebarUI>
    </>
  );
};

export default Sidebar;
