import { useMemo, useState, useRef } from "react";
import clsx from "clsx";

import usePageDataById from "hooks/usePageDataById";
import useRecordTypes from "hooks/useRecordTypes";
import useSchemaState from "hooks/useSchemaState";
import useImpersonateUser from "hooks/useImpersonateUser";
import useTableDataByColumnFilter from "hooks/useTableDataByColumnFilter";

import CellGroup from "components/CellGroup";
import Loader from "components/Loader";
import Button from "components/Button";

import toast, { ToastId } from "utils/toast";
import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import { generateFinalDataColumns } from "utils/dataUtils";
import { ButtonAppearance, CellType, FILTER_OPERATOR, TOAST_TYPE, USER_TYPE, ViewOption } from "utils/constants";
import { TableColumnType } from "types/baTypes";
import { SelectOption } from "types/common";

const ImpersonateUser = ({ onSuccess }: { onSuccess?: () => void }) => {
  const toastRef = useRef<ToastId | null>(null);
  const [selectedUser, setSelectedUser] = useState<SelectOption>();
  const { data: recordTypesData } = useRecordTypes();
  // ##HARDCODED Fetching error logs page to retrieve people options
  const { data: pageData } = usePageDataById("890", {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
  const { schemaInstance } = useSchemaState();
  const { impersonateUser } = useImpersonateUser();

  const { data: peopleData } = useTableDataByColumnFilter({
    slug: "people_data",
    tableName: "people",
    columnName: "id",
    value: selectedUser?.record?.id,
    additionalColumns: [],
    hookOptions: {
      enabled: !!selectedUser?.record?.id && selectedUser?.record?.type !== USER_TYPE.STAFF,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Infinity,
      onlyFetchAdditionalColumns: true
    }
  });

  const finalDataCol = useMemo(() => {
    const peopleColumn = pageData?.columns?.find((col) => col.id === "84eb6791-b336-4701-8c01-8b4af8e6fb21");
    const config = pageData?.views?.find((view) => view.viewType === ViewOption.GRID);
    if (!peopleColumn) return [];
    const updatedCol = appendRecordTypeLookupToCol(
      peopleColumn,
      recordTypesData || [],
      "error_logs",
      schemaInstance?.extendedSchema
    );
    if (!updatedCol) return [];
    const updatedColWithFilters = {
      ...updatedCol,
      columnFilters: [
        {
          id: "type_filter",
          filterField: "type",
          filterValue: USER_TYPE.STAFF,
          filterOperator: FILTER_OPERATOR.NOT_EQUALS
        },
        {
          id: "user_id_filter",
          filterField: "user_id",
          filterValue: "",
          filterOperator: FILTER_OPERATOR.NOT_EMPTY,
          filterDbType: "uuid"
        }
      ]
    };

    return generateFinalDataColumns({
      columns: [updatedColWithFilters] as TableColumnType[],
      view: ViewOption.GRID,
      config,
      recordTypesData,
      tableName: "error-logs",
      extendedSchema: schemaInstance?.extendedSchema
    });
  }, [pageData?.columns, schemaInstance?.extendedSchema, recordTypesData, pageData?.views]);

  const onPeopleSelected = (selected: any) => {
    setSelectedUser(selected);
  };

  const onImpersonate = () => {
    if (!selectedUser || !peopleData?.[0]?.id) return;
    const userDetails = peopleData?.[0];
    toastRef.current = toast.success("Impersonating user, please wait...", { autoClose: false });
    impersonateUser(
      {
        ...userDetails,
        email: userDetails?.email,
        redirectTo: "/table"
      },
      () => {
        if (toastRef.current) {
          toast.update(toastRef.current, "User impersonated in new window", {
            type: TOAST_TYPE.SUCCESS,
            autoClose: 3000
          });
        }
        setSelectedUser(undefined);
        onSuccess?.();
      }
    );
  };

  if (!finalDataCol?.length) {
    return <Loader />;
  }

  return (
    <div className="flex w-full flex-col p-5">
      <CellGroup
        column={finalDataCol?.[0]}
        type={CellType.PEOPLE}
        className={clsx("my-4 w-full cursor-pointer")}
        classNameContainer="w-full"
        onEdit={onPeopleSelected}
        inForm
        isEditable
      />
      <Button label="Impersonate" appearance={ButtonAppearance.PRIMARY} onClick={onImpersonate} />
    </div>
  );
};

export default ImpersonateUser;
