import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import { CheckCircleIcon, MoreVerticalIcon, Trash2Icon } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import Dropdown from "components/Dropdown";
import IconButton from "components/IconButton";
import { NOTIFICATION_TYPE, NotificationRecord } from "components/Notifications/utils";
import Tabs from "components/Tabs";
import useCurrentUser from "hooks/useCurrentUser";
import useNotificationCenter from "hooks/useNotificationCenter";
import useNotificationConfig from "hooks/useNotificationConfig";
import usePageByPathLite from "hooks/usePageByPathLite";
import { formatActions } from "utils/actions";
import { DropdownPosition } from "utils/constants";
import NoteMentionNotificationItem from "./NoteMentionNotificationItem";
import FormNotificationItem from "./FormNotificationItem";

type NotificationCenterProps = {
  onClose: () => void;
};
const NotificationCenter = ({ onClose }: NotificationCenterProps) => {
  const { notifications, markRead, markAllAsRead, clear, markAsDone } = useNotificationCenter();
  const [selectedTab, setSelectedTab] = useState(0);
  const { actions } = useNotificationConfig();
  const currentUser = useCurrentUser();
  const { data: notesPageData } = usePageByPathLite(
    {
      slug: "notes",
      userTypeQP: currentUser?.type,
      source: "Notification Center"
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  );
  const handleMarkRead = useCallback(
    async (notificationId: string, read?: boolean) => {
      markRead(notificationId, read);
    },
    [markRead]
  );

  const menuItems = useMemo(() => {
    return [
      {
        id: "mark_read",
        label: "Mark all as read",
        icon: CheckCircleIcon,
        onClick: markAllAsRead
      },
      {
        id: "clear",
        label: "Clear all notifications",
        icon: Trash2Icon,
        onClick: clear
      }
    ];
  }, [clear, markAllAsRead]);

  const viewActions = useMemo(() => {
    if (!actions) return [];

    return formatActions(sortBy(actions, "sort_order"));
  }, [actions]);

  const handleNoteMentionMarkAsDone = useCallback(
    (notification: NotificationRecord) => {
      const notificationToUpdate: Partial<NotificationRecord> = {
        ...notification,
        action_props: {
          ...notification.action_props,
          markAsDone: true
        }
      };

      let notificationToSend: Partial<NotificationRecord> | undefined;
      if (currentUser?.user_id) {
        const forUserId = notification.action_props?.from_user?.id;
        notificationToSend = {
          ...omit(notification, "id"),
          userId: forUserId,
          message: `${currentUser.full_name} marked the note as done.`,
          action_props: {
            from_user: {
              id: currentUser.user_id,
              name: currentUser.full_name,
              avatar: currentUser?.file?.path
            },
            markAsDone: true
          }
        };
      }
      markAsDone(notificationToUpdate, notificationToSend);
    },
    [currentUser?.user_id, currentUser?.full_name, currentUser?.file?.path, markAsDone]
  );

  const renderNotifications = useCallback(
    (notificationsList: NotificationRecord[]) => {
      if (!notificationsList.length) {
        return (
          <div className="flex flex-1 flex-col items-center justify-center text-base-disabled dark:text-base-dark-disabled">
            <span className="text-xs">No Notifications</span>
          </div>
        );
      }
      return (
        <div className="flex flex-1 flex-col gap-8 pr-3">
          {notificationsList.map((notification) => {
            switch (notification.type) {
              case NOTIFICATION_TYPE.NOTE_MENTION:
                return (
                  <NoteMentionNotificationItem
                    key={notification.id}
                    item={notification}
                    onMarkRead={handleMarkRead}
                    onClose={onClose}
                    actions={viewActions}
                    onMarkDone={handleNoteMentionMarkAsDone}
                    notesPageData={notesPageData}
                  />
                );
              case NOTIFICATION_TYPE.NOTIFICATION_FORM:
                return (
                  <FormNotificationItem
                    key={notification.id}
                    item={notification}
                    onMarkRead={handleMarkRead}
                    onClose={onClose}
                    actions={viewActions}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      );
    },
    [handleMarkRead, onClose, viewActions, handleNoteMentionMarkAsDone, notesPageData]
  );

  const tabItems = useMemo(() => {
    return [
      {
        id: "unread",
        label: "Unread",
        content: renderNotifications(notifications.filter((item) => !item.read))
      },
      {
        id: "all",
        label: "All",
        content: renderNotifications(notifications)
      }
    ];
  }, [notifications, renderNotifications]);

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden px-8 py-8">
      <div className="flex items-center justify-between">
        <span className="inline-block text-2xl font-semibold drop-shadow-sm">Notifications</span>
        <Dropdown
          position={DropdownPosition.BOTTOM_RIGHT}
          MenuButton={<IconButton icon={MoreVerticalIcon} size="sm" />}
          items={menuItems}
        />
      </div>
      <Tabs
        tabs={tabItems}
        className="customScrollBar flex flex-1 flex-col overflow-hidden"
        classNamePanel="flex flex-col flex-1 mt-8 overflow-y-auto"
        selectedTab={selectedTab}
        onChange={setSelectedTab}
      />
    </div>
  );
};

export default NotificationCenter;
