import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getAllPageViews } from "lib/adminApi";
import useCurrentUser from "./useCurrentUser";

const usePageViews = (pageId?: string) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["page-views", pageId, currentUser?.org_id],
    queryFn: () => getAllPageViews(supabaseClient, pageId, currentUser?.org_id),
    enabled: !!pageId,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: Infinity
  });

  return {
    data: data?.data,
    isLoading,
    refetch
  };
};

export default usePageViews;
