import { useCallback } from "react";

import toast from "utils/toast";
import { RecordItem } from "types/common";
import { USER_TYPE } from "utils/constants";

const useImpersonateUser = () => {
  const impersonateUser = useCallback(async (userDetails: RecordItem, onSuccess?: () => void) => {
    if (userDetails?.type === USER_TYPE.STAFF || !userDetails?.email) {
      toast.error("Invalid user type or details, cannot impersonate user");
      return;
    }
    const resp = await fetch("/api/auth/generate_impersonate_link", {
      method: "POST",
      body: JSON.stringify({
        email: userDetails.email,
        redirectTo: userDetails?.redirectTo || "https://client.buildappeal.com/table"
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });
    const data = await resp.json();
    if (data?.success && data?.impersonationLoginLink) {
      window.open(`${process.env.NEXT_PUBLIC_CLIENT_LINK}${data.impersonationLoginLink}`, "_blank");
      onSuccess?.();
    }
  }, []);

  return { impersonateUser };
};

export default useImpersonateUser;
