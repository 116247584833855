"use client";

import { JitsuProvider, useJitsu } from "@jitsu/jitsu-react";
import snakeCase from "lodash/snakeCase";
import startCase from "lodash/startCase";
import { usePathname, useSelectedLayoutSegments } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { getUserGeolocationInfo } from "lib/geolocationApi";
import useCurrentUser from "hooks/useCurrentUser";
import { Geolocation } from "types/apiTypes";
import { RecordItem } from "types/common";
import { PHProvider } from "./PostHogProvider";
import { RudderAnalyticsProvider, useRudderStack } from "./RudderStackContext";
const isLocalDev = !process.env.NEXT_PUBLIC_VERCEL_URL;
type AnalyticsProps = {
  geoLocationInfo?: Geolocation;
  geoLocationInfoLoaded: boolean;
};
type AnalyticsProviderProps = { children: ReactNode };

export type IdentifyUserProps = {
  traits?: RecordItem;
  callback?: () => void;
};

export type TrackProps = {
  eventName: string;
  properties?: RecordItem;
  callback?: () => void;
};

const IS_PRODUCTION = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
const JITSU_CLIENT_HOST = process.env.NEXT_PUBLIC_JITSU_CLIENT_HOST_URL || "";
const JITSU_AIRSTUDIO_HOST = process.env.NEXT_PUBLIC_JITSU_AIRSTUDIO_HOST_URL || "";

const AnalyticsContext = createContext<AnalyticsProps>({ geoLocationInfoLoaded: false });
const { Provider } = AnalyticsContext;

export const PageViewEventTrack = () => {
  const user = useCurrentUser();
  const selectedLayoutSegments = useSelectedLayoutSegments();
  const pathName = usePathname();
  const paths = pathName?.split("/");
  const prevSegment = useRef("");
  const isTable = selectedLayoutSegments?.[0] === "table";
  const segment = isTable ? selectedLayoutSegments?.[1] : selectedLayoutSegments?.[0] || paths?.[1]; // for page name
  const { analytics: jitsuAnalytics } = useJitsu();
  const { geoLocationInfo, geoLocationInfoLoaded } = useContext(AnalyticsContext);
  const isClient = typeof window !== "undefined" && window.location.host.startsWith("client.");
  const posthog = usePostHog();
  const { analytics: rudderStackAnalytics } = useRudderStack();

  useEffect(() => {
    if (prevSegment.current === segment || !geoLocationInfoLoaded || isLocalDev) return;
    if (segment && (!isClient || (isClient && posthog && rudderStackAnalytics))) {
      if (user) {
        const userTraits = { email: user.email, name: user.full_name, phone: user.phone, role: user.type };
        jitsuAnalytics.page(startCase(segment), {
          userId: user.user_id,
          userTraits,
          context: {
            location: geoLocationInfo?.location,
            ip: geoLocationInfo?.ip
          }
        });

        if (isClient) {
          if (posthog) {
            // send event to posthog
            posthog.capture("$pageview", {
              name: startCase(segment),
              context: {
                location: geoLocationInfo?.location,
                ip: geoLocationInfo?.ip
              },
              userId: user.user_id,
              userTraits
            });
          }

          if (rudderStackAnalytics) {
            // send event to rudderstack
            rudderStackAnalytics.page(startCase(segment), {
              context: {
                location: geoLocationInfo?.location,
                ip: geoLocationInfo?.ip
              },
              userId: user.user_id,
              userTraits
            });
          }
        }
      } else {
        jitsuAnalytics.page(startCase(segment), {
          context: {
            location: geoLocationInfo?.location,
            ip: geoLocationInfo?.ip
          }
        });

        if (isClient) {
          if (posthog) {
            // send event to posthog
            posthog.capture("$pageview", {
              name: startCase(segment),
              context: {
                location: geoLocationInfo?.location,
                ip: geoLocationInfo?.ip
              }
            });
          }

          if (rudderStackAnalytics) {
            // send event to rudderstack
            rudderStackAnalytics.page(startCase(segment), {
              context: {
                location: geoLocationInfo?.location,
                ip: geoLocationInfo?.ip
              }
            });
          }
        }
      }
      prevSegment.current = segment;
    }
  }, [user, segment, geoLocationInfo, geoLocationInfoLoaded, jitsuAnalytics, isClient, posthog, rudderStackAnalytics]);

  return null;
};

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const isClient = typeof window !== "undefined" && window.location.host.startsWith("client.");
  const isGeoLocationInfoLoaded = useRef(false);
  const [geoLocationInfo, setGeoLocationInfo] = useState<Geolocation | undefined>();

  const fetchGeolocationInfo = async () => {
    try {
      const result = await getUserGeolocationInfo();
      setGeoLocationInfo(result);
    } catch (err) {
      console.error(err as Error);
    } finally {
      isGeoLocationInfoLoaded.current = true;
    }
  };

  useEffect(() => {
    if (isGeoLocationInfoLoaded.current || !IS_PRODUCTION) return;
    fetchGeolocationInfo();
  }, []);

  return (
    <Provider value={{ geoLocationInfo, geoLocationInfoLoaded: isGeoLocationInfoLoaded.current }}>
      <RudderAnalyticsProvider>
        <PHProvider>
          <JitsuProvider options={{ host: isClient ? JITSU_CLIENT_HOST : JITSU_AIRSTUDIO_HOST }}>
            {children}
            <PageViewEventTrack />
          </JitsuProvider>
        </PHProvider>
      </RudderAnalyticsProvider>
    </Provider>
  );
};

// Analytics hook to be used with other components.
export const useAnalytics = () => {
  const user = useCurrentUser();
  const { analytics: jitsuAnalytics } = useJitsu();
  const { geoLocationInfo } = useContext(AnalyticsContext);
  const posthog = usePostHog();
  const isClient = typeof window !== "undefined" && window.location.host.startsWith("client.");
  const { analytics: rudderStackAnalytics } = useRudderStack();

  const identify = useCallback(
    ({ traits, callback }: IdentifyUserProps = {}): void => {
      if (!user || isLocalDev) return; // Only proceed if there is a user object

      const userTraits = traits || {
        email: user.email,
        name: user.full_name,
        phone: user.phone,
        role: user.type
      };

      // Attempt to identify the user with Jitsu analytics
      void jitsuAnalytics
        .identify(user.user_id, userTraits, {
          context: {
            location: geoLocationInfo?.location,
            ip: geoLocationInfo?.ip
          }
        })
        .catch(console.error);

      if (isClient) {
        if (posthog) {
          // send event to posthog
          posthog.identify(user.user_id, userTraits);
        }

        if (rudderStackAnalytics) {
          // send event to rudderstack
          rudderStackAnalytics?.identify(
            user.user_id,
            userTraits,
            {
              context: {
                location: geoLocationInfo?.location,
                ip: geoLocationInfo?.ip
              }
            },
            callback
          );
        }
      }
    },
    [user, geoLocationInfo, jitsuAnalytics, isClient, posthog, rudderStackAnalytics]
  );

  const track = useCallback(
    ({ eventName, properties, callback }: TrackProps) => {
      if (isLocalDev) return;

      if (user) {
        const userTraits = { email: user.email, name: user.full_name, phone: user.phone, role: user.type };
        const trackedProperties = { userId: user.user_id, ...properties, userTraits };

        void jitsuAnalytics
          .track(snakeCase(eventName), {
            context: {
              location: geoLocationInfo?.location,
              ip: geoLocationInfo?.ip
            },
            ...trackedProperties
          })
          .catch(console.error);

        if (isClient) {
          if (posthog) {
            // send event to posthog
            posthog.capture(snakeCase(eventName), {
              context: {
                location: geoLocationInfo?.location,
                ip: geoLocationInfo?.ip
              },
              ...trackedProperties
            });
          }

          if (rudderStackAnalytics) {
            //send event to rudderstack
            rudderStackAnalytics?.track(
              snakeCase(eventName),
              {
                context: {
                  location: geoLocationInfo?.location,
                  ip: geoLocationInfo?.ip
                },
                ...trackedProperties
              },
              {},
              callback
            );
          }
        }
      } else {
        void jitsuAnalytics
          .track(snakeCase(eventName), {
            context: {
              location: geoLocationInfo?.location,
              ip: geoLocationInfo?.ip
            },
            ...properties
          })
          .catch(console.error);

        if (isClient) {
          if (posthog) {
            // send event to posthog
            posthog.capture(snakeCase(eventName), {
              context: {
                location: geoLocationInfo?.location,
                ip: geoLocationInfo?.ip
              },
              ...properties
            });
          }

          if (rudderStackAnalytics) {
            //send event to rudderstack
            rudderStackAnalytics?.track(
              snakeCase(eventName),
              {
                context: {
                  location: geoLocationInfo?.location,
                  ip: geoLocationInfo?.ip
                },
                ...properties
              },
              {},
              callback
            );
          }
        }
      }
    },
    [user, geoLocationInfo, jitsuAnalytics, isClient, posthog, rudderStackAnalytics]
  );

  return {
    identify,
    track
  };
};
