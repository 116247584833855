import { useCallback } from "react";
import { NavigationItem, QueryHookOptions } from "types/common";
import { SidebarContainer, ViewOption } from "utils/constants";
import useFormOpenHandler from "./useFormOpenHandler";
import usePageDataById from "./usePageDataById";
import usePageByIdLite from "./usePageByIdLite";

const useFormNavItem = (
  {
    item,
    formViewId,
    pageId,
    recordId,
    recordPageId
  }: { formViewId?: string; pageId?: string; recordId?: string; recordPageId?: string; item: NavigationItem },
  options?: QueryHookOptions
) => {
  const { data: pageData } = usePageDataById(pageId, {
    enabled: !!(options?.enabled && pageId)
  });
  const { data: recordPageData } = usePageByIdLite(
    { id: recordPageId || "" },
    {
      enabled: !!(options?.enabled && recordPageId)
    }
  );

  const recordPageSlug = recordPageData?.path?.replace("/", "");

  const { openForm } = useFormOpenHandler({
    pageViews: pageData?.views,
    pageTitle: pageData?.title,
    parentRecordId: recordId,
    parentRecordSlug: recordPageSlug,
    pageSlug: pageData?.path?.replace("/", "")
  });

  const formConfig =
    formViewId && pageData?.views?.find((view) => view.viewId === formViewId)
      ? pageData?.views?.find((view) => view.viewId === formViewId)
      : pageData?.views?.find((view) => view.viewType === ViewOption.FORM && !view.isPageViewCustom);

  const onFormItemClick = useCallback(() => {
    if (!formConfig?.viewId) return;
    openForm({
      viewId: formConfig?.viewId,
      isCustom: false,
      displayType: item.additionalConfig?.displayCreateFormAsModal ? SidebarContainer.Modal : undefined
    });
  }, [openForm, formConfig?.viewId, item.additionalConfig?.displayCreateFormAsModal]);

  return {
    onFormItemClick
  };
};

export default useFormNavItem;
