import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useRef } from "react";
import useCurrentUser from "./useCurrentUser";
import useGeolocationInfo from "./useGeolocationInfo";
import useUpdateRecord from "./useUpdateRecord";

const useUserIpCheck = () => {
  const currentUser = useCurrentUser();
  const isLastestIpChecked = useRef(false);
  const { geoLocationInfo, isLoading: geoLocationInfoLoading } = useGeolocationInfo();
  const { updateRecordAsync } = useUpdateRecord();
  const queryClient = useQueryClient();

  const updateUserIp = useCallback(
    async (id: string, ipAddress: string) => {
      await updateRecordAsync({
        tableName: "people",
        input: { id, latest_ip_address: ipAddress },
      });

      queryClient.refetchQueries({ queryKey: ["record", "self_user_info", "user_id"] });
    },
    [updateRecordAsync, queryClient]
  );

  useEffect(() => {
    if (!currentUser || isLastestIpChecked.current || geoLocationInfoLoading) return;

    isLastestIpChecked.current = true;
    if (geoLocationInfo?.ip && currentUser.latest_ip_address !== geoLocationInfo?.ip) {
      updateUserIp(currentUser.id, geoLocationInfo?.ip);
    }
  }, [currentUser, geoLocationInfoLoading, geoLocationInfo, updateUserIp]);
};

export default useUserIpCheck;
