import { useEffect } from "react";

const useKeypressSave = (callback: () => void) => {
  useEffect(() => {
    const handleSave = (e: KeyboardEvent) => {
      if (e.key === "s" && e.metaKey) {
        e.preventDefault();
        callback();
      }
    };

    document.addEventListener("keydown", handleSave);
    return () => document.removeEventListener("keydown", handleSave);
  }, [callback]);
};

export default useKeypressSave;
